import React, { useState, useEffect, useCallback } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import { useCountUp } from "use-count-up";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import axiosInstance from "../axiosConfigEfes.js";
import Notification from "../Components/Notification.tsx";
import { useGetDriverEfesQuery,
    useGetExpiredInputQuery,
    useGetExpiringSoonInputQuery,
    useGetExpiredPsihQuery,
    useGetExpiringSoonPsihQuery
} from "../Global/DriverEfesSlice.ts";

const ControlDriver = () => {
    const { data: data_input_pass, error: error_input_pass, isLoading: loading_input_pass, refetch: refetch_input_pass } = useGetExpiredInputQuery();
    const { data: data_input_soon, error: error_input_soon, isLoading: loading_input_soon, refetch: refetch_input_soon } = useGetExpiringSoonInputQuery();

    const { data: data_psih_pass, error: error_psih_pass, isLoading: loading_psih_pass, refetch: refetch_psih_pass } = useGetExpiredPsihQuery();
    const { data: data_psih_soon, error: error_psih_soon, isLoading: loading_psih_soon, refetch: refetch_psih_soon } = useGetExpiringSoonPsihQuery();

    const { data: driver, error, isLoading } = useGetDriverEfesQuery();

    const [showModalInput, setShowModalInput] = useState(false);
    const [showModalPsih, setShowModalPsih] = useState(false);

    const [visibleInput, setVisibleInput] = useState(true);
    const [visiblePsih, setVisiblePsih] = useState(false);
  
    const [colorBtnTop, setColorBtnTop] = useState("btn1");
  
    const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
    const [selectedEmployeeFio, setSelectedEmployeeFio] = useState("");
  
    const [selectedDateInput, setSelectedDateInput] = useState("");
    const [selectedDatePsih, setSelectedDatePsih] = useState("");

    const [notification, setNotification] = useState({
      status: "",
      title: "",
      subtitle: "",
      subtitle2: <div></div>,
      isVisible: false,
    });
  
    const hideNotification = () => {
      setNotification({
        ...notification,
        isVisible: false,
      });
    };
  
    const { value: valueSoonInput } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_input_soon?.count,
    });
  
    const { value: valuePassInput } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_input_pass?.count,
    });
  
    const { value: valueSoonPsih } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_psih_soon?.count,
    });
  
    const { value: valuePassPsih } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_psih_pass?.count,
    });

    const all_empl = driver?.length || 0;

    const value_soon_input_proc = Math.round(all_empl > 0 ? (Number(valueSoonInput) ?? 0) / all_empl * 100 : 0);
    const value_pass_input_proc = Math.round(all_empl > 0 ? (Number(valuePassInput) ?? 0) / all_empl * 100 : 0);

    const value_soon_psih_proc = Math.round(all_empl > 0 ? (Number(valueSoonPsih) ?? 0) / all_empl * 100 : 0);
    const value_pass_psih_proc = Math.round(all_empl > 0 ? (Number(valuePassPsih) ?? 0) / all_empl * 100 : 0);

    const formatDate = (dateStr, type) => {
      if (!dateStr) return "";
      const isValidISOFormat = /^\d{4}-\d{2}-\d{2}$/.test(dateStr);
      if (isValidISOFormat) return dateStr;
      const dateParts = dateStr.split(".");
      if (dateParts.length !== 3) return "";
    
      const [day, month, year] = dateParts;
      const parsedDate = new Date(`${year}-${month}-${day}`);
      if (isNaN(parsedDate)) return ""; 
    
      const formattedDay = String(parsedDate.getDate()).padStart(2, "0");
      const formattedMonth = String(parsedDate.getMonth() + 1).padStart(2, "0");
      const formattedYear = parsedDate.getFullYear();
    
      if (type === "input") {
        parsedDate.setFullYear(parsedDate.getFullYear() + 10);
      } else if (type === "psih") {
        parsedDate.setFullYear(parsedDate.getFullYear() + 5); 
      } else {
        return ""; 
      }
    
      const newDay = String(parsedDate.getDate()).padStart(2, "0");
      const newMonth = String(parsedDate.getMonth() + 1).padStart(2, "0");
      const newYear = parsedDate.getFullYear();
    
      return `${newYear}-${newMonth}-${newDay}`;
    };
    
  
    const columnsInput: GridColDef[] = [
      // { field: "id", headerName: "ID", width: 40 },
      {
        field: "fio",
        headerName: "ФИО",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          if (row.employee) {
            return row.employee.fio;
          }
        },
      },
      {
        field: "datelmk",
        headerName: "Дата прохождения",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.dateinput) {
            return null;
          }
          const [day, month, year] = row.dateinput.split(".");
          return new Date(`${year}-${month}-${day}`);
        },
      },
      {
        field: "datelmk2",
        headerName: "Дата срока окончания",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.dateinput) {
            return null;
          }
          const [day, month, year] = row.dateinput.split(".");
          const startDate = new Date(`${year}-${month}-${day}`);
          const endDate = new Date(startDate);
          endDate.setFullYear(endDate.getFullYear() + 10);
          return endDate;
        },
      },
      {
        field: "button",
        headerName: "",
        width: 40,
        renderCell: (params) => (
          <div>
            <IconButton
              onClick={() => {setShowModalInput(true); setSelectedEmployeeId(params.row.id); setSelectedEmployeeFio(params.row.employee.fio); setSelectedDateInput(formatDate(params.row.dateinput, "input")) }}
            >
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];

    const handleEdit = async (type) => {

      if (type === "input"){
        const formattedDate1 = new Date(selectedDateInput).toISOString();

        const empData = {
          id: selectedEmployeeId,
          dateinput: formattedDate1,
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
        };

        try{
          setNotification({
            status: "loading",
            title: "Обновление данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
          });

          await axiosInstance.put(`/efes/edit_driver`, empData);

          setShowModalInput(false);
          refetch_input_pass();
          refetch_input_soon();

          setNotification({
            status: "success",
            title: "Успешно",
            subtitle: "Запись отредактирована",
            subtitle2: <div></div>,
            isVisible: true,
          });

        } catch (error) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при обновлении",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
      }

      if (type === "psih"){
        const formattedDate2 = new Date(selectedDatePsih).toISOString();
        const empData = {
          id: selectedEmployeeId,
          datepsih: formattedDate2,
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
        };

        try{
          setNotification({
            status: "loading",
            title: "Обновление данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
          });

          await axiosInstance.put(`/efes/edit_driver`, empData);

          setShowModalPsih(false);
          refetch_psih_pass();
          refetch_psih_soon();

          setNotification({
            status: "success",
            title: "Успешно",
            subtitle: "Запись отредактирована",
            subtitle2: <div></div>,
            isVisible: true,
          });

        } catch (error) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при обновлении",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
      }
    };
  
    const columnsPsih: GridColDef[] = [
      // { field: "id", headerName: "ID", width: 40 },
      {
        field: "fio",
        headerName: "ФИО",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          if (row.employee) {
            return row.employee.fio;
          }
        },
      },
      {
        field: "dateonjob",
        headerName: "Дата прохождения",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.datepsih) {
            return null;
          }
          const [day, month, year] = row.datepsih.split(".");
          return new Date(`${year}-${month}-${day}`);
        },
      },
      {
        field: "dateonjob2",
        headerName: "Дата срока окончания",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.datepsih) {
            return null;
          }
          const [day, month, year] = row.datepsih.split(".");
          const startDate = new Date(`${year}-${month}-${day}`);
          const endDate = new Date(startDate);
          endDate.setFullYear(endDate.getFullYear() + 5);
          return endDate;
        },
      },
      {
        field: "button",
        headerName: "",
        width: 40,
        renderCell: (params) => (
          <div>
            <IconButton
              onClick={() => {setShowModalPsih(true); setSelectedEmployeeId(params.row.id); setSelectedEmployeeFio(params.row.employee.fio); setSelectedDatePsih(formatDate(params.row.datepsih, "psih")) }}
            >
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];

    if (loading_input_pass || loading_input_soon || loading_psih_pass || loading_psih_soon || isLoading)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
            </div>
        </div>
    ;
        
    if (error_input_pass || error_input_soon || error_psih_pass || error_psih_soon || error) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
            <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
        </div>
    ;


    return (
      <div className="briefings_container">
        <div className="btn_brief_change" style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            onClick={() => {
              setVisibleInput(true);
              setVisiblePsih(false);
              setColorBtnTop("btn1");
            }}
            style={{
              backgroundColor: colorBtnTop === "btn1" ? "#D51B6C" : "",
            }}
          >
            Выдача водительского удостоверения
          </Button>
          <Button
            variant="contained"
            onClick={() => {
                setVisibleInput(false);
                setVisiblePsih(true);
                setColorBtnTop("btn2");
            }}
            style={{
              backgroundColor: colorBtnTop === "btn2" ? "#D51B6C" : "",
            }}
          >
            Псих освидетельствование
          </Button>
        </div>
        <div className="tables_brief">
          {visibleInput && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="navbar_control">
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">В течении месяца</div>
                    <div className="num_cntrl">{data_input_soon?.count}</div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_soon_input_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ffaa00",
                    }}
                  >
                    <Typography>{value_soon_input_proc}%</Typography>
                  </CircularProgress>
                </div>
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">Просрочено</div>
                    <div className="num_cntrl" style={{ color: "#ad1100" }}>
                      {data_input_pass?.count}
                    </div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_pass_input_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ad1100",
                    }}
                  >
                    <Typography>{value_pass_input_proc}%</Typography>
                  </CircularProgress>
                </div>
              </div>
              <div style={{ display: "flex", maxHeight: "700px" }}>
                <DataGrid
                  rows={data_input_soon?.records ?? []}
                  columns={columnsInput}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                  style={{ marginRight: "50px" }}
                />
                <DataGrid
                  rows={data_input_pass?.records ?? []}
                  columns={columnsInput}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                />
              </div>
            </div>
          )}
          {visiblePsih && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="navbar_control">
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">В течении месяца</div>
                    <div className="num_cntrl">{data_psih_soon?.count}</div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_soon_psih_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ffaa00",
                    }}
                  >
                    <Typography>{value_soon_psih_proc}%</Typography>
                  </CircularProgress>
                </div>
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">Просрочен</div>
                    <div className="num_cntrl" style={{ color: "#ad1100" }}>
                      {data_psih_pass?.count}
                    </div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_pass_psih_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ad1100",
                    }}
                  >
                    <Typography>{value_pass_psih_proc}%</Typography>
                  </CircularProgress>
                </div>
              </div>
              <div style={{ display: "flex", maxHeight: "700px" }}>
                <DataGrid
                  rows={data_psih_soon?.records ?? []}
                  columns={columnsPsih}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                  style={{ marginRight: "50px" }}
                />
                <DataGrid
                  rows={data_psih_pass?.records ?? []}
                  columns={columnsPsih}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                />
              </div>
            </div>
          )}
        </div>

        {/* Модальное окно для редактирования выдачи*/}
        {showModalInput && (
          <div className="modal_brief_employee_efes">
              <div className="modal_cont_brief_employee_efes" style={{height: "190px", width: "350px"}}>
                  <h3 className='title_employee_efes'>Редактирование даты выдачи водительского удостоверения</h3>

                  <label style={{display:"flex", flexDirection: "row", height: "fit-content"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

                  <div className="dat">
                      <label htmlFor="datelmk">Дата выдачи:</label>
                      <input
                          type="date"
                          id="datelmk"
                          name="datelmk"
                          value={selectedDateInput}
                          onChange={(e) => setSelectedDateInput(e.target.value)}
                          style={{marginTop: "15px", marginBottom: "10px", width: "140px"}}
                      />
                  </div>

                  <div className="modal_act_fir">
                      <button onClick={() => handleEdit("input")}>Обновить</button>
                      <button onClick={() => setShowModalInput(false)}>Отмена</button>
                  </div>
              </div>
          </div>
        )}

        {/* Модальное окно для редактирования псих. освед */}
        {showModalPsih && (
          <div className="modal_brief_employee_efes">
              <div className="modal_cont_brief_employee_efes" style={{height: "190px", width: "350px"}}>
                  <h3 className='title_employee_efes'>Редактирование даты псих освидетельствования</h3>

                  <label style={{display:"flex", flexDirection: "row"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

                  <div className="dat">
                      <label htmlFor="dateflg">Дата прохождения:</label>
                      <input
                          type="date"
                          id="dateflg"
                          name="dateflg"
                          value={selectedDatePsih}
                          onChange={(e) => setSelectedDatePsih(e.target.value)}
                          style={{marginTop: "15px", marginBottom: "10px", width: "140px"}}
                      />
                  </div>

                  <div className="modal_act_fir">
                      <button onClick={() => handleEdit("psih")}>Обновить</button>
                      <button onClick={() => setShowModalPsih(false)}>Отмена</button>
                  </div>
              </div>
          </div>
        )}

        {notification.isVisible && (
          <Notification
            status={notification.status}
            title={notification.title}
            subtitle={notification.subtitle}
            subtitle2={notification.subtitle2}
            onClose={hideNotification}
            isVisible={notification.isVisible}
          />
        )}
      </div>
    );
}
   
export default ControlDriver;