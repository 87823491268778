import React, { useState } from "react";
import { useGetBriefingsMarsQuery } from "../Global/briefingsMarsSlice.ts";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import axiosInstance from "../../axiosConfig.js";
import Notification from "../Components/notification.tsx";

const TableBrief = () => {
    const { data: briefings, error, isLoading, refetch } = useGetBriefingsMarsQuery();

    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
    const [selectedEmployeeFio, setSelectedEmployeeFio] = useState(null);
    const [showModalUpd, setShowModalUpd] = useState(false);

    const [dateonjob, setDateonjob] = useState("");
    const [datefire, setDatefire] = useState("");
    const [dateelectro, setDateelectro] = useState("");
    const [electro, setElectro] = useState("");
    const [electroemp, setElectroemp] = useState("");
    const [dateelectrolast, setDateelectrolast] = useState("");

    const [type, setType] = useState("");
    const [showModalPrint, setShowModalPrint] = useState(false);
    const [selectedId, setSelectedId] = useState("");

    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });
    
    const hideNotification = () => {
        setNotification({
          ...notification,
          isVisible: false,
        });
    };

    const columns: GridColDef[] = [
        {
            field: "fio",
            headerName: "ФИО",
            width: 250,
            valueGetter: (value, row) => {
                if (row.fio !== ""){
                    return row.fio;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "dateonjob",
            headerName: "На рабочем месте",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.dateonjob) {
                    return null;
                }
                const [day, month, year] = row.dateonjob.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "datefire",
            headerName: "ПБ",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.datefire) {
                    return null;
                }
                const [day, month, year] = row.datefire.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "dateelectro",
            headerName: "ЭБ",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.dateelectro) {
                    return null;
                }
                const [day, month, year] = row.dateelectro.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "numelectro",
            headerName: "Группа ЭБ",
            width: 90,
            valueGetter: (value, row) => {
                if (row.numelectro !== ""){
                    return row.numelectro;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "dateelectrolast",
            headerName: "ЭБ пред.",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.dateelectrolast) {
                    return null;
                }
                const [day, month, year] = row.dateelectrolast.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "button",
            headerName: "Действия",
            width: 100,
            renderCell: (params) => (
              <div>
                <IconButton onClick={() => handleEdit(params.row.id, params.row.fio)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => handlePrint(params.row.id)}
                >
                  <LocalPrintshopIcon />
                </IconButton> 
              </div>
            ),
        },
    ];

    const handlePrint = (id) => {
        setSelectedId(id);
        setShowModalPrint(true);
    }

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const handleEdit = async (id, fio) => {
        setNotification({
            status: "loading",
            title: "Получение данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
        });

        await axiosInstance
          .get(`/employees/${id}`)
          .then((response) => {
            const employee = response.data;
            setDateonjob(formatDate(employee.dateonjob));
            setDatefire(formatDate(employee.datefire));
            setDateelectro(formatDate(employee.dateelectro));
            setDateelectrolast(formatDate(employee.dateelectrolast));
            setElectro(employee.numelectro);
            setElectroemp(employee.numelectro);
          })
          .catch((error) => {
            console.error(
              "Ошибка при получении данных о сотруднике для редактирования:",
              error
            );
        })

        hideNotification();

        setSelectedEmployeeId(id);
        setSelectedEmployeeFio(fio);
        setShowModalUpd(true);
    };

    const confirmUpd = async () => {
        const formattedDateonjob = new Date(dateonjob).toISOString();
        const formattedDatefire = new Date(datefire).toISOString();
        const formattedDateelectro = new Date(dateelectro).toISOString();

        let formattedDateelectrolast: string | null = null;
        if (dateelectrolast !== null) {
            formattedDateelectrolast = new Date(dateelectrolast).toISOString();
        }

        const empData = {
            id: selectedEmployeeId,
            dateonjob: formattedDateonjob,
            datefire: formattedDatefire,
            dateelectro: formattedDateelectro,
            numelectro: electro,
            dateelectrolast: formattedDateelectrolast,
        };
    
        try {
    
          setNotification({
              status: "loading",
              title: "Обновление данных",
              subtitle: "Пожалуйста, подождите...",
              subtitle2: <div></div>,
              isVisible: true,
          });
    
          await axiosInstance.put(`/employees/edit_brief`, empData);
          
          setShowModalUpd(false);
          refetch();
    
          setNotification({
            status: "success",
            title: "Успешно",
            subtitle: "Инструктажи отредактированы",
            subtitle2: <div></div>,
            isVisible: true,
          });
    
        } catch (error) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при обновлении",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
    };

    const handlePrintElectro = async () => {
        try {
            setNotification({
                status: "loading",
                title: "Создание документа",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });
            
            const response = await axiosInstance.get(
                `/documents/protelectro/${selectedId}?type=${type}`,
                {
                  responseType: "blob",
                }
            );
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("target", "_blank");
            link.click();
            window.URL.revokeObjectURL(url);

            hideNotification();
            setShowModalPrint(false);

        } catch (error) {
            setNotification({
                status: "error",
                title: "Произошла ошибка",
                subtitle: "Повторите еще раз или обратитесь к разработчику",
                subtitle2: <div></div>,
                isVisible: true,
            });
        }
    };

    if (isLoading)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
          </div>
        </div>
    ;
        
    if (error) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
          <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
          <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
      </div>
    ;

    return (
        <div className="active_employee_efes">
            <div className="container_briefings_efes" style={{width: "870px"}}>
                <DataGrid
                    style={{
                    marginTop: 20,
                    }}
                    rows={briefings ?? []}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 12,
                        },
                    },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    pageSizeOptions={[12]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{
                    toolbar: GridToolbar,
                    }}
                    slotProps={{ toolbar: { showQuickFilter: true } }}
                />
            </div>
            {/* Модальное окно для редактирования */}
            {showModalUpd && (
                <div className="modal_brief_employee_efes">
                    <div className="modal_cont_brief_employee_efes" style={{height:"fit-content"}}>
                        <h3 className='title_employee_efes'>Редактирование инструктажей</h3>
                        <label style={{display:"flex", flexDirection: "row", marginBottom: "15px"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

                        <div className="dat">
                            <label htmlFor="dateonjob">Дата на рабочем месте:</label>
                            <input
                                type="date"
                                id="dateonjob"
                                name="dateonjob"
                                value={dateonjob}
                                onChange={(e) => setDateonjob(e.target.value)}
                                style={{marginBottom: "10px", marginLeft: "65px"}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="datefire">Дата пожаробезопасности:</label>
                            <input
                                type="date"
                                id="datefire"
                                name="datefire"
                                value={datefire}
                                onChange={(e) => setDatefire(e.target.value)}
                                style={{ marginBottom: "10px", marginLeft: "33px"}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="dateelectro">Дата электробезопасности:</label>
                            <input
                                type="date"
                                id="dateelectro"
                                name="dateelectro"
                                value={dateelectro}
                                onChange={(e) => setDateelectro(e.target.value)}
                                style={{marginBottom: "10px", marginLeft: "30px"}}
                            />
                        </div>

                        <label
                            htmlFor="electro-dropdown"
                        >
                            Группа электробезопасности:
                        </label>
                        <select
                            id="electro-dropdown"
                            value={electro}
                            style={{ width: "100%", marginTop: "10px", height: "22px"}}
                            onChange={(e) => setElectro(e.target.value)}
                        >
                            <option value="" disabled>
                            Выберите группу электробезопасности
                            </option>
                            <option value="1" key="1" >
                            Первая
                            </option>
                            <option value="2" key="2">
                            Вторая
                            </option>
                        </select>

                        {String(electroemp) !== String(electro) && (
                            <div className="dat">
                                <label htmlFor="dateelectrolast">Пред. дата проверки:</label>
                                <input
                                    type="date"
                                    id="dateelectrolast"
                                    name="dateelectrolast"
                                    value={dateelectrolast}
                                    onChange={(e) => setDateelectrolast(e.target.value)}
                                    style={{ marginLeft: "80px", marginTop: "10px"}}
                                />
                            </div>
                        )}

                        <div className="modal_act_fir">
                            <button onClick={confirmUpd}>Обновить</button>
                            <button onClick={() => setShowModalUpd(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Модальное окно для редактирования */}
            {showModalPrint && (
                <div className="modal_brief_employee_efes">
                    <div className="modal_cont_brief_employee_efes" style={{height:"fit-content"}}>
                        <h3 className='title_employee_efes'>Печать протокола ЭБ</h3>

                        <label
                            htmlFor="electro-dropdown"
                        >
                            Причина проверки:
                        </label>
                        <select
                            id="electro-dropdown"
                            value={type}
                            style={{ width: "100%", marginTop: "10px", height: "22px"}}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <option value="" disabled>
                                Выберите причину проверки
                            </option>
                            <option value="Первичный" key="Первичный" >
                                Первичный
                            </option>
                            <option value="Повторный" key="Повторный">
                                Повторный
                            </option>
                            <option value="Внеплановый" key="Внеплановый">
                                Внеплановый
                            </option>
                            <option value="Целевой" key="Целевой">
                                Целевой
                            </option>
                        </select>

                        <div className="modal_act_fir">
                            <button onClick={handlePrintElectro}>Печать</button>
                            <button onClick={() => setShowModalPrint(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {notification.isVisible && (
                <Notification
                    status={notification.status}
                    title={notification.title}
                    subtitle={notification.subtitle}
                    onClose={hideNotification}
                    isVisible={notification.isVisible}
                    subtitle2={notification.subtitle2}
                />
            )}
        </div>
    );
}

export default TableBrief;