import React, { useState, useEffect, useCallback } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import { Employee, formatDate2 } from "../../models.ts";
import { useCountUp } from "use-count-up";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  fetchEmployee,
  fetchEmployeesWithOnJobExpired,
  fetchEmployeesWithOnJobExpiring,
  fetchEmployeesWithFireExpired,
  fetchEmployeesWithFireExpiring,
  fetchEmployeesWithElectroExpired,
  fetchEmployeesWithElectroExpiring,
} from "../../api_control.ts";
import '../../Styles/briefings.scss';
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Notification from "../../Components/notification.tsx";
import axiosInstance from "../../../axiosConfig.js";


const Briefings = () => {
  const [visibleOnjob, setVisibleOnjob] = useState(true);
  const [visibleFire, setVisibleFire] = useState(false);
  const [visibleElect, setVisibleElect] = useState(false);
  const [dataEmployee, setDataEmployee] = useState<Employee[]>([]);

  const [OnJobExpiring, setOnJobExpiring] = useState<Employee[]>([]);
  const [OnJobExpired, setOnJobExpired] = useState<Employee[]>([]);

  const [FireExpiring, setFireExpiring] = useState<Employee[]>([]);
  const [FireExpired, setFireExpired] = useState<Employee[]>([]);

  const [ElectroExpiring, setElectroExpiring] = useState<Employee[]>([]);
  const [ElectroExpired, setElectroExpired] = useState<Employee[]>([]);
  const [electro, setElectro] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const [filteredOnJobExpiring, setFilteredOnJobExpiring] = useState<
    Employee[]
  >([]);
  const [filteredOnJobExpired, setFilteredOnJobExpired] = useState<Employee[]>(
    []
  );

  const [filteredFireExpiring, setFilteredFireExpiring] = useState<Employee[]>(
    []
  );
  const [filteredFireExpired, setFilteredFireExpired] = useState<Employee[]>(
    []
  );

  const [filteredElectroExpiring, setFilteredElectroExpiring] = useState<
    Employee[]
  >([]);
  const [filteredElectroExpired, setFilteredElectroExpired] = useState<
    Employee[]
  >([]);

  const [isFiltered, setIsFiltered] = useState(false);

  const [colorBtnTop, setColorBtnTop] = useState("btn1");
  const [colorBtn, setColorBtn] = useState("btn3");

  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectedEmployeeFio, setSelectedEmployeeFio] = useState("");
  const [isModalVisibleUpd, setIsModalVisibleUpd] = useState(false);

  const [befDateOnJob, setBefDateOnJob] = useState("");
  const [selectedDateOnJob, setSelectedDateOnJob] = useState("");

  const [befDateFire, setBefDateFire] = useState("");
  const [selectedDateFire, setSelectedDateFire] = useState("");

  const [befDateElectro, setBefDateElectro] = useState("");
  const [selectedDateElectro, setSelectedDateElectro] = useState("");

  const [typeModalUpd, setTypeModalUpd] = useState("");

  const [notification, setNotification] = useState({
    status: "",
    title: "",
    subtitle: "",
    subtitle2: <div></div>,
    isVisible: false,
  });

  const hideNotification = () => {
    setNotification({
      ...notification,
      isVisible: false,
    });
  };

  const fetchData = async () => {
    try {
      const dataEmpl = await fetchEmployee();
      setDataEmployee(dataEmpl);

      // На рабочем месте
      const data1 = await fetchEmployeesWithOnJobExpiring();
      setOnJobExpiring(data1);
      const data2 = await fetchEmployeesWithOnJobExpired();
      setOnJobExpired(data2);

      // Пожарная безопасность
      const dataFire = await fetchEmployeesWithFireExpiring();
      setFireExpiring(dataFire);
      const dataFire2 = await fetchEmployeesWithFireExpired();
      setFireExpired(dataFire2);

      // Электробезопасность
      const dataElectro = await fetchEmployeesWithElectroExpiring();
      setElectroExpiring(dataElectro);
      const dataElectro2 = await fetchEmployeesWithElectroExpired();
      setElectroExpired(dataElectro2);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filterDataForMonth = useCallback(
    (date: Date) => {
      const month = date.getMonth();

      // На рабочем месте
      const expiringFiltered = OnJobExpiring.filter((employee) => {
        if (!employee.dateonjob) {
          return false;
        }
        const [day, monthStr, year] = employee.dateonjob.split(".");
        const lmkDate = new Date(`${year}-${monthStr}-${day}`);
        return lmkDate.getMonth() === month;
      });

      const expiredFiltered = OnJobExpired.filter((employee) => {
        if (!employee.dateonjob) {
          return false;
        }
        const [day, monthStr, year] = employee.dateonjob.split(".");
        const lmkDate = new Date(`${year}-${monthStr}-${day}`);
        return lmkDate.getMonth() === month;
      });

      setFilteredOnJobExpiring(expiringFiltered);
      setFilteredOnJobExpired(expiredFiltered);

      // Пожарная безопасность
      const expiringFiltered2 = FireExpiring.filter((employee) => {
        if (!employee.datefire) {
          return false;
        }
        const [day, monthStr, year] = employee.datefire.split(".");
        const lmkDate = new Date(`${year}-${monthStr}-${day}`);
        return lmkDate.getMonth() === month;
      });

      const expiredFiltered2 = FireExpired.filter((employee) => {
        if (!employee.datefire) {
          return false;
        }
        const [day, monthStr, year] = employee.datefire.split(".");
        const lmkDate = new Date(`${year}-${monthStr}-${day}`);
        return lmkDate.getMonth() === month;
      });

      setFilteredFireExpiring(expiringFiltered2);
      setFilteredFireExpired(expiredFiltered2);

      // Электробезопасность
      const expiringFiltered3 = ElectroExpiring.filter((employee) => {
        if (!employee.dateelectrosecurity) {
          return false;
        }
        const [day, monthStr, year] = employee.dateelectrosecurity.split(".");
        const lmkDate = new Date(`${year}-${monthStr}-${day}`);
        return lmkDate.getMonth() === month;
      });

      const expiredFiltered3 = ElectroExpired.filter((employee) => {
        if (!employee.dateelectrosecurity) {
          return false;
        }
        const [day, monthStr, year] = employee.dateelectrosecurity.split(".");
        const lmkDate = new Date(`${year}-${monthStr}-${day}`);
        return lmkDate.getMonth() === month;
      });
      setFilteredElectroExpiring(expiringFiltered3);
      setFilteredElectroExpired(expiredFiltered3);
    },
    [
      OnJobExpiring,
      OnJobExpired,
      FireExpiring,
      FireExpired,
      ElectroExpiring,
      ElectroExpired,
    ]
  );

  useEffect(() => {
    if (isFiltered && (OnJobExpiring.length > 0 || OnJobExpired.length > 0)) {
      filterDataForMonth(selectedMonth);
    } else {
      setFilteredOnJobExpiring(OnJobExpiring);
      setFilteredOnJobExpired(OnJobExpired);
    }
    if (isFiltered && (FireExpiring.length > 0 || FireExpired.length > 0)) {
      filterDataForMonth(selectedMonth);
    } else {
      setFilteredFireExpiring(FireExpiring);
      setFilteredFireExpired(FireExpired);
    }
    if (
      isFiltered &&
      (ElectroExpiring.length > 0 || ElectroExpired.length > 0)
    ) {
      filterDataForMonth(selectedMonth);
    } else {
      setFilteredElectroExpiring(ElectroExpiring);
      setFilteredElectroExpired(ElectroExpired);
    }
  }, [
    selectedMonth,
    OnJobExpiring,
    OnJobExpired,
    isFiltered,
    filterDataForMonth,
    FireExpiring,
    FireExpired,
    ElectroExpiring,
    ElectroExpired,
  ]);

  const handleMonthChange = (direction: "next" | "prev") => {
    setIsFiltered(true);
    setSelectedMonth(() => {
      const newMonth = new Date();
      if (direction === "next") {
        newMonth.setMonth(newMonth.getMonth() + 1);
      }
      return newMonth;
    });
  };

  const calculatePercentage = (count, total) =>
    Math.round((count / total) * 100);

  const rowCountAll = dataEmployee ? dataEmployee.length : 0;

  const calculateData = (expired, expiring) => {
    const rowCountExpired = expired.length;
    const rowCountExpiring = expiring.length;

    const procExpired = calculatePercentage(rowCountExpired, rowCountAll);
    const procExpiring = calculatePercentage(rowCountExpiring, rowCountAll);

    return { procExpired, procExpiring, rowCountExpired, rowCountExpiring };
  };

  // На рабочем месте
  const {
    procExpired: procExpiredOnJob,
    procExpiring: procExpiringOnJob,
    rowCountExpired: ExpiredOnJobLenght,
    rowCountExpiring: ExpiringOnJobLenght,
  } = calculateData(filteredOnJobExpired, filteredOnJobExpiring);

  // Пожарная безопасность
  const {
    procExpired: procExpiredFire,
    procExpiring: procExpiringFire,
    rowCountExpired: ExpiredFireLenght,
    rowCountExpiring: ExpiringFireLenght,
  } = calculateData(filteredFireExpired, filteredFireExpiring);

  // Электробезопасность
  const {
    procExpired: procExpiredElectro,
    procExpiring: procExpiringElectro,
    rowCountExpired: ExpiredElectroLenght,
    rowCountExpiring: ExpiringElectroLenght,
  } = calculateData(filteredElectroExpired, filteredElectroExpiring);

  const { value: valueOnJob } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: procExpiredOnJob,
  });

  const { value: value2OnJob } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: procExpiringOnJob,
  });

  const { value: valueFire } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: procExpiredFire,
  });

  const { value: value2Fire } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: procExpiringFire,
  });

  const { value: valueElectro } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: procExpiredElectro,
  });

  const { value: value2Electro } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: procExpiringElectro,
  });

  const handleResetFilter = () => {
    setIsFiltered(false);
  };

  const columnsFire: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 40 },
    {
      field: "fio",
      headerName: "ФИО",
      width: 250,
      type: "string",
      editable: false,
    },
    {
      field: "datefire",
      headerName: "Дата прохождения",
      width: 200,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.datefire) {
          return null;
        }
        const [day, month, year] = row.datefire.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "datefire2",
      headerName: "Дата срока окончания",
      width: 200,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.datefire) {
          return null;
        }
        const [day, month, year] = row.datefire.split(".");
        const startDate = new Date(`${year}-${month}-${day}`);
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + 6);
        return endDate;
      },
    },
    {
      field: "button",
      headerName: "",
      width: 40,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() =>
              handleEdit(
                params.row.id,
                params.row.fio,
                params.row.datefire,
                "fire"
              )
            }
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const columnsOnjob: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 40 },
    {
      field: "fio",
      headerName: "ФИО",
      width: 250,
      type: "string",
      editable: false,
    },
    {
      field: "dateonjob",
      headerName: "Дата прохождения",
      width: 200,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.dateonjob) {
          return null;
        }
        const [day, month, year] = row.dateonjob.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "dateonjob2",
      headerName: "Дата срока окончания",
      width: 200,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.dateonjob) {
          return null;
        }
        const [day, month, year] = row.dateonjob.split(".");
        const startDate = new Date(`${year}-${month}-${day}`);
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + 6);
        return endDate;
      },
    },
    {
      field: "button",
      headerName: "",
      width: 40,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() =>
              handleEdit(
                params.row.id,
                params.row.fio,
                params.row.dateonjob,
                "onJob"
              )
            }
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const columnsElectro: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 40 },
    {
      field: "fio",
      headerName: "ФИО",
      width: 250,
      type: "string",
      editable: false,
    },
    {
      field: "numelectrosecurity",
      headerName: "Группа",
      width: 100,
      type: "string",
      editable: false,
    },
    {
      field: "dateonjob",
      headerName: "Дата прохождения",
      width: 200,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.dateelectrosecurity) {
          return null;
        }
        const [day, month, year] = row.dateelectrosecurity.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "dateonjob2",
      headerName: "Дата срока окончания",
      width: 200,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.dateelectrosecurity) {
          return null;
        }
        const [day, month, year] = row.dateelectrosecurity.split(".");
        const startDate = new Date(`${year}-${month}-${day}`);
        const endDate = new Date(startDate);
        endDate.setFullYear(endDate.getFullYear() + 1);
        return endDate;
      },
    },
    {
      field: "button",
      headerName: "",
      width: 40,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() =>
              handleEdit(
                params.row.id,
                params.row.fio,
                params.row.dateelectrosecurity,
                "electro"
              )
            }
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  // Редактирование
  const handleEdit = (id, fio, date, type) => {
    setSelectedEmployeeId(id);
    setSelectedEmployeeFio(fio);
    setSelectedEmployeeId(id);
    setSelectedEmployeeFio(fio);
    const [day, monthStr, year] = date.split(".");
    const new_date = new Date(`${year}-${monthStr}-${day}`);
    if (type === "onJob"){
      setBefDateOnJob(formatDate2(new_date));
      setSelectedDateOnJob(formatDate2(new_date));
    }
    if (type === "fire") {
      setBefDateFire(formatDate2(new_date));
      setSelectedDateFire(formatDate2(new_date));
    }
    if (type === "electro") {
      new_date.setFullYear(new_date.getFullYear() + 1);
      setBefDateElectro(formatDate2(new_date));
      setSelectedDateElectro(formatDate2(new_date));
    }
    setTypeModalUpd(type);
    setIsModalVisibleUpd(true);
  };

  const confirmUpdOnJob = async () => {
    if (!selectedDateOnJob) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Введите дату",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }
    if (selectedDateOnJob === befDateOnJob) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Новая дата имеет такое же значение",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }
    try {
      const formattedDate = new Date(selectedDateOnJob).toISOString();
      const data = {
        dateonjob: formattedDate,
      };
      await axiosInstance.put(`/employees/${selectedEmployeeId}`, data);
      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Данные по инструктажу на рабочем месте обновлены!",
        subtitle2: <div></div>,
        isVisible: true,
      });
      setIsModalVisibleUpd(false);
      fetchData();
    } catch {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Не удалось изменить данные. Ошибка сервера",
        subtitle2: <div></div>,
        isVisible: true,
      });
    }
  };

  const confirmUpdFire = async () => {
    if (!selectedDateFire) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Введите дату",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }
    if (selectedDateFire === befDateFire) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Новая дата имеет такое же значение",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }
    try {
      const formattedDate = new Date(selectedDateFire).toISOString();
      const data = {
        datefire: formattedDate,
      };
      await axiosInstance.put(`/employees/${selectedEmployeeId}`, data);
      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Данные по пожарной безопасности обновлены!",
        subtitle2: <div></div>,
        isVisible: true,
      });
      setIsModalVisibleUpd(false);
      fetchData();
    } catch {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Не удалось изменить данные. Ошибка сервера",
        subtitle2: <div></div>,
        isVisible: true,
      });
    }
  };

  const confirmUpdElectro = async () => {
    if (!selectedDateElectro || !electro) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Заполните все поля",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }
    if (selectedDateElectro === befDateElectro) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Новая дата имеет такое же значение",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }
    try {
      const formattedDate = new Date(selectedDateElectro).toISOString();
      const data = {
        dateelectrosecurity: formattedDate,
        numelectrosecurity: electro,
      };
      await axiosInstance.put(`/employees/${selectedEmployeeId}`, data);
      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Данные по электробезопасности обновлены!",
        subtitle2: (
          <div>
            <p style={{margin: "0px"}}>
              Не забудьте напечатать протокол ЭБ
            </p>
            <Button
              variant="contained"
              onClick={() => {
                handlePrintDoc("protelectro", selectedEmployeeId);
              }}
              style={{ marginTop: "10px", marginLeft: "auto", marginRight: "auto"}}
            >
              печать
            </Button>
          </div>
        ),
        isVisible: true,
      });
      setIsModalVisibleUpd(false);
      fetchData();
    } catch {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Не удалось изменить данные. Ошибка сервера",
        subtitle2: <div></div>,
        isVisible: true,
      });
    }
  };

  const handlePrintDoc = async (documentType, employeeid) => {
    try {
      const response = await axiosInstance.get(
        `/documents/${documentType}/${employeeid}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "_blank");
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Ошибка при получении PDF:", error);
    }
  };

  return (
    <div className="briefings_container">
      <div className="btn_brief_change" style={{ marginTop: "20px" }}>
        <Button
          variant="contained"
          onClick={() => {
            setVisibleOnjob(true);
            setVisibleFire(false);
            setVisibleElect(false);
            setColorBtnTop("btn1");
          }}
          style={{
            backgroundColor: colorBtnTop === "btn1" ? "#D51B6C" : "",
          }}
        >
          на рабочем месте
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setVisibleOnjob(false);
            setVisibleFire(true);
            setVisibleElect(false);
            setColorBtnTop("btn2");
          }}
          style={{
            backgroundColor: colorBtnTop === "btn2" ? "#D51B6C" : "",
          }}
        >
          пожарная безопасность
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setVisibleOnjob(false);
            setVisibleFire(false);
            setVisibleElect(true);
            setColorBtnTop("btn3");
          }}
          style={{
            backgroundColor: colorBtnTop === "btn3" ? "#D51B6C" : "",
          }}
        >
          электробезопасность
        </Button>
      </div>
      <div className="btn_m2">
        <Button
          onClick={() => {
            handleMonthChange("prev");
            setColorBtn("btn1");
          }}
          variant="contained"
          style={{
            backgroundColor: colorBtn === "btn1" ? "#D51B6C" : "",
          }}
        >
          Текущий месяц
        </Button>
        <Button
          onClick={() => {
            handleMonthChange("next");
            setColorBtn("btn2");
          }}
          variant="contained"
          style={{
            backgroundColor: colorBtn === "btn2" ? "#D51B6C" : "",
          }}
        >
          Следующий месяц
        </Button>
        <Button
          onClick={() => {
            handleMonthChange("next");
            setColorBtn("btn3");
          }}
          variant="contained"
          style={{
            backgroundColor: colorBtn === "btn3" ? "#D51B6C" : "",
          }}
        >
          Все месяца
        </Button>
      </div>
      <div className="tables_brief">
        {visibleOnjob && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className="navbar_control">
              <div className="cntrl">
                <div className="data_cntrl">
                  <div className="title_cntrl">В течении месяца</div>
                  <div className="num_cntrl">{ExpiringOnJobLenght}</div>
                </div>
                <CircularProgress
                  determinate
                  value={value2OnJob as number}
                  sx={{
                    "--CircularProgress-size": "78px",
                    "--CircularProgress-progressColor": "#ffaa00",
                  }}
                >
                  <Typography>{value2OnJob}%</Typography>
                </CircularProgress>
              </div>
              <div className="cntrl">
                <div className="data_cntrl">
                  <div className="title_cntrl">Просрочен</div>
                  <div className="num_cntrl" style={{ color: "#ad1100" }}>
                    {ExpiredOnJobLenght}
                  </div>
                </div>
                <CircularProgress
                  determinate
                  value={valueOnJob as number}
                  sx={{
                    "--CircularProgress-size": "78px",
                    "--CircularProgress-progressColor": "#ad1100",
                  }}
                >
                  <Typography>{valueOnJob}%</Typography>
                </CircularProgress>
              </div>
            </div>
            <div style={{ display: "flex", maxHeight: "700px" }}>
              <DataGrid
                rows={filteredOnJobExpiring ?? []}
                columns={columnsOnjob}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                style={{ marginRight: "50px" }}
              />
              <DataGrid
                rows={filteredOnJobExpired ?? []}
                columns={columnsOnjob}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
              />
            </div>
          </div>
        )}
        {visibleFire && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className="navbar_control">
              <div className="cntrl">
                <div className="data_cntrl">
                  <div className="title_cntrl">В течении месяца</div>
                  <div className="num_cntrl">{ExpiringFireLenght}</div>
                </div>
                <CircularProgress
                  determinate
                  value={value2Fire as number}
                  sx={{
                    "--CircularProgress-size": "78px",
                    "--CircularProgress-progressColor": "#ffaa00",
                  }}
                >
                  <Typography>{value2Fire}%</Typography>
                </CircularProgress>
              </div>
              <div className="cntrl">
                <div className="data_cntrl">
                  <div className="title_cntrl">Просрочен</div>
                  <div className="num_cntrl" style={{ color: "#ad1100" }}>
                    {ExpiredFireLenght}
                  </div>
                </div>
                <CircularProgress
                  determinate
                  value={valueFire as number}
                  sx={{
                    "--CircularProgress-size": "78px",
                    "--CircularProgress-progressColor": "#ad1100",
                  }}
                >
                  <Typography>{valueFire}%</Typography>
                </CircularProgress>
              </div>
            </div>
            <div style={{ display: "flex", maxHeight: "700px" }}>
              <DataGrid
                rows={filteredFireExpiring ?? []}
                columns={columnsFire}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                style={{ marginRight: "50px" }}
              />
              <DataGrid
                rows={filteredFireExpired ?? []}
                columns={columnsFire}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
              />
            </div>
          </div>
        )}
        {visibleElect && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className="navbar_control">
              <div className="cntrl">
                <div className="data_cntrl">
                  <div className="title_cntrl">В течении месяца</div>
                  <div className="num_cntrl">{ExpiringElectroLenght}</div>
                </div>
                <CircularProgress
                  determinate
                  value={value2Electro as number}
                  sx={{
                    "--CircularProgress-size": "78px",
                    "--CircularProgress-progressColor": "#ffaa00",
                  }}
                >
                  <Typography>{value2Electro}%</Typography>
                </CircularProgress>
              </div>
              <div className="cntrl">
                <div className="data_cntrl">
                  <div className="title_cntrl">Просрочен</div>
                  <div className="num_cntrl" style={{ color: "#ad1100" }}>
                    {ExpiredElectroLenght}
                  </div>
                </div>
                <CircularProgress
                  determinate
                  value={valueElectro as number}
                  sx={{
                    "--CircularProgress-size": "78px",
                    "--CircularProgress-progressColor": "#ad1100",
                  }}
                >
                  <Typography>{valueElectro}%</Typography>
                </CircularProgress>
              </div>
            </div>
            <div style={{ display: "flex", maxHeight: "700px" }}>
              <DataGrid
                rows={filteredElectroExpiring ?? []}
                columns={columnsElectro}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                style={{ marginRight: "50px" }}
              />
              <DataGrid
                rows={filteredElectroExpired ?? []}
                columns={columnsElectro}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
              />
            </div>
          </div>
        )}
      </div>
      {isModalVisibleUpd && (
        <div className="modal_upd_lmk">
          <div className="modal_cont_upd_lmk">
            <label style={{ marginBottom: "10px" }}>
              ФИО: {selectedEmployeeFio}
            </label>

            {typeModalUpd === "onJob" && (
              <div>
                <label style={{ marginBottom: "10px" }}>
                  Дата иструктажа на рабочем месте:
                </label>
                <input
                  type="date"
                  value={selectedDateOnJob}
                  onChange={(e) => setSelectedDateOnJob(e.target.value)}
                />
                <div
                  style={{
                    display: "flex",
                    marginTop: "0px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button onClick={confirmUpdOnJob}>Редактировать</button>
                  <button onClick={() => setIsModalVisibleUpd(false)}>
                    Отмена
                  </button>
                </div>
              </div>
            )}

            {typeModalUpd === "fire" && (
              <div>
                <label style={{ marginBottom: "10px" }}>
                  Дата иструктажа по пожарной бехопастности:
                </label>
                <input
                  type="date"
                  value={selectedDateFire}
                  onChange={(e) => setSelectedDateFire(e.target.value)}
                />
                <div
                  style={{
                    display: "flex",
                    marginTop: "0px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button onClick={confirmUpdFire}>Редактировать</button>
                  <button onClick={() => setIsModalVisibleUpd(false)}>
                    Отмена
                  </button>
                </div>
              </div>
            )}

            {typeModalUpd === "electro" && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ marginBottom: "10px" }}>
                  Дата иструктажа по электробезопасности:
                </label>
                <input
                  type="date"
                  value={selectedDateElectro}
                  onChange={(e) => setSelectedDateElectro(e.target.value)}
                />

                <label htmlFor="electro-dropdown">
                  Группа электробезопасности:
                </label>
                <select
                  id="electro-dropdown"
                  value={electro}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                  onChange={(e) => setElectro(e.target.value)}
                >
                  <option value="" disabled>
                    Выберите группу электробезопасности
                  </option>
                  <option value="1" key="1">
                    Первая
                  </option>
                  <option value="2" key="2">
                    Вторая
                  </option>
                </select>
                <div
                  style={{
                    display: "flex",
                    marginTop: "0px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button onClick={confirmUpdElectro}>Редактировать</button>
                  <button onClick={() => setIsModalVisibleUpd(false)}>
                    Отмена
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {notification.isVisible && (
        <Notification
          status={notification.status}
          title={notification.title}
          subtitle={notification.subtitle}
          subtitle2={notification.subtitle2}
          onClose={hideNotification}
          isVisible={notification.isVisible}
        />
      )}
    </div>
  );
}
 
export default Briefings;