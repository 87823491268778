import React from 'react';
import { useNavigate } from "react-router-dom";
import { useGetAllExpiredQuery,
  useGetAllExpiringSoonQuery,
} from "../Global/LMKEmployeeEfes.ts";
import { useGetExpiredOnJobQuery,
  useGetExpiringSoonOnJobQuery,
  useGetExpiredFireQuery,
  useGetExpiringSoonFireQuery,
  useGetExpiredElectroQuery,
  useGetExpiringSoonElectroQuery,
} from "../Global/BriefingsEfesSlice.ts";
import {
  useGetDriverAllExpiredQuery,
  useGetDriverAllExpiringSoonQuery,
} from "../Global/DriverEfesSlice.ts";


const Homepage = () => {

  const { data: data_lmk_pass, error: error_lmk_pass, isLoading: loading_lmk_pass, refetch: refetch_lmk_pass } = useGetAllExpiredQuery();
  const { data: data_lmk_soon, error: error_lmk_soon, isLoading: loading_lmk_soon, refetch: refetch_lmk_soon } = useGetAllExpiringSoonQuery();

  const { data: data_driver_pass, error: error_driver_pass, isLoading: loading_driver_pass } = useGetDriverAllExpiredQuery();
  const { data: data_driver_soon, error: error_driver_soon, isLoading: loading_driver_soon } = useGetDriverAllExpiringSoonQuery();

  const { data: data_onjob_pass, error: error_onjob_pass, isLoading: loading_onjob_pass, refetch: refetch_onjob_pass } = useGetExpiredOnJobQuery();
  const { data: data_onjob_soon, error: error_onjob_soon, isLoading: loading_onjob_soon, refetch: refetch_onjob_soon } = useGetExpiringSoonOnJobQuery();

  const { data: data_fire_pass, error: error_fire_pass, isLoading: loading_fire_pass, refetch: refetch_fire_pass } = useGetExpiredFireQuery();
  const { data: data_fire_soon, error: error_fire_soon, isLoading: loading_fire_soon, refetch: refetch_fire_soon } = useGetExpiringSoonFireQuery();

  const { data: data_electro_pass, error: error_electro_pass, isLoading: loading_electro_pass, refetch: refetch_electro_pass } = useGetExpiredElectroQuery();
  const { data: data_electro_soon, error: error_electro_soon, isLoading: loading_electro_soon, refetch: refetch_electro_soon } = useGetExpiringSoonElectroQuery();


  const calculateTotalBrief = () => {
    const total = [
      data_onjob_pass,
      data_onjob_soon,
      data_fire_pass,
      data_fire_soon,
      data_electro_pass,
      data_electro_soon,
    ].reduce((sum, currentData) => sum + (currentData?.records.length || 0), 0);
  
    return total;
  };


  const role = localStorage.getItem("role_efes");
  const fio = localStorage.getItem("fio_efes");

  const navigate = useNavigate();

  const today = new Date();

  const formattedDate = new Intl.DateTimeFormat("ru-RU", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(today);

  const date_today = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  const nextControlLMK = () => {
    navigate("/efes/control_lmk");
  };

  const nextControlBrief = () => {
    navigate("/efes/control_brief");
  };

  
  return (
    <div className="homepage_container">
      <div className="homepage_header">
        <div className="header_title_info">
          <div>{role}</div>
          <div>{fio}</div>
        </div>
        <img
          src="https://workspace.ru/upload/main/e35/9drglma6601fwku6py9zfurze84xtx7t/reviewsphoto.png"
          width="52px"
          height="52px"
          alt="avatar"
          style={{ marginRight: "15px", marginLeft: "auto" }}
        />
      </div>
      <div className="homepage_dashboard_container" style={{width: "100%"}}>

        <div
          className="block_employee_num"
          style={{ marginLeft: "15px", width: "380px" }}
        >
          <div className="circle_dashboard">
            <img
              src={require("../Assets/calendar.png")}
              alt=""
              width="35px"
              height="35px"
            />
          </div>
          <div className="info_employee_num">
            <div>Сегодня</div>
            <div>{date_today}</div>
          </div>
        </div>

        <div
          className="block_employee_num nextControl"
          onClick={nextControlBrief}
          style={{ marginLeft: "15px", width: "340px" }}
        >
          <div className="circle_dashboard">
            <img
              src={require("../Assets/fire.png")}
              alt=""
              width="35px"
              height="35px"
            />
          </div>
          <div className="info_employee_num">
            <div>Контроль сроков инструктажей</div>
            <div>{calculateTotalBrief()}</div>
          </div>
        </div>

        <div
          className="block_employee_num nextControl"
          onClick={nextControlLMK}
          style={{ marginLeft: "15px", width: "270px" }}
        >
          <div className="circle_dashboard">
            <img
              src={require("../Assets/fire.png")}
              alt=""
              width="35px"
              height="35px"
            />
          </div>
          <div className="info_employee_num">
            <div>Контроль сроков ЛМК</div>
            <div>{Number(data_lmk_pass === null ? 0 : data_lmk_pass) + Number(data_lmk_soon === null ? 0 : data_lmk_soon)}</div>
          </div>
        </div>

        <div
          className="block_employee_num nextControl"
          onClick={nextControlLMK}
          style={{ marginLeft: "15px", width: "330px" }}
        >
          <div className="circle_dashboard">
            <img
              src={require("../Assets/fire.png")}
              alt=""
              width="35px"
              height="35px"
            />
          </div>
          <div className="info_employee_num">
            <div>Контроль сроков водит. удост.</div>
            <div>{Number(data_driver_pass === null ? 0 : data_driver_pass) + Number(data_driver_soon === null ? 0: data_driver_soon)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;