import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Notification from "../../Components/notification.tsx";
import { useGetEmplFriendMarsQuery } from "../../Global/employeeSlice.ts";
import CircularProgress from '@mui/material/CircularProgress';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell, Label } from "recharts";
import axiosInstance from "../../../axiosConfig.js";
import { useGetEmployeesMarsQuery } from "../../Global/employeeSlice.ts";
import Select, { SingleValue } from "react-select";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface EmployeeOption {
    value: number;
    label: string;
}

const Friend = () => {
    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });

    const hideNotification = () => {
        setNotification({
          ...notification,
          isVisible: false,
        });
    };

    const { data: empl, error, isLoading, refetch } = useGetEmplFriendMarsQuery();

    const reqOptions: EmployeeOption[] = empl?.map((employee) => ({
        value: employee.id,
        label: employee.fio,
    })) || [];

    const columns: GridColDef[] = [
        {
            field: "fio",
            headerName: "ФИО",
            width: 250,
            valueGetter: (value, row) => {
                if (row.fio !== ""){
                    return row.fio;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "friend",
            headerName: '"Акция "приведи друга"',
            width: 80,
            valueGetter: (value, row) => {
                if (row.friendaction === true){
                    return "✅";
                } else {
                    return "❌";
                }
            },
        },
        {
            field: "req",
            headerName: "Кто привел",
            type: "string",
            width: 250,
            editable: false,
            valueGetter: (value, row) => {
              if (row.fioreqemployee != null){
                return row.fioreqemployee;
              }
            },
        },
        {
            field: "dateemployment",
            headerName: "Дата трудоуст.",
            width: 120,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
              if (!row.dateemployment) {
                return null;
              }
              const [day, month, year] = row.dateemployment.split(".");
              return new Date(`${year}-${month}-${day}`);
            },
        },
    ];

    if (isLoading)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
            </div>
        </div>
    ;
        
    if (error) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
            <img src={require("../../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
        </div>
    ;

    return (
        <div className="active_employee_efes">
            <div className="container_briefings_efes" style={{width: "770px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <DataGrid
                    style={{
                    marginTop: 20,
                    }}
                    rows={empl ?? []}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 12,
                        },
                    },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    pageSizeOptions={[12]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{
                    toolbar: GridToolbar,
                    }}
                    slotProps={{ toolbar: { showQuickFilter: true } }}
                />
            </div>

            {notification.isVisible && (
                <Notification
                status={notification.status}
                title={notification.title}
                subtitle={notification.subtitle}
                onClose={hideNotification}
                isVisible={notification.isVisible}
                subtitle2={notification.subtitle2}
                />
            )}
        </div>
    );
}

export default Friend;