import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import axiosInstance from '../axiosConfigEfes';
import { ruRU } from "@mui/x-data-grid/locales";
import CircularProgress from '@mui/material/CircularProgress';
import Notification from "../Components/Notification.tsx";
import { useGetAllLMKQuery } from "../Global/LMKEmployeeEfes.ts";
import Select, { SingleValue } from "react-select";
import { Employee_active, useGetEmployeesQuery } from "../Global/EmployeeActiveSlice.ts";
import Button from "@mui/material/Button";

interface EmployeeOption {
    value: number;
    label: string;
}

const LMK = () => {
    const { data: data_lmk, error, isLoading, refetch } = useGetAllLMKQuery();
    const { data: employees_active, error: errorEmpl, isLoading: isLoadingEmpl } = useGetEmployeesQuery();

    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
    const [selectedEmployeeFio, setSelectedEmployeeFio] = useState("");
    const [showModalUpd, setShowModalUpd] = useState(false);
    const [showModalLMK, setShowModalLMK] = useState(false);

    const [datelmk, setDatelmk] = useState("");
    const [dateflg, setDateflg] = useState("");
    const [dateadsm, setDateadsm] = useState("");
    const [dateses, setDateses] = useState("");

    const [dataEmployee, setDataEmployee] = useState<Employee_active[]>(employees_active || []);
    
    const [selectedEmployee, setSelectedEmployee] =
        useState<SingleValue<EmployeeOption>>(null);

    const employeeOptions: EmployeeOption[] = employees_active?.map((employee) => ({
        value: employee.id,
        label: employee.fio,
    }));

    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });
    
    const hideNotification = () => {
        setNotification({
            ...notification,
            isVisible: false,
        });
    };

    const columns: GridColDef[] = [
        {
            field: "fio",
            headerName: "ФИО",
            width: 250,
            valueGetter: (value, row) => {
                if (row.employee !== ""){
                    return row.employee.fio;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "datelmk",
            headerName: "ЛМК",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
            if (!row.datelmk) {
                return null;
            }
            const [day, month, year] = row.datelmk.split(".");
            return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "dateflg",
            headerName: "ФЛГ",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.dateflg) {
                    return null;
                }
                const [day, month, year] = row.dateflg.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "dateadsm",
            headerName: "АДСМ",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.dateadsm) {
                    return null;
                }
                const [day, month, year] = row.dateadsm.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "dateses",
            headerName: "СЭС",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.dateses) {
                    return null;
                }
                const [day, month, year] = row.dateses.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "button",
            headerName: "",
            width: 60,
            renderCell: (params) => (
              <div>
                <IconButton onClick={() => handleEdit(params.row.id, params.row.employee.fio)}>
                  <EditIcon />
                </IconButton>
              </div>
            ),
        },
    ];

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const handleEdit = async (id, fio) => {
        setNotification({
            status: "loading",
            title: "Получение данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
        });

        await axiosInstance
          .get(`/efes/lmk/get_lmk/${id}`)
          .then((response) => {
            const employee = response.data;
            if (employee.datelmk === null){
                setDatelmk("");
            } else {
                setDatelmk(formatDate(employee.datelmk));
            }
            if (employee.dateflg === null){
                setDateflg("");
            } else {
                setDateflg(formatDate(employee.dateflg));
            }
            if (employee.dateadsm === null){
                setDateadsm("");
            } else {
                setDateadsm(formatDate(employee.dateadsm));
            }
            if (employee.dateses === null){
                setDateses("");
            } else {
                setDateses(formatDate(employee.dateses));
            }
          })
          .catch((error) => {
            console.error(
              "Ошибка при получении данных",
              error
            );
        })

        hideNotification();

        setSelectedEmployeeId(id);
        setSelectedEmployeeFio(fio);
        setShowModalUpd(true);
    };

    const confirmUpd = async () => {
        let formattedDate1 = "";
        if (datelmk){
            formattedDate1 = new Date(datelmk).toISOString();
        }

        let formattedDate2 = "";
        if (dateflg){
            formattedDate2 = new Date(dateflg).toISOString();
        }

        let formattedDate3 = "";
        if (dateadsm){
            formattedDate3 = new Date(dateadsm).toISOString();
        }

        let formattedDate4 = "";
        if (dateses){
            formattedDate4 = new Date(dateses).toISOString();
        }
    
        const empData = {
          id: selectedEmployeeId,
          datelmk: formattedDate1,
          dateflg: formattedDate2,
          dateadsm: formattedDate3,
          dateses: formattedDate4,
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
      };
    
        try {
    
          setNotification({
              status: "loading",
              title: "Обновление данных",
              subtitle: "Пожалуйста, подождите...",
              subtitle2: <div></div>,
              isVisible: true,
          });
    
          await axiosInstance.put(`/efes/lmk/edit_lmk`, empData);
          
          setShowModalUpd(false);
          setDatelmk("");
          setDateflg("");
          setDateadsm("");
          setDateses("");
          refetch();
    
          setNotification({
            status: "success",
            title: "Успешно",
            subtitle: "ЛМК отредактирована",
            subtitle2: <div></div>,
            isVisible: true,
          });
    
        } catch (error) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при обновлении",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
    };

    const confirm = async () => {
        let formattedDate1 = "";
        if (datelmk){
            formattedDate1 = new Date(datelmk).toISOString();
        }

        let formattedDate2 = "";
        if (dateflg){
            formattedDate2 = new Date(dateflg).toISOString();
        }

        let formattedDate3 = "";
        if (dateadsm){
            formattedDate3 = new Date(dateadsm).toISOString();
        }

        let formattedDate4 = "";
        if (dateses){
            formattedDate4 = new Date(dateses).toISOString();
        }
    
        const empData = {
            employeeid: selectedEmployee?.value,
            datelmk: formattedDate1,
            dateflg: formattedDate2,
            dateadsm: formattedDate3,
            dateses: formattedDate4,
            fio_akk: localStorage.getItem("fio_efes"),
            role_akk: localStorage.getItem("role_efes"),
        };
    
        try {
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
            await axiosInstance.post(`/efes/lmk/add_new_lmk`, empData);

            setShowModalLMK(false);
            setSelectedEmployee(null);
            setDatelmk("");
            setDateflg("");
            setDateadsm("");
            setDateses("");
            refetch();
          
            setNotification({
                status: "success",
                title: "Успешно",
                subtitle: "ЛМК добавлена",
                subtitle2: (
                    <div>
                    </div>
                ),
                isVisible: true,
            });
        } catch (error) {
            setShowModalLMK(false);
            setSelectedEmployee(null);
            setDatelmk("");
            setDateflg("");
            setDateadsm("");
            setDateses("");
            const errorMessage = error.response?.data || "Ошибка при добавлении";
            setNotification({
                status: "error",
                title: "Ошибка",
                subtitle: errorMessage,
                subtitle2: <div></div>,
                isVisible: true,
            });
        }
    };

    if (isLoading || isLoadingEmpl)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
          </div>
        </div>
    ;
        
    if (error || errorEmpl) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
          <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
          <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
      </div>
    ;

    return (
        <div className="active_employee_efes">
            <div className="container_briefings_efes" style={{width: "740px"}}>
                <Button
                    variant="contained"
                    onClick={() => setShowModalLMK(true)}
                    style={{ marginTop: "20px" }}
                    >
                    Добавить данные ЛМК
                </Button>
                <DataGrid
                    style={{
                    marginTop: 20,
                    }}
                    rows={data_lmk ?? []}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 12,
                        },
                    },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    pageSizeOptions={[12]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{
                    toolbar: GridToolbar,
                    }}
                    slotProps={{ toolbar: { showQuickFilter: true } }}
                />
            </div>

            {/* Модальное окно для редактирования */}
            {showModalUpd && (
                <div className="modal_brief_employee_efes">
                    <div className="modal_cont_brief_employee_efes" style={{height: "290px"}}>
                        <h3 className='title_employee_efes'>Редактирование ЛМК</h3>
                        <label style={{display:"flex", flexDirection: "row"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

                        <div className="dat">
                            <label htmlFor="datelmk">Дата прохождения ЛМК:</label>
                            <input
                                type="date"
                                id="datelmk"
                                name="datelmk"
                                value={datelmk}
                                onChange={(e) => setDatelmk(e.target.value)}
                                style={{marginTop: "15px", marginBottom: "10px", width: "170px"}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="dateflg">Дата прохождения ФЛГ:</label>
                            <input
                                type="date"
                                id="dateflg"
                                name="dateflg"
                                value={dateflg}
                                onChange={(e) => setDateflg(e.target.value)}
                                style={{marginBottom: "10px", width: "170px", marginLeft: "3px"}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="dateadsm">Дата прохождения АДСМ:</label>
                            <input
                                type="date"
                                id="dateadsm"
                                name="dateadsm"
                                value={dateadsm}
                                onChange={(e) => setDateadsm(e.target.value)}
                                style={{ marginBottom: "10px", width: "157px"}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="dateses">Дата прохождения СЭС:</label>
                            <input
                                type="date"
                                id="dateses"
                                name="dateses"
                                value={dateses}
                                onChange={(e) => setDateses(e.target.value)}
                                style={{ marginTop: "10px", marginBottom: "5px",  width: "157px", marginLeft: "18px"}}
                            />
                        </div>

                        <div className="modal_act_fir">
                            <button onClick={confirmUpd}>Обновить</button>
                            <button onClick={() => setShowModalUpd(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Модальное окно для добавления вод. уд.*/}
            {showModalLMK && (
                <div className="modal_brief_employee_efes">
                    <div className="modal_cont_brief_employee_efes" style={{height: "330px"}}>
                        <h3 className='title_employee_efes' style={{}}>Добавление ЛМК</h3>
                        
                        <div className="tr_cont_select">
                            <Select
                                id="employee-dropdown"
                                value={selectedEmployee}
                                options={employeeOptions}
                                onChange={(selectedOption: SingleValue<EmployeeOption> | null) => setSelectedEmployee(selectedOption)}
                                placeholder="Выберите сотрудника"
                                styles={{}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="datelmk">Дата прохождения ЛМК:</label>
                            <input
                                type="date"
                                id="datelmk"
                                name="datelmk"
                                value={datelmk}
                                onChange={(e) => setDatelmk(e.target.value)}
                                style={{marginTop: "15px", marginBottom: "10px", width: "170px"}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="dateflg">Дата прохождения ФЛГ:</label>
                            <input
                                type="date"
                                id="dateflg"
                                name="dateflg"
                                value={dateflg}
                                onChange={(e) => setDateflg(e.target.value)}
                                style={{marginBottom: "10px", width: "170px", marginLeft: "3px"}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="dateadsm">Дата прохождения АДСМ:</label>
                            <input
                                type="date"
                                id="dateadsm"
                                name="dateadsm"
                                value={dateadsm}
                                onChange={(e) => setDateadsm(e.target.value)}
                                style={{ marginBottom: "10px", width: "157px"}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="dateses">Дата прохождения СЭС:</label>
                            <input
                                type="date"
                                id="dateses"
                                name="dateses"
                                value={dateses}
                                onChange={(e) => setDateses(e.target.value)}
                                style={{ marginTop: "10px", marginBottom: "5px",  width: "157px", marginLeft: "18px"}}
                            />
                        </div>

                        <div className="modal_act_fir">
                            <button onClick={confirm}>Добавить</button>
                            <button onClick={() => setShowModalLMK(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {notification.isVisible && (
                <Notification
                    status={notification.status}
                    title={notification.title}
                    subtitle={notification.subtitle}
                    onClose={hideNotification}
                    isVisible={notification.isVisible}
                    subtitle2={notification.subtitle2}
                />
            )}
        </div>
    );
}

export default LMK;