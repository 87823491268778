import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';


export interface Area {
  id: number;
  namearea: string;
}

export const areaSlice = createApi({
  reducerPath: 'area',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getArea: builder.query<Area[], void>({
      query: () => ({ url: '/efes/all_area', method: 'GET' }),
    }),
    getNonActiveArea: builder.query<Area[], void>({
      query: () => ({ url: '/efes/all_area_false', method: 'GET' }),
    }),
  }),
});

export const { useGetAreaQuery, useGetNonActiveAreaQuery } = areaSlice;