import React, { useState } from "react";
import { useGetGenderAgeEmplMarsQuery } from "../../Global/employeeSlice.ts";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, LabelList } from "recharts";
import {
    TextField,
    Box,
  } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";

const TableGender = () => {
    const { data: employees, error, isLoading } = useGetGenderAgeEmplMarsQuery();

    const [minAge, setMinAge] = useState<number | "">("");
    const [maxAge, setMaxAge] = useState<number | "">("");

    // Фильтруем сотрудников по возрасту
    const filteredEmployees = employees?.filter((emp) => {
        if (minAge !== "" && emp.age < minAge) return false;
        if (maxAge !== "" && emp.age > maxAge) return false;
        return true;
    });

    // Подсчет мужчин и женщин
    const totalMen = employees?.filter((emp) => emp.gender === "М").length || 0;
    const totalWomen = employees?.filter((emp) => emp.gender === "Ж").length || 0;

    const filteredMen = filteredEmployees?.filter((emp) => emp.gender === "М").length || 0;
    const filteredWomen = filteredEmployees?.filter((emp) => emp.gender === "Ж").length || 0;

    const chartData = [
        { name: "Отфильтрованные", М: filteredMen, Ж: filteredWomen },
        { name: "Все сотрудники", М: totalMen, Ж: totalWomen }
    ];

    const columns: GridColDef[] = [
        {
            field: "fio",
            headerName: "ФИО",
            width: 250,
            valueGetter: (value, row) => {
                if (row.fio !== ""){
                    return row.fio;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "gender",
            headerName: "Пол",
            width: 40,
            editable: false,
        },
        {
            field: "age",
            headerName: "Возраст",
            width: 40,
            editable: false,
        },
        {
            field: "shift",
            headerName: "Смена",
            width: 150,
            valueGetter: (value, row) => {
                if (row.shift !== ""){
                    return row.shift;
                } else {
                    return "Нет данных";
                }
            },
        },
    ];


    if (isLoading)
            return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
                <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
                <CircularProgress />
                </div>
            </div>
    ;
        
    if (error) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
            <img src={require("../../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
        </div>
    ;

    return (
        <div className="active_employee_efes">
            <div className="container_briefings_efes" style={{width: "870px", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "30px"}}>

                <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
                    <TextField
                    label="Минимальный возраст"
                    type="number"
                    value={minAge}
                    onChange={(e) => setMinAge(e.target.value === "" ? "" : Number(e.target.value))}
                    variant="outlined"
                    size="small"
                    />
                    <TextField
                    label="Максимальный возраст"
                    type="number"
                    value={maxAge}
                    onChange={(e) => setMaxAge(e.target.value === "" ? "" : Number(e.target.value))}
                    variant="outlined"
                    size="small"
                    />
                </Box>

                <div>
                    <BarChart
                        width={800}
                        height={300}
                        data={chartData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="М" fill="#8884d8">
                            <LabelList dataKey="М" position="top" />
                        </Bar>
                        <Bar dataKey="Ж" fill="#f387ff">
                            <LabelList dataKey="Ж" position="top" />
                        </Bar>
                    </BarChart>
                </div>

                <div style={{width: "570px"}}>
                    <DataGrid
                        style={{
                        marginTop: 20,
                        }}
                        rows={filteredEmployees ?? []}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 6,
                            },
                        },
                        }}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        pageSizeOptions={[6]}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        slots={{
                        toolbar: GridToolbar,
                        }}
                        slotProps={{ toolbar: { showQuickFilter: true } }}
                    />
                </div>
            </div>
        </div>
    );
}

export default TableGender;