import React, { useState, useEffect } from "react";
import axiosInstance from "../../axiosConfig";
import "../Styles/vacations_all.scss";
import { Select, MenuItem, FormControl, InputLabel, CircularProgress } from "@mui/material";

interface VacationData {
  [employeeName: string]: string[];
}

const AllVacations = () => {
  const [data, setData] = useState<VacationData>({});
  const [year, setYear] = useState<number>(2025);
  const [loading, setLoading] = useState(false); // Для отображения загрузки
  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  // Список годов для выбора
  const years = Array.from({ length: 10 }, (_, i) => 2020 + i); // Годы от 2020 до 2029

  useEffect(() => {
    setLoading(true); // Включаем загрузку
    axiosInstance
      .get(`/vacations/vacations_all_table/${year}`)
      .then((response) => {
        setData(response.data);
        setLoading(false); // Выключаем загрузку после получения данных
      })
      .catch((error) => {
        console.error("Error fetching vacations", error);
        setLoading(false); // Выключаем загрузку при ошибке
      });
  }, [year]);

  const monthNames = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  const generateCalendarWeeks = () => {
    const weeks: { week: number; days: { month: number; day: number }[] }[] = [];
    let weekNumber = 1;
    let currentWeekDays: { month: number; day: number }[] = [];
  
    // Перебираем все дни в году
    let currentDate = new Date(year, 0, 1); // Начало года
    const endOfYear = new Date(year + 1, 0, 1); // Конец года
  
    while (currentDate < endOfYear) {
      const dayOfWeek = currentDate.getDay(); // День недели (0 - воскресенье, 6 - суббота)
      const month = currentDate.getMonth(); // Месяц (0 - январь, 11 - декабрь)
      const day = currentDate.getDate(); // День месяца
  
      currentWeekDays.push({ month, day });
  
      // Если неделя завершена (7 дней)
      if (currentWeekDays.length === 7 || currentDate.getTime() === endOfYear.getTime()) {
        weeks.push({ week: weekNumber, days: currentWeekDays });
        weekNumber++;
        currentWeekDays = []; // Сброс текущей недели
      }
  
      currentDate.setDate(currentDate.getDate() + 1); // Переход к следующему дню
    }
  
    return weeks;
  };
  

  const calendarWeeks = generateCalendarWeeks();

  const mergedWeeks = calendarWeeks.map((week) => {
    const uniqueMonths = Array.from(new Set(week.days.map((d) => d.month)));
    return { week: week.week, months: uniqueMonths };
  });

  const renderHeader = () => {
    // Массив для подсчета количества недель, которые относятся к каждому месяцу
    const monthWeekCounts = new Array(monthNames.length).fill(0);
  
    // Проходим по всем неделям и подсчитываем их для каждого месяца
    mergedWeeks.forEach(({ months }) => {
      months.forEach((monthIndex) => {
        monthWeekCounts[monthIndex]++;
      });
    });
  
    return (
      <thead>
        {/* Шапка с месяцами */}
        {/* <tr className="month-header">
          <th className="all_vacations_table_fio" style={{ zIndex: "5" }}></th>
          {monthNames.map((monthName, index) => {
            return (
              <th key={index} colSpan={3} style={{ textAlign: "center" }}>
                {monthName}
              </th>
            );
          })}
        </tr> */}
  
        {/* Шапка с неделями */}
        <tr className="week-header">
          <th className="all_vacations_table_fio" style={{ zIndex: "5" }}>ФИО</th>
          {mergedWeeks.map(({ week, months }, index) => {
            const style = {
              backgroundColor:
                months.length === 1
                  ? "#f0f0f0" // Если неделя полностью в одном месяце
                  : "linear-gradient(to right, #f0f0f0 50%, #e8e8e8 50%)", // Если неделя пересекает два месяца
              borderLeft: months.length === 2 ? "1px solid #ccc" : undefined,
            };
            return (
              <th
                key={index}
                colSpan={1}
                style={{
                  textAlign: "center",
                  ...style,
                }}
              >
                Неделя {week}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderRows = () => {
    return Object.entries(data).map(([name, dates], rowIndex) => {
      const vacationDays = new Set(
        dates.map((date) => {
          const d = new Date(date);
          return `${d.getDate()}/${d.getMonth() + 1}`;
        })
      );

      return (
        <tr
          key={name}
          onClick={() => setSelectedRow(rowIndex)} // Устанавливаем выбранную строку
          className={selectedRow === rowIndex ? "selected-row" : ""} // Добавляем класс для выделенной строки
        >
          <td>{name}</td>
          {calendarWeeks.map(({ days }, index) => {
            const hasVacation = days.some(({ month, day }) =>
              vacationDays.has(`${day}/${month + 1}`)
            );
            return (
              <td key={index} className={hasVacation ? "vacation" : ""}></td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", marginTop: "30px"}}>
      {/* Выбор года */}

      <label>
        Год:
        <select
          value={year}
          style={{ marginBottom: "20px", marginLeft: "15px" }}
          onChange={(e) => setYear(parseInt(e.target.value, 10))}
        >
          {[2023, 2024, 2025, 2026].map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </label>

      <div style={{
        width: "80%",
        height: "800px",
        overflowY: "auto", 
        overflowX: "auto", 
      }}>
        {/* Индикатор загрузки */}
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <CircularProgress />
          </div>
        ) : (
          <table className="vacation-table">
            {renderHeader()}
            <tbody>{renderRows()}</tbody>
          </table>
        )}
      </div>

    </div>
  );
};

export default AllVacations;
