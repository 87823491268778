import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';

export const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export interface BriefingsEfes {
  fio: string;
  dateinput: string | null;
  dateonjob: string | null;
  datefire: string | null;
  dateelectro: string | null;
  numelectro: string;
}

// Интерфейсы
export interface BriefingsEfesPr {
  id: number;
  employee: {
    id: number;
    fio: string;
  };
  dateonjob: string | null;
  datefire: string | null;
  dateelectro: string | null;
  numelectro: string;
}

export interface ExpiryResult<T> {
  count: number;
  records: T[];
}

export const briefingsEfesSlice = createApi({
  reducerPath: 'briefingsEfes',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['BriefingsEfes'],
  endpoints: (builder) => ({
    getBriefingsEfes: builder.query<BriefingsEfes[], void>({
      query: () => ({ url: '/efes/all_brief', method: 'GET' }),
      transformResponse: (response: BriefingsEfes[]) =>
        response.map((history) => ({
          ...history,
          dateinput: formatDate(history.dateinput),
          dateonjob: formatDate(history.dateonjob),
          datefire: formatDate(history.datefire),
          dateelectro: formatDate(history.dateelectro),
        })),
      providesTags: ['BriefingsEfes'],
    }),
    getExpiredOnJob: builder.query<ExpiryResult<BriefingsEfesPr>, void>({
      query: () => ({ url: '/efes/onjob/expired', method: 'GET' }),
      transformResponse: (response: ExpiryResult<BriefingsEfesPr>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          dateonjob: formatDate(record.dateonjob),
        })),
      }),
      providesTags: ['BriefingsEfes'],
    }),
    getExpiringSoonOnJob: builder.query<ExpiryResult<BriefingsEfesPr>, void>({
      query: () => ({ url: '/efes/onjob/expiring-soon', method: 'GET' }),
      transformResponse: (response: ExpiryResult<BriefingsEfesPr>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          dateonjob: formatDate(record.dateonjob),
        })),
      }),
      providesTags: ['BriefingsEfes'],
    }),
    getExpiredFire: builder.query<ExpiryResult<BriefingsEfesPr>, void>({
      query: () => ({ url: '/efes/fire/expired', method: 'GET' }),
      transformResponse: (response: ExpiryResult<BriefingsEfesPr>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          datefire: formatDate(record.datefire),
        })),
      }),
      providesTags: ['BriefingsEfes'],
    }),
    getExpiringSoonFire: builder.query<ExpiryResult<BriefingsEfesPr>, void>({
      query: () => ({ url: '/efes/fire/expiring-soon', method: 'GET' }),
      transformResponse: (response: ExpiryResult<BriefingsEfesPr>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          datefire: formatDate(record.datefire),
        })),
      }),
      providesTags: ['BriefingsEfes'],
    }),
    getExpiredElectro: builder.query<ExpiryResult<BriefingsEfesPr>, void>({
      query: () => ({ url: '/efes/electro/expired', method: 'GET' }),
      transformResponse: (response: ExpiryResult<BriefingsEfesPr>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          dateelectro: formatDate(record.dateelectro),
        })),
      }),
      providesTags: ['BriefingsEfes'],
    }),
    getExpiringSoonElectro: builder.query<ExpiryResult<BriefingsEfesPr>, void>({
      query: () => ({ url: '/efes/electro/expiring-soon', method: 'GET' }),
      transformResponse: (response: ExpiryResult<BriefingsEfesPr>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          dateelectro: formatDate(record.dateelectro),
        })),
      }),
      providesTags: ['BriefingsEfes'],
    }),
  }),
});

export const { 
  useGetBriefingsEfesQuery,
  useGetExpiredOnJobQuery,
  useGetExpiringSoonOnJobQuery,
  useGetExpiredFireQuery,
  useGetExpiringSoonFireQuery,
  useGetExpiredElectroQuery,
  useGetExpiringSoonElectroQuery,
 } = briefingsEfesSlice;