import React from "react";
import '../Styles/notification.scss';
import CircularProgress from '@mui/material/CircularProgress';

type NotificationProps = {
  status: string;
  title: string;
  subtitle: string;
  subtitle2: any;
  onClose: () => void;
  onAction?: () => void;
  isVisible: boolean;
  actionText?: string;
};

const Notification = ({
  status,
  title,
  subtitle,
  subtitle2,
  onClose,
  onAction,
  actionText = "Action",
  isVisible,
}: NotificationProps) => {
  return (
    <div className={`modal_notification ${isVisible ? "visible" : ""}`}>
      <div className="container_notification">
        <div className="icon_notification">
          {status === "loading" ? (
            <CircularProgress size={80} style={{padding: "10px"}}/>
          ) : status === "success" ? (
            <img src={require("../Assets/success.svg").default} alt="Success" />
          ) : (
            <img src={require("../Assets/error.svg").default} alt="Error" />
          )}
        </div>
        <div className="title_notification">{title}</div>
        <div className="subtitle_notification">{subtitle}</div>
        {subtitle2}
        <div className="btn_notif">
          {onAction && actionText && (
            <button className="action_button" onClick={onAction}>
              {actionText}
            </button>
          )}
          {status !== "loading" && (
            <button className="close_button" onClick={onClose}>
              Закрыть
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;