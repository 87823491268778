import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';

// Форматирование даты
export const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

// Интерфейсы
export interface EmployeeLMKEfes {
  id: number;
  employee: {
    id: number;
    fio: string;
  };
  datelmk: string | null;
  dateadsm: string | null;
  dateflg: string | null;
  dateses: string | null;
}

export interface ExpiryResult<T> {
  count: number;
  records: T[];
}

export interface LMKEmployee {
  id: number;
  employee: {
    id: number;
    fio: string;
  };
  datelmk: string | null;
  dateflg: string | null;
  dateadsm: string | null;
  dateses: string | null;
}

// API с использованием RTK Query
export const LMKEmployeeApi = createApi({
  reducerPath: 'LMKEmployeeApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['EmployeeLMKEfes'],
  endpoints: (builder) => ({
    getAllLMK: builder.query<LMKEmployee[], void>({
      query: () => ({ url: '/efes/lmk/get_all', method: 'GET' }),
      transformResponse: (response: LMKEmployee[]): LMKEmployee[] =>
        response.map((history) => ({
          ...history,
          datelmk: formatDate(history.datelmk),
          dateflg: formatDate(history.dateflg),
          dateadsm: formatDate(history.dateadsm),
          dateses: formatDate(history.dateses),
        })),
      providesTags: ['EmployeeLMKEfes'],
    }),
    getExpiredLMK: builder.query<ExpiryResult<EmployeeLMKEfes>, void>({
      query: () => ({ url: '/efes/lmk/expired', method: 'GET' }),
      transformResponse: (response: ExpiryResult<EmployeeLMKEfes>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          datelmk: formatDate(record.datelmk),
        })),
      }),
      providesTags: ['EmployeeLMKEfes'],
    }),
    getExpiringSoonLMK: builder.query<ExpiryResult<EmployeeLMKEfes>, void>({
      query: () => ({ url: '/efes/lmk/expiring-soon', method: 'GET' }),
      transformResponse: (response: ExpiryResult<EmployeeLMKEfes>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          datelmk: formatDate(record.datelmk),
        })),
      }),
      providesTags: ['EmployeeLMKEfes'],
    }),
    getExpiredADSM: builder.query<ExpiryResult<EmployeeLMKEfes>, void>({
      query: () => ({ url: '/efes/adsm/expired', method: 'GET' }),
      transformResponse: (response: ExpiryResult<EmployeeLMKEfes>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          dateadsm: formatDate(record.dateadsm),
        })),
      }),
      providesTags: ['EmployeeLMKEfes'],
    }),
    getExpiringSoonADSM: builder.query<ExpiryResult<EmployeeLMKEfes>, void>({
      query: () => ({ url: '/efes/adsm/expiring-soon', method: 'GET' }),
      transformResponse: (response: ExpiryResult<EmployeeLMKEfes>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          dateadsm: formatDate(record.dateadsm),
        })),
      }),
      providesTags: ['EmployeeLMKEfes'],
    }),
    getExpiredFLG: builder.query<ExpiryResult<EmployeeLMKEfes>, void>({
      query: () => ({ url: '/efes/flg/expired', method: 'GET' }),
      transformResponse: (response: ExpiryResult<EmployeeLMKEfes>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          dateflg: formatDate(record.dateflg),
        })),
      }),
      providesTags: ['EmployeeLMKEfes'],
    }),
    getExpiringSoonFLG: builder.query<ExpiryResult<EmployeeLMKEfes>, void>({
      query: () => ({ url: '/efes/flg/expiring-soon', method: 'GET' }),
      transformResponse: (response: ExpiryResult<EmployeeLMKEfes>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          dateflg: formatDate(record.dateflg),
        })),
      }),
      providesTags: ['EmployeeLMKEfes'],
    }),
    getExpiredSES: builder.query<ExpiryResult<EmployeeLMKEfes>, void>({
      query: () => ({ url: '/efes/ses/expired', method: 'GET' }),
      transformResponse: (response: ExpiryResult<EmployeeLMKEfes>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          dateses: formatDate(record.dateses),
        })),
      }),
      providesTags: ['EmployeeLMKEfes'],
    }),
    getExpiringSoonSES: builder.query<ExpiryResult<EmployeeLMKEfes>, void>({
      query: () => ({ url: '/efes/ses/expiring-soon', method: 'GET' }),
      transformResponse: (response: ExpiryResult<EmployeeLMKEfes>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          dateses: formatDate(record.dateses),
        })),
      }),
      providesTags: ['EmployeeLMKEfes'],
    }),
    getAllExpired: builder.query<Number, void>({
      query: () => ({ url: '/efes/lmk/all_count_expired', method: 'GET' }),
      providesTags: ['EmployeeLMKEfes'],
    }),
    getAllExpiringSoon: builder.query<Number, void>({
      query: () => ({ url: '/efes/lmk/all_count_expiring_soon', method: 'GET' }),
      providesTags: ['EmployeeLMKEfes'],
    }),
  }),
});

// Хуки для RTK Query
export const {
  useGetExpiredLMKQuery,
  useGetExpiringSoonLMKQuery,
  useGetExpiredADSMQuery,
  useGetExpiringSoonADSMQuery,
  useGetExpiredFLGQuery,
  useGetExpiringSoonFLGQuery,
  useGetAllLMKQuery,
  useGetExpiredSESQuery,
  useGetExpiringSoonSESQuery,
  useGetAllExpiredQuery,
  useGetAllExpiringSoonQuery,
} = LMKEmployeeApi;

export const LMKEmployeeApiReducer = LMKEmployeeApi.reducer;
