import React, { useState } from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from "@mui/material/IconButton";
import axiosInstance from "../../axiosConfig.js";
import Notification from "../Components/notification.tsx";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useGetFiredEmployeeMarsQuery } from "../Global/firedEmployeeSliceMars.ts";

const TableFired = () => {
    const { data: fired_employee, error: fired_employee_error, isLoading: fired_employee_loading, refetch: fired_employee_refetch } = useGetFiredEmployeeMarsQuery();

    const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
    const [selectedEmployeeFio, setSelectedEmployeeFio] = useState("");
    const [dateemployment, setDateEmployment] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
    });

    const [showModalRef, setShowModalRef] = useState(false);

    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });
    
    const hideNotification = () => {
        setNotification({
          ...notification,
          isVisible: false,
        });
    };

    const columnsFrd: GridColDef[] = [
        {
          field: "fio",
          headerName: "ФИО",
          width: 250,
          type: "string",
          editable: false,
          valueGetter: (value, row) => {
            return row.fio;
          },
        },
        {
          field: "gender",
          headerName: "Пол",
          width: 40,
          editable: false,
        },
        {
          field: "years",
          headerName: "Возраст",
          width: 40,
          editable: false,
          valueGetter: (value, row) => {
            if (!row.datebirthday) {
              return null;
            }
            const [day, month, year] = row.datebirthday.split(".");
            const startDate = new Date(`${year}-${month}-${day}`);
            const now = new Date();
            let years = now.getFullYear() - startDate.getFullYear();
            return years;
          },
        },
        {
          field: "post",
          headerName: "Должность",
          width: 250,
          type: "string",
          editable: false,
          valueGetter: (value, row) => {
            if (row.mainPost) {
              return row.mainPost.namepost;
            } else {
              return "Нет данных";
            }
          },
        },
        {
          field: "area",
          headerName: "Основной участок",
          width: 200,
          editable: false,
          valueGetter: (value, row) => {
            if (row.area) {
              return row.area.area.namearea;
            } else {
              return "Нет данных";
            }
          },
        },
        {
          field: "shift",
          headerName: "Смена",
          width: 200,
          editable: false,
          valueGetter: (value, row) => {
            if (row.shift) {
              return row.shift.nameshift;
            } else {
              return "Нет данных";
            }
          },
        },
        {
          field: "phone",
          headerName: "Номер телефона",
          width: 130,
          editable: false,
        },
        {
          field: "dateemployment",
          headerName: "Дата трудоуст.",
          width: 130,
          editable: false,
          type: "date",
          valueGetter: (value, row) => {
            if (!row.dateemployment) {
              return null;
            }
            const [day, month, year] = row.dateemployment.split(".");
            return new Date(`${year}-${month}-${day}`);
          },
        },
        {
          field: "datefired",
          headerName: "Дата увольнения",
          width: 140,
          editable: false,
          type: "date",
          valueGetter: (value, row) => {
            if (!row.datefired) {
              return null;
            }
            const [day, month, year] = row.datefired.split(".");
            return new Date(`${year}-${month}-${day}`);
          },
        },
        {
          field: "reason",
          headerName: "Причина увольнения",
          width: 300,
          type: "string",
          editable: false,
        },
        {
          field: "dateedst",
          headerName: "Стаж",
          width: 100,
          editable: false,
          valueGetter: (value, row) => {
            if (!row.dateemployment || !row.datefired) {
              return null;
            }
            const [day, month, year] = row.dateemployment.split(".");
            const startDate = new Date(`${year}-${month}-${day}`);
            const [day1, month1, year1] = row.datefired.split(".");
            const endDate = new Date(`${year1}-${month1}-${day1}`);
            const { formattedExperience } = calculateWorkExperience(
              startDate,
              endDate
            );
            return formattedExperience;
          },
        },
        {
          field: "button",
          headerName: "",
          width: 80,
          renderCell: (params) => (
            <div>
              {localStorage.getItem("role") !== "Бригадир" && (
                <IconButton onClick={() => {setShowModalRef(true); setSelectedEmployeeId(params.row.id); setSelectedEmployeeFio(params.row.fio)}}>
                  <AutorenewIcon />
                </IconButton>
              )}
            </div>
          ),
        },
    ];

    const confirmRefrFired = async () => {
        const formattedDate = new Date(dateemployment).toISOString();
    
        const data1 = {
            id: selectedEmployeeId,
            dateemployment: formattedDate,
            fio_akk: localStorage.getItem("fio_efes"),
            role_akk: localStorage.getItem("role_efes"),
        };
    
        try {
    
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
            await axiosInstance.delete(`/fired/delete_firedempl`, { data: data1 });
            setShowModalRef(false);

            fired_employee_refetch();

            const refetchPromise = fired_employee_refetch();
            await refetchPromise;

            setNotification({
                status: "success",
                title: "Успешно",
                subtitle: "Сотрудник восстановлен",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
        } catch (error) {
            setNotification({
                status: "error",
                title: "Ошибка",
                subtitle: "Ошибка при восстановлении",
                subtitle2: <div></div>,
                isVisible: true,
            });
        }
    };

    const calculateWorkExperience = (startDate, endDate) => {
        const now = new Date(endDate);
        const start = new Date(startDate);
    
        let years = now.getFullYear() - start.getFullYear();
        let months = now.getMonth() - start.getMonth();
        let days = now.getDate() - start.getDate();
    
        if (months < 0) {
          years--;
          months += 12;
        }
    
        if (days < 0) {
          months--;
          const lastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
          days += lastMonth.getDate();
        }
    
        const totalMonths = years * 12 + months;
    
        let experience = "";
        if (years > 0) {
          experience += `${years} ${
            years === 1 ? "год" : years > 1 && years < 5 ? "года" : "лет"
          }`;
        }
    
        if (months > 0) {
          if (experience) experience += " ";
          experience += `${months} ${
            months === 1 ? "месяц" : months > 1 && months < 5 ? "месяца" : "месяцев"
          }`;
        }
    
        if (years === 0 && months === 0 && days >= 0) {
          if (days === 0) {
            experience = "менее дня";
          } else {
            experience = `${days} ${
              days === 1 ? "день" : days > 1 && days < 5 ? "дня" : "дней"
            }`;
          }
        }
    
        return { formattedExperience: experience.trim(), totalMonths };
    };

    if (fired_employee_loading)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
          </div>
        </div>
    ;
        
    if (fired_employee_error) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
          <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
          <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
      </div>
    ;

    return (
        <div className="active_employee_efes">
            <div className="container_briefings_efes" style={{width: "1500px"}}>
                <DataGrid
                    style={{
                    marginTop: 20,
                    }}
                    rows={fired_employee}
                    columns={columnsFrd}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 12,
                        },
                    },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    pageSizeOptions={[12]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{
                    toolbar: GridToolbar,
                    }}
                    slotProps={{ toolbar: { showQuickFilter: true } }}
                />
            </div>

            {showModalRef && (
                <div className="modal_brief_employee_efes">
                    <div className="modal_cont_brief_employee_efes" style={{height: "160px"}}>
                        <h3 className='title_employee_efes'>Восстановление сотрудника</h3>
                        <label style={{display:"flex", flexDirection: "row"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

                        <div className="dat">
                            <label htmlFor="dateinput">Дата трудоустройства:</label>
                            <input
                                type="date"
                                id="dateinput"
                                name="dateinput"
                                value={dateemployment}
                                onChange={(e) => setDateEmployment(e.target.value)}
                                style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                            />
                        </div>

                        <div className="modal_act_fir">
                            <button onClick={confirmRefrFired}>Обновить</button>
                            <button onClick={() => setShowModalRef(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {notification.isVisible && (
                <Notification
                    status={notification.status}
                    title={notification.title}
                    subtitle={notification.subtitle}
                    onClose={hideNotification}
                    isVisible={notification.isVisible}
                    subtitle2={notification.subtitle2}
                />
            )}
        </div>
    );
}

export default TableFired;