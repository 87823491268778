import React, { useState } from 'react';
import { useGetEmployeesQuery } from '../../Efes/Global/EmployeeActiveSlice.ts';
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import '../Styles/Employee.scss';
import Notification from "../Components/Notification.tsx";
import Button from "@mui/material/Button";
import ModalAdd from "../Components/ModalAddActiveEmployee.tsx";
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import ModalEdit from  "../Components/ModalEditActiveEmployee.tsx";
import axiosInstance from '../axiosConfigEfes.js';

const Employee = () => {
  const { data: employees_active, error, isLoading, refetch } = useGetEmployeesQuery();
  const [isModalVisibleAdd, setIsModalVisibleAdd] = useState(false);
  const [isModalVisibleUpd, setIsModalVisibleUpd] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [selectedEmployeeFio, setSelectedEmployeeFio] = useState(null);
  const [showModalFired, setShowModalFired] = useState(false);
  const [datefired, setDateFired] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const [selectedDateemploy, setSelectedDateemploy] = useState("");
  const [reason, setReason] = useState("");
  const [notification, setNotification] = useState({
    status: "",
    title: "",
    subtitle: "",
    subtitle2: <div></div>,
    isVisible: false,
  });

  const hideNotification = () => {
    setNotification({
      ...notification,
      isVisible: false,
    });
  };

  // Подсчет стажа
  const calculateWorkExperience = (startDate) => {
    const now = new Date();
    const start = new Date(startDate);

    let years = now.getFullYear() - start.getFullYear();
    let months = now.getMonth() - start.getMonth();
    let days = now.getDate() - start.getDate();

    if (months < 0) {
      years--;
      months += 12;
    }

    if (days < 0) {
      months--;
      const lastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
      days += lastMonth.getDate();
    }

    const totalMonths = years * 12 + months;

    let experience = "";
    if (years > 0) {
      experience += `${years} ${
        years === 1 ? "год" : years > 1 && years < 5 ? "года" : "лет"
      }`;
    }

    if (months > 0) {
      if (experience) experience += " ";
      experience += `${months} ${
        months === 1 ? "месяц" : months > 1 && months < 5 ? "месяца" : "месяцев"
      }`;
    }

    if (years === 0 && months === 0 && days >= 0) {
      experience =
        days === 0
          ? "менее дня"
          : `${days} ${
              days === 1 ? "день" : days > 1 && days < 5 ? "дня" : "дней"
            }`;
    }

    return { formattedExperience: experience.trim(), totalMonths };
  };

  const columns: GridColDef[] = [
    {
      field: "fio",
      headerName: "ФИО",
      width: 250,
      type: "string",
      editable: false,
    },
    {
      field: "gender",
      headerName: "Пол",
      width: 40,
      type: "string",
      editable: false,
      valueGetter: (value, row) => {
        if (!row.gender) {
          return null;
        }
          return row.gender;
      },
    },
    {
      field: "years",
      headerName: "Возраст",
      width: 40,
      editable: false,
      valueGetter: (value, row) => {
        if (!row.datebirthday) {
          return null;
        }
        const [day, month, year] = row.datebirthday.split(".");
        const startDate = new Date(`${year}-${month}-${day}`);
        const now = new Date();
        let years = now.getFullYear() - startDate.getFullYear();
        return years;
      },
    },
    {
      field: "datebirthday",
      headerName: "Дата рождения",
      width: 120,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.datebirthday) {
          return null;
        }
        const [day, month, year] = row.datebirthday.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "phone",
      headerName: "Номер телефона",
      width: 150,
      editable: false,
    },
    // {
    //   field: "shift",
    //   headerName: "Смена",
    //   type: "string",
    //   width: 110,
    //   editable: false,
    //   valueGetter: (value, row) => {
    //     if (row.shift != null){
    //       return row.shift.nameshift;
    //     } else {
    //       return "Нет данных";
    //     }
    //   },
    // },
    {
      field: "post",
      headerName: "Должность",
      width: 200,
      editable: false,
      valueGetter: (value, row) => {
        if (row.post != null){
          return row.post.namepost;
        } else {
          return "Нет данных";
        }
      },
    },
    {
      field: "dateemployment",
      headerName: "Дата трудоуст.",
      width: 120,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.dateemployment) {
          return null;
        }
        const [day, month, year] = row.dateemployment.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "typeemployment",
      headerName: "Тип",
      width: 80,
      valueGetter: (value, row) => {
        if (row.typeemployment !== ""){
          return row.typeemployment;
        } else {
          return "Нет данных";
        }
      },
    },
    {
      field: "dateedst",
      headerName: "Стаж",
      width: 100,
      editable: false,
      valueGetter: (value, row) => {
        if (!row.dateemployment) {
          return null;
        }
        const [day, month, year] = row.dateemployment.split(".");
        const startDate = new Date(`${year}-${month}-${day}`);
        const { formattedExperience } = calculateWorkExperience(startDate);
        return formattedExperience;
      },
    },
    {
      field: "button",
      headerName: "Действия",
      width: 130,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleEdit(params.row.id)}>
            <EditIcon />
          </IconButton>
          {localStorage.getItem("role_efes") !== "Бригадир" && (
            <IconButton onClick={() => handleFired(params.row.id, params.row.fio, params.row.dateemployment)}>
              <DeleteIcon />
            </IconButton>
          )}
          {/* <IconButton
            onClick={() => handleAddVacation(params.row.id, params.row.fio)}
          >
            <BeachAccessIcon />
          </IconButton> */}
        </div>
      ),
    },
  ];

  const userRole = localStorage.getItem("role_efes");

  const filteredColumns: GridColDef[] = columns.filter((column) => {
    if (userRole === "Менеджер") {
      return true;
    }
    if (userRole === "Бригадир") {
      return column.field !== "typeemployment";
    }
  });

  // Функция для увольнения сотрудника
  const handleFired = (id, fio, dateempl) => {
    setShowModalFired(true);
    setSelectedEmployeeId(id);
    setSelectedEmployeeFio(fio);
    setSelectedDateemploy(dateempl);
  };

  const handleEdit = (id) => {
    setSelectedEmployeeId(id);
    setIsModalVisibleUpd(true);
  };

  const handleModalAddClose = () => {
    setIsModalVisibleAdd(false);
  };

  const handleAddSuccess = () => {
    refetch();
  };

  const handleModalClose = () => {
    setIsModalVisibleUpd(false);
  };

  const handleUpdateSuccess = () => {
    refetch();
  };

  const confirmFired = async () => {
    if (!selectedEmployeeId || !datefired || !reason) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Необходимо указать все данные",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }

    const parsedDateFired = new Date(
      /^\d{4}-\d{2}-\d{2}$/.test(datefired)
        ? datefired
        : datefired.split(".").reverse().join("-")
    );
    const parsedDateEmploy = new Date(
      /^\d{4}-\d{2}-\d{2}$/.test(selectedDateemploy)
        ? selectedDateemploy
        : selectedDateemploy.split(".").reverse().join("-")
    );
    
    // Сравниваем даты
    if (parsedDateFired < parsedDateEmploy) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Дата увольнения не может быть раньше даты трудоустройства",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }

    const formattedDateFired = new Date(datefired).toISOString();

    const empData = {
      comment: reason,
      datefired: formattedDateFired,
      fio_akk: localStorage.getItem("fio_efes"),
      role_akk: localStorage.getItem("role_efes"),
  };

    try {

      setNotification({
          status: "loading",
          title: "Обновление данных",
          subtitle: "Пожалуйста, подождите...",
          subtitle2: <div></div>,
          isVisible: true,
      });

      await axiosInstance.post(`/efes/add_fired_employee/${selectedEmployeeId}`, empData);
      
      setShowModalFired(false);
      refetch();

      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Сотрудник уволен",
        subtitle2: <div></div>,
        isVisible: true,
      });

    } catch (error) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Ошибка при увольнении сотрудника",
        subtitle2: <div></div>,
        isVisible: true,
      });
    }
  };


  if (isLoading)
    return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
      <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
        <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
        <CircularProgress />
      </div>
    </div>
  ;
    
  if (error) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
      <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
      <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
    </div>
  </div>
  ;

  return (
    <div className="active_employee_efes">
      <div className="container_active_employee_efes">
        <Button
          variant="contained"
          onClick={() => setIsModalVisibleAdd(true)}
          style={{ marginTop: "20px" }}
        >
          Добавить сотрудника
        </Button>
        <DataGrid
            style={{
              marginTop: 20,
            }}
            rows={employees_active ?? []}
            columns={filteredColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 12,
                },
              },
            }}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            pageSizeOptions={[12]}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{ toolbar: { showQuickFilter: true } }}
          />
      </div>
      {/* Модальное окно для добавления информации о сотруднике */}
      {isModalVisibleAdd && (
        <ModalAdd
          closeModalAdd={handleModalAddClose}
          onAddSuccess={handleAddSuccess}
        />
      )}
      {/* Модальное окно для редактирования информации о сотруднике */}
      {isModalVisibleUpd && (
        <ModalEdit
          selectedEmployeeId={selectedEmployeeId}
          closeModalUpd={handleModalClose}
          onUpdateSuccess={handleUpdateSuccess}
        />
      )}
      {/* Модальное окно для подтверждения увольнения сотрудника */}
      {showModalFired && (
        <div className="modal_fired_employee_efes">
          <div className="modal_cont_fired_employee_efes">
            <h3 className='title_employee_efes' style={{height: "50px"}}>Вы уверены, что хотите уволить этого сотрудника?</h3>
            <label style={{display:"flex", flexDirection: "row"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

            <div className="dat">
              <label htmlFor="datefired">Дата увольнения:</label>
              <input
                type="date"
                id="datefired"
                name="datefired"
                value={datefired}
                onChange={(e) => setDateFired(e.target.value)}
                style={{marginTop: "10px", marginBottom: "10px"}}
              />
            </div>

            <label htmlFor="reason">Причина увольнения:</label>
            <textarea
              name="reason"
              placeholder="Напишите причину увольнения"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              rows={4}
            />

            <div className="modal_act_fir">
              <button onClick={confirmFired}>Уволить</button>
              <button onClick={() => setShowModalFired(false)}>Отмена</button>
            </div>
          </div>
        </div>
      )}
      {notification.isVisible && (
        <Notification
          status={notification.status}
          title={notification.title}
          subtitle={notification.subtitle}
          onClose={hideNotification}
          isVisible={notification.isVisible}
          subtitle2={notification.subtitle2}
        />
      )}
    </div>
  );
};
  
export default Employee;