import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from "./marsBaseQuery.ts";
  
export interface OccupancyMars {
  employees: number;
  shift: string;
};

export interface OccupancyGenderMars {
  male: number;
  female: number;
  shift: string;
};

export interface OccupancyAgeMars {
  "18-29": number;
  "30-39": number;
  "40-49": number;
  "50-59": number;
  "60+": number;
  shift: string;
};

export interface OccupancyExperienceMars {
  "Меньше месяца": number;
  "От 1 до 12 месяцев": number;
  "От 6 до 12 месяцев": number;
  "От года до 2х лет": number;
  "От 2х до 3х лет": number;
  "Больше 3х лет": number;
  shift: string;
};

export interface OccupancyAreaMars {
  shift: string;
  "Вторичная упаковка": number;
  "Мониторинг": number;
  "ПМ-замеры": number;
  "Паучмейкер": number;
  "Переборка паллет с гп и упаковкой": number;
  "СГП": number;
  "Стерилизация": number;
  "ТТ9": number;
  "ТТ9-овощи": number;
  "ТТ9-подклад": number;
  "Холодный склад. Бункерная зона": number;
  "Честный знак": number;
};
  
export const occupancySliceMars = createApi({
    reducerPath: 'occupancyMars',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['OccupancyMars'],
    endpoints: (builder) => ({
      getOccupancyMars: builder.query<OccupancyMars[], void>({
        query: () => ({ url: 'employees/count_by_shift', method: 'GET' }),
        transformResponse: (response: OccupancyMars[]) =>
          response.map((occupancy) => ({
            ...occupancy,
          })),
        providesTags: ['OccupancyMars'],
      }),
      getOccupancyForemenMars: builder.query<OccupancyMars[], void>({
        query: () => ({ url: 'employees/count_by_shift_post/1', method: 'GET' }),
        transformResponse: (response: OccupancyMars[]) =>
          response.map((occupancy) => ({
            ...occupancy,
          })),
        providesTags: ['OccupancyMars'],
      }),
      getOccupancyKinMars: builder.query<OccupancyMars[], void>({
        query: () => ({ url: 'employees/count_by_shift_post/2', method: 'GET' }),
        transformResponse: (response: OccupancyMars[]) =>
          response.map((occupancy) => ({
            ...occupancy,
          })),
        providesTags: ['OccupancyMars'],
      }),
      getOccupancyTehMars: builder.query<OccupancyMars[], void>({
        query: () => ({ url: 'employees/count_by_shift_post/3', method: 'GET' }),
        transformResponse: (response: OccupancyMars[]) =>
          response.map((occupancy) => ({
            ...occupancy,
          })),
        providesTags: ['OccupancyMars'],
      }),
      getOccupancyOpMars: builder.query<OccupancyMars[], void>({
        query: () => ({ url: 'employees/count_by_shift_post/4', method: 'GET' }),
        transformResponse: (response: OccupancyMars[]) =>
          response.map((occupancy) => ({
            ...occupancy,
          })),
        providesTags: ['OccupancyMars'],
      }),
      getOccupancySpMars: builder.query<OccupancyMars[], void>({
        query: () => ({ url: 'employees/count_by_shift_post/5', method: 'GET' }),
        transformResponse: (response: OccupancyMars[]) =>
          response.map((occupancy) => ({
            ...occupancy,
          })),
        providesTags: ['OccupancyMars'],
      }),
      getOccupancyGenderMars: builder.query<OccupancyGenderMars[], void>({
        query: () => ({ url: 'employees/count_by_shift_gender', method: 'GET' }),
        transformResponse: (response: OccupancyGenderMars[]) =>
          response.map((occupancy) => ({
            ...occupancy,
          })),
        providesTags: ['OccupancyMars'],
      }),
      getOccupancyAgeMars: builder.query<OccupancyAgeMars[], void>({
        query: () => ({ url: 'employees/count_by_shift_age', method: 'GET' }),
        transformResponse: (response: OccupancyAgeMars[]) =>
          response.map((occupancy) => ({
            ...occupancy,
          })),
        providesTags: ['OccupancyMars'],
      }),
      getOccupancyExperienceMars: builder.query<OccupancyExperienceMars[], void>({
        query: () => ({ url: 'employees/count_by_shift_experience', method: 'GET' }),
        transformResponse: (response: OccupancyExperienceMars[]) =>
          response.map((occupancy) => ({
            ...occupancy,
          })),
        providesTags: ['OccupancyMars'],
      }),
      getOccupancyAreaMars: builder.query<OccupancyAreaMars[], void>({
        query: () => ({ url: 'employees/count_by_shift_area', method: 'GET' }),
        transformResponse: (response: OccupancyAreaMars[]) =>
          response.map((occupancy) => ({
            ...occupancy,
          })),
        providesTags: ['OccupancyMars'],
      }),
    }),
});
  
export const { 
  useGetOccupancyMarsQuery,
  useGetOccupancyForemenMarsQuery,
  useGetOccupancyKinMarsQuery,
  useGetOccupancyTehMarsQuery,
  useGetOccupancyOpMarsQuery,
  useGetOccupancySpMarsQuery,
  useGetOccupancyGenderMarsQuery,
  useGetOccupancyAgeMarsQuery,
  useGetOccupancyExperienceMarsQuery,
  useGetOccupancyAreaMarsQuery,
} = occupancySliceMars;