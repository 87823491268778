import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Notification from "../../Components/notification.tsx";
import { useGetEmplProjectCHZMarsQuery } from "../../Global/employeeSlice.ts";
import CircularProgress from '@mui/material/CircularProgress';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell, Label } from "recharts";
import axiosInstance from "../../../axiosConfig.js";
import { useGetEmployeesMarsQuery } from "../../Global/employeeSlice.ts";
import Select, { SingleValue } from "react-select";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface EmployeeOption {
    value: number;
    label: string;
}

const ProjectCHZ = () => {
    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });

    const hideNotification = () => {
        setNotification({
          ...notification,
          isVisible: false,
        });
    };

    const { data: emplProjectCHZ, error, isLoading, refetch } = useGetEmplProjectCHZMarsQuery();
    const { data: employeedata} = useGetEmployeesMarsQuery();
    const [numType, setNumType] = useState([]);

    const [selectedId, setSelectedId] = useState("");
    const [selectedFio, setSelectedFio] = useState("");

    const [typeCHZ, setTypeCHZ] = useState("");
    const [commentCHZ, setCommentCHZ] = useState("");
    const [friendaction, setFriendaction] = useState(false);
    const [showModalUpd, setShowModalUpd] = useState(false);

    const [selectedReq, setSelectedReq] = useState<SingleValue<EmployeeOption>>(null);
    const reqOptions: EmployeeOption[] = employeedata?.map((employee) => ({
        value: employee.id,
        label: employee.fio,
    })) || [];

    const fetchNumType = async () => {
        try {
          const response = await axiosInstance.get("employees/count_by_projectchz_type");
          return response.data;
        } catch (error) {
          console.error("Error:", error);
        }
    };

    const fetchData = async () => {
        try {
            const dataNumShift = await fetchNumType();

            if (dataNumShift != null){
                const formattedData = dataNumShift.map(item => {
                    const [type, employees] = Object.entries(item)[0];
                    return { type, employees };
                });
        
                setNumType(formattedData);
            }
        } catch (error) {
            console.error("Error fetching or formatting data:", error);
        }
    };
    
    
    useEffect(() => {
        fetchData();
    }, []);

    const columns: GridColDef[] = [
        {
            field: "fio",
            headerName: "ФИО",
            width: 250,
            valueGetter: (value, row) => {
                if (row.fio !== ""){
                    return row.fio;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "typechz",
            headerName: "Тип подбора",
            type: "string",
            width: 110,
            editable: false,
            valueGetter: (value, row) => {
              if (row.typechz != null){
                return row.typechz;
              }
            },
        },
        {
            field: "commchz",
            headerName: "Коммент",
            type: "string",
            width: 300,
            editable: false,
            valueGetter: (value, row) => {
              if (row.commchz != null){
                return row.commchz;
              }
            },
        },
        {
            field: "friend",
            headerName: '"Акция "приведи друга"',
            width: 80,
            valueGetter: (value, row) => {
                if (row.friendaction === true){
                    return "✅";
                } else {
                    return "❌";
                }
            },
        },
        {
            field: "req",
            headerName: "Кто привел",
            type: "string",
            width: 250,
            editable: false,
            valueGetter: (value, row) => {
              if (row.fioreqemployee != null){
                return row.fioreqemployee;
              }
            },
        },
        {
            field: "dateemployment",
            headerName: "Дата трудоуст.",
            width: 120,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
              if (!row.dateemployment) {
                return null;
              }
              const [day, month, year] = row.dateemployment.split(".");
              return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "button",
            headerName: "",
            width: 80,
            renderCell: (params) => (
              <div>
                <IconButton onClick={() => handleEdit(params.row.id, params.row.fio)}>
                  <EditIcon />
                </IconButton>
              </div>
            ),
        },
    ];

    const handleEdit = async (id, fio) => {
        setNotification({
            status: "loading",
            title: "Получение данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
        });

        await axiosInstance
          .get(`/employees/${id}`)
          .then((response) => {
            const employee = response.data;
            setTypeCHZ(employee.typechz);
            setCommentCHZ(employee.commchz);
            setFriendaction(employee.friendaction);
            const reqEmpl: EmployeeOption = {
                value: employee.reqemployee,
                label: employee.fioreqemployee,
            };
            setSelectedReq(reqEmpl);
          })
          .catch((error) => {
            console.error(
              "Ошибка при получении данных о сотруднике для редактирования:",
              error
            );
        })

        hideNotification();

        setSelectedId(id);
        setSelectedFio(fio);
        setShowModalUpd(true);
    }

    const confirmUpd = async () => {
        try {
            const updatedEmpData = {
                typechz: typeCHZ,
                commchz: commentCHZ,
                friendaction: friendaction,
                reqemployee: selectedReq?.value,
            };

            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
            await updateEmpl(updatedEmpData);

            const refetchPromise = refetch();
            await refetchPromise;

            setShowModalUpd(false);
    
            setNotification({
                status: "success",
                title: "Успешно",
                subtitle: "Информация о сотруднике обновлена",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
          
        } catch (error) {
            setNotification({
                status: "error",
                title: "Ошибка",
                subtitle: "Произошла ошибка",
                subtitle2: <div></div>,
                isVisible: true,
            });
        }
    };

    const updateEmpl = async (updatedEmpData) => {
        try {
          const response = await axiosInstance.put(
            `/employees/${selectedId}`,
            updatedEmpData
          );
          return response.data;
        } catch (error) {
          throw error;
        }
    };

    if (isLoading)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
            </div>
        </div>
    ;
        
    if (error) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
            <img src={require("../../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
        </div>
    ;

    const colors = [
        "#d60000",
        "#ff8c00",
        "#f9d400",
    ];

    const isSaveDisabled = !(
        typeCHZ &&
        commentCHZ &&
        (!friendaction || selectedReq)
    );

    return (
        <div className="active_employee_efes">
            <div className="container_briefings_efes" style={{width: "770px", display: "flex", flexDirection: "column", alignItems: "center"}}>
            {numType !== null && (
                <BarChart
                    width={500}
                    height={300}
                    style={{marginTop: "20px"}}
                    data={numType}
                    margin={{ top: 20, right: 30, bottom: 20, left: 20 }}
                >
                    {/* Ось X с подписью */}
                    <XAxis dataKey="type">
                        <Label value="Тип подбора" offset={-5} position="insideBottom" />
                    </XAxis>

                    {/* Ось Y с подписью */}
                    <YAxis>
                        <Label angle={-90} position="insideLeft" />
                    </YAxis>

                    <Tooltip />

                    {/* Отображение количества сотрудников */}
                    {numType && (
                        <Bar dataKey="employees" fill="#8884d8" label={{ position: "top" }}>
                            {numType.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Bar>
                    )}
                </BarChart>
            )}

                <DataGrid
                    style={{
                    marginTop: 20,
                    }}
                    rows={emplProjectCHZ ?? []}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 12,
                        },
                    },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    pageSizeOptions={[12]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{
                    toolbar: GridToolbar,
                    }}
                    slotProps={{ toolbar: { showQuickFilter: true } }}
                />
            </div>

            {/* Модальное окно для редактирования */}
            {showModalUpd && (
                <div className="modal_brief_employee_efes">
                    <div className="modal_cont_brief_employee_efes" style={{height:"fit-content", width: "400px"}}>
                        <h3 className='title_employee_efes'>Редактирование</h3>
                        <label style={{display:"flex", flexDirection: "row", marginBottom: "15px", width: "100%"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedFio}</div></label>

                        <div>
                            <label
                                htmlFor="typeCHZ-dropdown"
                            >
                                Тип подбора:
                            </label>
                            <select
                                id="typeCHZ-dropdown"
                                value={typeCHZ}
                                style={{ width: "270px", marginBottom: "10px"}}
                                onChange={(e) => setTypeCHZ(e.target.value)}
                            >
                                <option value="" disabled>
                                    Выберите тип подбора
                                </option>
                                <option value="Локальный" key="1" >
                                    Локальный
                                </option>
                                <option value="Вахта РФ" key="2">
                                    Вахта РФ
                                </option>
                                <option value="Вахта СНГ" key="3">
                                    Вахта СНГ
                                </option>
                            </select>
                        </div>
  
                        <label htmlFor="comment">Комментарий к проекту ЧЗ</label>
                        <textarea id="comment" name="comment" rows={5} placeholder="Введите комментарий здесь..." value={commentCHZ} onChange={(e) => setCommentCHZ(e.target.value)} style={{resize: "none", width: "375px", marginBottom: "10px", marginTop: "10px", fontFamily: "Montserrat, sans-serif", fontWeight: "500", padding: "10px"}}></textarea>

                        <FormControlLabel control={
                            <Checkbox
                            checked={friendaction}
                            onChange={(e) => setFriendaction(e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label='Акция "приведи друга"' />

                        {friendaction === true && (
                            <div style={{width: "100%"}}>
                                <Select
                                id="mentor-dropdown"
                                value={selectedReq}
                                options={reqOptions}
                                onChange={(reqOptions) => setSelectedReq(reqOptions)}
                                placeholder="Кто привел"
                                />
                            </div>
                        )}

                        <div className="modal_act_fir">
                            <button onClick={confirmUpd} disabled={isSaveDisabled}>Редактировать</button>
                            <button onClick={() => setShowModalUpd(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}


            {notification.isVisible && (
                <Notification
                status={notification.status}
                title={notification.title}
                subtitle={notification.subtitle}
                onClose={hideNotification}
                isVisible={notification.isVisible}
                subtitle2={notification.subtitle2}
                />
            )}
        </div>
    );
}

export default ProjectCHZ;