import React, { useState, useEffect, useCallback } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import { useCountUp } from "use-count-up";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import axiosInstance from "../axiosConfigEfes.js";
import Notification from "../Components/Notification.tsx";
import { useGetExpiredOnJobQuery,
    useGetExpiringSoonOnJobQuery,
    useGetExpiredFireQuery,
    useGetExpiringSoonFireQuery,
    useGetExpiredElectroQuery,
    useGetExpiringSoonElectroQuery,
} from "../Global/BriefingsEfesSlice.ts";
import { useGetEmployeesQuery } from "../Global/EmployeeActiveSlice.ts";

const ControlBrief = () => {
    const { data: data_onjob_pass, error: error_onjob_pass, isLoading: loading_onjob_pass, refetch: refetch_onjob_pass } = useGetExpiredOnJobQuery();
    const { data: data_onjob_soon, error: error_onjob_soon, isLoading: loading_onjob_soon, refetch: refetch_onjob_soon } = useGetExpiringSoonOnJobQuery();

    const { data: data_fire_pass, error: error_fire_pass, isLoading: loading_fire_pass, refetch: refetch_fire_pass } = useGetExpiredFireQuery();
    const { data: data_fire_soon, error: error_fire_soon, isLoading: loading_fire_soon, refetch: refetch_fire_soon } = useGetExpiringSoonFireQuery();

    const { data: data_electro_pass, error: error_electro_pass, isLoading: loading_electro_pass, refetch: refetch_electro_pass } = useGetExpiredElectroQuery();
    const { data: data_electro_soon, error: error_electro_soon, isLoading: loading_electro_soon, refetch: refetch_electro_soon } = useGetExpiringSoonElectroQuery();

    const { data: employees_active, error: errorEmpl, isLoading: isLoadingEmpl } = useGetEmployeesQuery();

    const [showModalOnJob, setShowModalOnJob] = useState(false);
    const [showModalFire, setShowModalFire] = useState(false);
    const [showModalElectro, setShowModalElectro] = useState(false);

    const [visibleOnJob, setVisibleOnJob] = useState(true);
    const [visibleFire, setVisibleFire] = useState(false);
    const [visibleElectro, setVisibleElectro] = useState(false);
  
    const [colorBtnTop, setColorBtnTop] = useState("btn1");
  
    const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
    const [selectedEmployeeFio, setSelectedEmployeeFio] = useState("");
  
    const [selectedDateOnJob, setSelectedDateOnJob] = useState("");
    const [selectedDateFire, setSelectedDateFire] = useState("");
    const [selectedDateElectro, setSelectedDateElectro] = useState("");
    const [selectedElectro, setSelectedElectro] = useState("");

    const [notification, setNotification] = useState({
      status: "",
      title: "",
      subtitle: "",
      subtitle2: <div></div>,
      isVisible: false,
    });
  
    const hideNotification = () => {
      setNotification({
        ...notification,
        isVisible: false,
      });
    };
  
    const { value: valueSoonOnjob } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_onjob_soon?.count,
    });
  
    const { value: valuePassOnjob } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_onjob_pass?.count,
    });
  
    const { value: valueSoonFire } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_fire_soon?.count,
    });
  
    const { value: valuePassFire } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_fire_pass?.count,
    });
  
    const { value: valueSoonElectro } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_electro_soon?.count,
    });
  
    const { value: valuePassElectro } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_electro_pass?.count,
    });

    const all_empl = employees_active?.length || 0;

    const value_soon_onjob_proc = Math.round(all_empl > 0 ? (Number(valueSoonOnjob) ?? 0) / all_empl * 100 : 0);
    const value_pass_onjob_proc = Math.round(all_empl > 0 ? (Number(valuePassOnjob) ?? 0) / all_empl * 100 : 0);

    const value_soon_fire_proc = Math.round(all_empl > 0 ? (Number(valueSoonFire) ?? 0) / all_empl * 100 : 0);
    const value_pass_fire_proc = Math.round(all_empl > 0 ? (Number(valuePassFire) ?? 0) / all_empl * 100 : 0);

    const value_soon_electro_proc = Math.round(all_empl > 0 ? (Number(valueSoonElectro) ?? 0) / all_empl * 100 : 0);
    const value_pass_electro_proc = Math.round(all_empl > 0 ? (Number(valuePassElectro) ?? 0) / all_empl * 100 : 0);

    const formatDate = (dateStr, type) => {
      if (!dateStr) return "";
      const isValidISOFormat = /^\d{4}-\d{2}-\d{2}$/.test(dateStr);
      if (isValidISOFormat) return dateStr;
      const dateParts = dateStr.split(".");
      if (dateParts.length !== 3) return "";
    
      const [day, month, year] = dateParts;
      const parsedDate = new Date(`${year}-${month}-${day}`);
      if (isNaN(parsedDate)) return ""; 
    
      const formattedDay = String(parsedDate.getDate()).padStart(2, "0");
      const formattedMonth = String(parsedDate.getMonth() + 1).padStart(2, "0");
      const formattedYear = parsedDate.getFullYear();
    
      if (type === "onjob" || type === "fire") {
        parsedDate.setMonth(parsedDate.getMonth() + 6);
      } else if (type === "electro") {
        parsedDate.setFullYear(parsedDate.getFullYear() + 1); 
      } else {
        return ""; 
      }
    
      const newDay = String(parsedDate.getDate()).padStart(2, "0");
      const newMonth = String(parsedDate.getMonth() + 1).padStart(2, "0");
      const newYear = parsedDate.getFullYear();
    
      return `${newYear}-${newMonth}-${newDay}`;
    };
    
  
    const columnsOnjob: GridColDef[] = [
      // { field: "id", headerName: "ID", width: 40 },
      {
        field: "fio",
        headerName: "ФИО",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          if (row.employee) {
            return row.employee.fio;
          }
        },
      },
      {
        field: "datelmk",
        headerName: "Дата прохождения",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.dateonjob) {
            return null;
          }
          const [day, month, year] = row.dateonjob.split(".");
          return new Date(`${year}-${month}-${day}`);
        },
      },
      {
        field: "datelmk2",
        headerName: "Дата срока окончания",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.dateonjob) {
            return null;
          }
          const [day, month, year] = row.dateonjob.split(".");
          const startDate = new Date(`${year}-${month}-${day}`);
          const endDate = new Date(startDate);
          endDate.setMonth(endDate.getMonth() + 6);
          return endDate;
        },
      },
      {
        field: "button",
        headerName: "",
        width: 40,
        renderCell: (params) => (
          <div>
            <IconButton
              onClick={() => {setShowModalOnJob(true); setSelectedEmployeeId(params.row.employee.id); setSelectedEmployeeFio(params.row.employee.fio); setSelectedDateOnJob(formatDate(params.row.dateonjob, "onjob")) }}
            >
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];

    const handleEdit = async (type) => {

      if (type === "onjob"){
        const formattedDate1 = new Date(selectedDateOnJob).toISOString();

        const empData = {
          id: selectedEmployeeId,
          dateonjob: formattedDate1,
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
        };

        try{
          setNotification({
            status: "loading",
            title: "Обновление данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
          });

          await axiosInstance.put(`/efes/edit_brief`, empData);

          setShowModalOnJob(false);
          refetch_onjob_pass();
          refetch_onjob_soon();

          setNotification({
            status: "success",
            title: "Успешно",
            subtitle: "Запись отредактирована",
            subtitle2: <div></div>,
            isVisible: true,
          });

        } catch (error) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при обновлении",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
      }

      if (type === "fire"){
        const formattedDate2 = new Date(selectedDateFire).toISOString();
        const empData = {
          id: selectedEmployeeId,
          datefire: formattedDate2,
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
        };

        try{
          setNotification({
            status: "loading",
            title: "Обновление данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
          });

          await axiosInstance.put(`/efes/edit_brief`, empData);

          setShowModalFire(false);
          refetch_fire_pass();
          refetch_fire_soon();

          setNotification({
            status: "success",
            title: "Успешно",
            subtitle: "Запись отредактирована",
            subtitle2: <div></div>,
            isVisible: true,
          });

        } catch (error) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при обновлении",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
      }

      if (type === "electro"){
        const formattedDate3 = new Date(selectedDateElectro).toISOString();
        const empData = {
          id: selectedEmployeeId,
          dateelectro: formattedDate3,
          numelectro: selectedElectro,
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
        };

        try{
          setNotification({
            status: "loading",
            title: "Обновление данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
          });

          await axiosInstance.put(`/efes/edit_brief`, empData);

          setShowModalElectro(false);
          refetch_electro_pass();
          refetch_electro_soon();

          setNotification({
            status: "success",
            title: "Успешно",
            subtitle: "Запись отредактирована",
            subtitle2: <div></div>,
            isVisible: true,
          });

        } catch (error) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при обновлении",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
      }
    };
  
    const columnsFire: GridColDef[] = [
      // { field: "id", headerName: "ID", width: 40 },
      {
        field: "fio",
        headerName: "ФИО",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          if (row.employee) {
            return row.employee.fio;
          }
        },
      },
      {
        field: "dateonjob",
        headerName: "Дата прохождения",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.datefire) {
            return null;
          }
          const [day, month, year] = row.datefire.split(".");
          return new Date(`${year}-${month}-${day}`);
        },
      },
      {
        field: "dateonjob2",
        headerName: "Дата срока окончания",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.datefire) {
            return null;
          }
          const [day, month, year] = row.datefire.split(".");
          const startDate = new Date(`${year}-${month}-${day}`);
          const endDate = new Date(startDate);
          endDate.setMonth(endDate.getMonth() + 6);
          return endDate;
        },
      },
      {
        field: "button",
        headerName: "",
        width: 40,
        renderCell: (params) => (
          <div>
            <IconButton
              onClick={() => {setShowModalFire(true); setSelectedEmployeeId(params.row.employee.id); setSelectedEmployeeFio(params.row.employee.fio); setSelectedDateFire(formatDate(params.row.datefire, "fire")) }}
            >
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];
  
    const columnsElectro: GridColDef[] = [
      // { field: "id", headerName: "ID", width: 40 },
      {
        field: "fio",
        headerName: "ФИО",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          if (row.employee) {
            return row.employee.fio;
          }
        },
      },
      {
        field: "numelectro",
        headerName: "Группа ЭБ",
        width: 90,
        valueGetter: (value, row) => {
            if (row.numelectro !== ""){
                return row.numelectro;
            } else {
                return "Нет данных";
            }
        },
      },
      {
        field: "dateonjob",
        headerName: "Дата прохождения",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.dateelectro) {
            return null;
          }
          const [day, month, year] = row.dateelectro.split(".");
          return new Date(`${year}-${month}-${day}`);
        },
      },
      {
        field: "dateonjob2",
        headerName: "Дата срока окончания",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.dateelectro) {
            return null;
          }
          const [day, month, year] = row.dateelectro.split(".");
          const startDate = new Date(`${year}-${month}-${day}`);
          const endDate = new Date(startDate);
          endDate.setFullYear(endDate.getFullYear() + 1);
          return endDate;
        },
      },
      {
        field: "button",
        headerName: "",
        width: 40,
        renderCell: (params) => (
          <div>
            <IconButton
              onClick={() => {setShowModalElectro(true); setSelectedEmployeeId(params.row.employee.id); setSelectedEmployeeFio(params.row.employee.fio); setSelectedDateElectro(formatDate(params.row.dateelectro, "electro")); setSelectedElectro(params.row.numelectro) }}
            >
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];
  

    if (loading_onjob_pass || loading_onjob_soon || loading_fire_pass || loading_fire_soon || loading_electro_pass || loading_electro_soon || isLoadingEmpl)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
            </div>
        </div>
    ;
        
    if (error_onjob_pass || error_onjob_soon || error_fire_pass || error_fire_soon || error_electro_pass || error_electro_soon || errorEmpl) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
            <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
        </div>
    ;


    return (
      <div className="briefings_container">
        <div className="btn_brief_change" style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            onClick={() => {
              setVisibleOnJob(true);
              setVisibleFire(false);
              setVisibleElectro(false);
              setColorBtnTop("btn1");
            }}
            style={{
              backgroundColor: colorBtnTop === "btn1" ? "#D51B6C" : "",
            }}
          >
            На рабочем месте
          </Button>
          <Button
            variant="contained"
            onClick={() => {
                setVisibleOnJob(false);
                setVisibleFire(true);
                setVisibleElectro(false);
                setColorBtnTop("btn2");
            }}
            style={{
              backgroundColor: colorBtnTop === "btn2" ? "#D51B6C" : "",
            }}
          >
            Пожаробезопасность
          </Button>
          <Button
            variant="contained"
            onClick={() => {
                setVisibleOnJob(false);
                setVisibleFire(false);
                setVisibleElectro(true);
                setColorBtnTop("btn3");
            }}
            style={{
              backgroundColor: colorBtnTop === "btn3" ? "#D51B6C" : "",
            }}
          >
            Электробезопасность
          </Button>
        </div>
        <div className="tables_brief">
          {visibleOnJob && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="navbar_control">
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">В течении месяца</div>
                    <div className="num_cntrl">{data_onjob_soon?.count}</div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_soon_onjob_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ffaa00",
                    }}
                  >
                    <Typography>{value_soon_onjob_proc}%</Typography>
                  </CircularProgress>
                </div>
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">Просрочено</div>
                    <div className="num_cntrl" style={{ color: "#ad1100" }}>
                      {data_onjob_pass?.count}
                    </div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_pass_onjob_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ad1100",
                    }}
                  >
                    <Typography>{value_pass_onjob_proc}%</Typography>
                  </CircularProgress>
                </div>
              </div>
              <div style={{ display: "flex", maxHeight: "700px" }}>
                <DataGrid
                  rows={data_onjob_soon?.records ?? []}
                  columns={columnsOnjob}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                  style={{ marginRight: "50px" }}
                />
                <DataGrid
                  rows={data_onjob_pass?.records ?? []}
                  columns={columnsOnjob}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                />
              </div>
            </div>
          )}
          {visibleFire && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="navbar_control">
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">В течении месяца</div>
                    <div className="num_cntrl">{data_fire_soon?.count}</div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_soon_fire_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ffaa00",
                    }}
                  >
                    <Typography>{value_soon_fire_proc}%</Typography>
                  </CircularProgress>
                </div>
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">Просрочен</div>
                    <div className="num_cntrl" style={{ color: "#ad1100" }}>
                      {data_fire_pass?.count}
                    </div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_pass_fire_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ad1100",
                    }}
                  >
                    <Typography>{value_pass_fire_proc}%</Typography>
                  </CircularProgress>
                </div>
              </div>
              <div style={{ display: "flex", maxHeight: "700px" }}>
                <DataGrid
                  rows={data_fire_soon?.records ?? []}
                  columns={columnsFire}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                  style={{ marginRight: "50px" }}
                />
                <DataGrid
                  rows={data_fire_pass?.records ?? []}
                  columns={columnsFire}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                />
              </div>
            </div>
          )}
          {visibleElectro && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="navbar_control">
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">В течении месяца</div>
                    <div className="num_cntrl">{data_electro_soon?.count}</div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_soon_electro_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ffaa00",
                    }}
                  >
                    <Typography>{value_soon_electro_proc}%</Typography>
                  </CircularProgress>
                </div>
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">Просрочен</div>
                    <div className="num_cntrl" style={{ color: "#ad1100" }}>
                      {data_electro_pass?.count}
                    </div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_pass_electro_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ad1100",
                    }}
                  >
                    <Typography>{value_pass_electro_proc}%</Typography>
                  </CircularProgress>
                </div>
              </div>
              <div style={{ display: "flex", maxHeight: "700px" }}>
                <DataGrid
                  rows={data_electro_soon?.records ?? []}
                  columns={columnsElectro}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                  style={{ marginRight: "50px" }}
                />
                <DataGrid
                  rows={data_electro_pass?.records ?? []}
                  columns={columnsElectro}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                />
              </div>
            </div>
          )}
        </div>

        {/* Модальное окно для редактирования раб мест*/}
        {showModalOnJob && (
          <div className="modal_brief_employee_efes">
              <div className="modal_cont_brief_employee_efes" style={{height: "170px", width: "450px"}}>
                  <h3 className='title_employee_efes'>Редактирование инструктажа</h3>

                  <label style={{display:"flex", flexDirection: "row"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

                  <div className="dat">
                      <label htmlFor="datelmk">Дата прохождения на раб. месте:</label>
                      <input
                          type="date"
                          id="datelmk"
                          name="datelmk"
                          value={selectedDateOnJob}
                          onChange={(e) => setSelectedDateOnJob(e.target.value)}
                          style={{marginTop: "15px", marginBottom: "10px", width: "140px"}}
                      />
                  </div>

                  <div className="modal_act_fir">
                      <button onClick={() => handleEdit("onjob")}>Обновить</button>
                      <button onClick={() => setShowModalOnJob(false)}>Отмена</button>
                  </div>
              </div>
          </div>
        )}

        {/* Модальное окно для редактирования Fire */}
        {showModalFire && (
          <div className="modal_brief_employee_efes">
              <div className="modal_cont_brief_employee_efes" style={{height: "170px", width: "450px"}}>
                  <h3 className='title_employee_efes'>Редактирование инструктажа</h3>

                  <label style={{display:"flex", flexDirection: "row"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

                  <div className="dat">
                      <label htmlFor="dateflg">Дата прохождения пожаробез.:</label>
                      <input
                          type="date"
                          id="dateflg"
                          name="dateflg"
                          value={selectedDateFire}
                          onChange={(e) => setSelectedDateFire(e.target.value)}
                          style={{marginTop: "15px", marginBottom: "10px", width: "140px"}}
                      />
                  </div>

                  <div className="modal_act_fir">
                      <button onClick={() => handleEdit("fire")}>Обновить</button>
                      <button onClick={() => setShowModalFire(false)}>Отмена</button>
                  </div>
              </div>
          </div>
        )}

        {/* Модальное окно для редактирования АДСМ */}
        {showModalElectro && (
          <div className="modal_brief_employee_efes">
              <div className="modal_cont_brief_employee_efes" style={{height: "230px", width: "450px"}}>
                    <h3 className='title_employee_efes'>Редактирование инструктажа</h3>

                    <label style={{display:"flex", flexDirection: "row"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

                    <div className="dat">
                        <label htmlFor="dateadsm">Дата прохождения электробез.:</label>
                        <input
                            type="date"
                            id="dateadsm"
                            name="dateadsm"
                            value={selectedDateElectro}
                            onChange={(e) => setSelectedDateElectro(e.target.value)}
                            style={{marginTop: "15px", marginBottom: "10px", width: "140px"}}
                        />
                    </div>

                    <label
                        htmlFor="electro-dropdown"
                    >
                        Группа электробезопасности:
                    </label>
                    <select
                        id="electro-dropdown"
                        value={selectedElectro}
                        style={{ width: "100%", marginBottom: "10px", marginTop: "10px", height: "22px"}}
                        onChange={(e) => setSelectedElectro(e.target.value)}
                    >
                        <option value="" disabled>
                        Выберите группу электробезопасности
                        </option>
                        <option value="1" key="1" >
                        Первая
                        </option>
                        <option value="2" key="2">
                        Вторая
                        </option>
                        <option value="3" key="3">
                        Третья
                        </option>
                        <option value="4" key="4">
                        Четвертая
                        </option>
                    </select>

                    <div className="modal_act_fir">
                        <button onClick={() => handleEdit("electro")}>Обновить</button>
                        <button onClick={() => setShowModalElectro(false)}>Отмена</button>
                    </div>
              </div>
          </div>
        )}

        {notification.isVisible && (
          <Notification
            status={notification.status}
            title={notification.title}
            subtitle={notification.subtitle}
            subtitle2={notification.subtitle2}
            onClose={hideNotification}
            isVisible={notification.isVisible}
          />
        )}
      </div>
    );
}
   
export default ControlBrief;