import React, { useState, useEffect, useCallback } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import { useCountUp } from "use-count-up";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import axiosInstance from "../axiosConfigEfes.js";
import Notification from "../Components/Notification.tsx";
import { useGetExpiredLMKQuery,
    useGetExpiringSoonLMKQuery,
    useGetExpiredADSMQuery,
    useGetExpiringSoonADSMQuery,
    useGetExpiredFLGQuery,
    useGetExpiringSoonFLGQuery,
    useGetExpiredSESQuery,
    useGetExpiringSoonSESQuery,
} from "../Global/LMKEmployeeEfes.ts";
import { useGetEmployeesQuery } from "../Global/EmployeeActiveSlice.ts";

const ControlLMK = () => {
    const { data: data_lmk_pass, error: error_lmk_pass, isLoading: loading_lmk_pass, refetch: refetch_lmk_pass } = useGetExpiredLMKQuery();
    const { data: data_lmk_soon, error: error_lmk_soon, isLoading: loading_lmk_soon, refetch: refetch_lmk_soon } = useGetExpiringSoonLMKQuery();

    const { data: data_flg_pass, error: error_flg_pass, isLoading: loading_flg_pass, refetch: refetch_flg_pass } = useGetExpiredFLGQuery();
    const { data: data_flg_soon, error: error_flg_soon, isLoading: loading_flg_soon, refetch: refetch_flg_soon } = useGetExpiringSoonFLGQuery();

    const { data: data_adsm_pass, error: error_adsm_pass, isLoading: loading_adsm_pass, refetch: refetch_adsm_pass } = useGetExpiredADSMQuery();
    const { data: data_adsm_soon, error: error_adsm_soon, isLoading: loading_adsm_soon, refetch: refetch_adsm_soon } = useGetExpiringSoonADSMQuery();

    const { data: data_ses_pass, error: error_ses_pass, isLoading: loading_ses_pass, refetch: refetch_ses_pass } = useGetExpiredSESQuery();
    const { data: data_ses_soon, error: error_ses_soon, isLoading: loading_ses_soon, refetch: refetch_ses_soon } = useGetExpiringSoonSESQuery();

    const { data: employees_active, error: errorEmpl, isLoading: isLoadingEmpl } = useGetEmployeesQuery();

    const [showModalLMK, setShowModalLMK] = useState(false);
    const [showModalFLG, setShowModalFLG] = useState(false);
    const [showModalADSM, setShowModalADSM] = useState(false);
    const [showModalSES, setShowModalSES] = useState(false);

    const [visibleLmk, setVisibleLmk] = useState(true);
    const [visibleFlg, setVisibleFlg] = useState(false);
    const [visibleAdsm, setVisibleAdsm] = useState(false);
    const [visibleSes, setVisibleSes] = useState(false);
  
    const [colorBtnTop, setColorBtnTop] = useState("btn1");
  
    const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
    const [selectedEmployeeFio, setSelectedEmployeeFio] = useState("");
  
    const [selectedDatelmk, setSelectedDatelmk] = useState("");
    const [selectedDateflg, setSelectedDateflg] = useState("");
    const [selectedDateadsm, setSelectedDateadsm] = useState("");
    const [selectedDateses, setSelectedDateses] = useState("");
  
    const [notification, setNotification] = useState({
      status: "",
      title: "",
      subtitle: "",
      subtitle2: <div></div>,
      isVisible: false,
    });
  
    const hideNotification = () => {
      setNotification({
        ...notification,
        isVisible: false,
      });
    };
  
    const { value: valueSoonLmk } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_lmk_soon?.count,
    });
  
    const { value: valuePassLmk } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_lmk_pass?.count,
    });
  
    const { value: valueSoonFlg } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_flg_soon?.count,
    });
  
    const { value: valuePassFlg } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_flg_pass?.count,
    });
  
    const { value: valueSoonAdsm } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_adsm_soon?.count,
    });
  
    const { value: valuePassAdsm } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_adsm_pass?.count,
    });

    const { value: valueSoonSes } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_ses_soon?.count,
    });
  
    const { value: valuePassSes } = useCountUp({
      isCounting: true,
      duration: 1,
      start: 0,
      end: data_ses_pass?.count,
    });

    const all_empl = employees_active?.length || 0;

    const value_soon_lmk_proc = Math.round(all_empl > 0 ? (Number(valueSoonLmk) ?? 0) / all_empl * 100 : 0);
    const value_pass_lmk_proc = Math.round(all_empl > 0 ? (Number(valuePassLmk) ?? 0) / all_empl * 100 : 0);

    const value_soon_flg_proc = Math.round(all_empl > 0 ? (Number(valueSoonFlg) ?? 0) / all_empl * 100 : 0);
    const value_pass_flg_proc = Math.round(all_empl > 0 ? (Number(valuePassFlg) ?? 0) / all_empl * 100 : 0);

    const value_soon_adsm_proc = Math.round(all_empl > 0 ? (Number(valueSoonAdsm) ?? 0) / all_empl * 100 : 0);
    const value_pass_adsm_proc = Math.round(all_empl > 0 ? (Number(valuePassAdsm) ?? 0) / all_empl * 100 : 0);

    const value_soon_ses_proc = Math.round(all_empl > 0 ? (Number(valueSoonSes) ?? 0) / all_empl * 100 : 0);
    const value_pass_ses_proc = Math.round(all_empl > 0 ? (Number(valuePassSes) ?? 0) / all_empl * 100 : 0);

    const formatDate = (dateStr, type) => {
      if (!dateStr) return ""; // Return empty string for undefined/null
      const isValidISOFormat = /^\d{4}-\d{2}-\d{2}$/.test(dateStr); // Check if it's already in ISO format
      if (isValidISOFormat) return dateStr; // If already in the correct format, return it directly
    
      const dateParts = dateStr.split(".");
      if (dateParts.length !== 3) return ""; // Ensure correct format for DD.MM.YYYY
    
      const [day, month, year] = dateParts;
      const parsedDate = new Date(`${year}-${month}-${day}`);
    
      const formattedDay = String(parsedDate.getDate()).padStart(2, "0");
      const formattedMonth = String(parsedDate.getMonth() + 1).padStart(2, "0");

      if (type === "lmk"){
        const formattedYear = parsedDate.getFullYear() + 1;
        return `${formattedYear}-${formattedMonth}-${formattedDay}`;
      }
      
      if (type === "flg"){
        const formattedYear = parsedDate.getFullYear() + 1;
        return `${formattedYear}-${formattedMonth}-${formattedDay}`;
      }

      if (type === "adsm"){
        const formattedYear = parsedDate.getFullYear() + 10;
        return `${formattedYear}-${formattedMonth}-${formattedDay}`;
      }

      if (type === "ses"){
        const formattedYear = parsedDate.getFullYear() + 2;
        return `${formattedYear}-${formattedMonth}-${formattedDay}`;
      }

    };
  
    const columnsLMK: GridColDef[] = [
      // { field: "id", headerName: "ID", width: 40 },
      {
        field: "fio",
        headerName: "ФИО",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          if (row.employee) {
            return row.employee.fio;
          }
        },
      },
      {
        field: "datelmk",
        headerName: "Дата прохождения",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.datelmk) {
            return null;
          }
          const [day, month, year] = row.datelmk.split(".");
          return new Date(`${year}-${month}-${day}`);
        },
      },
      {
        field: "datelmk2",
        headerName: "Дата срока окончания",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.datelmk) {
            return null;
          }
          const [day, month, year] = row.datelmk.split(".");
          const startDate = new Date(`${year}-${month}-${day}`);
          const endDate = new Date(startDate);
          endDate.setFullYear(endDate.getFullYear() + 1);
          return endDate;
        },
      },
      {
        field: "button",
        headerName: "",
        width: 40,
        renderCell: (params) => (
          <div>
            <IconButton
              onClick={() => {setShowModalLMK(true); setSelectedEmployeeId(params.row.id); setSelectedEmployeeFio(params.row.employee.fio); setSelectedDatelmk(formatDate(params.row.datelmk, "lmk")) }}
            >
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];

    const handleEditLMK = async (type) => {

      if (type === "lmk"){
        const formattedDate1 = new Date(selectedDatelmk).toISOString();

        const empData = {
          id: selectedEmployeeId,
          datelmk: formattedDate1,
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
        };

        try{
          setNotification({
            status: "loading",
            title: "Обновление данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
          });

          await axiosInstance.put(`/efes/lmk/edit_lmk`, empData);

          setShowModalLMK(false);
          refetch_lmk_pass();
          refetch_lmk_soon();

          setNotification({
            status: "success",
            title: "Успешно",
            subtitle: "Запись отредактирована",
            subtitle2: <div></div>,
            isVisible: true,
          });

        } catch (error) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при обновлении",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
      }

      if (type === "flg"){
        const formattedDate2 = new Date(selectedDateflg).toISOString();
        const empData = {
          id: selectedEmployeeId,
          dateflg: formattedDate2,
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
        };

        try{
          setNotification({
            status: "loading",
            title: "Обновление данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
          });

          await axiosInstance.put(`/efes/lmk/edit_lmk`, empData);

          setShowModalFLG(false);
          refetch_flg_pass();
          refetch_flg_soon();

          setNotification({
            status: "success",
            title: "Успешно",
            subtitle: "Запись отредактирована",
            subtitle2: <div></div>,
            isVisible: true,
          });

        } catch (error) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при обновлении",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
      }

      if (type === "adsm"){
        const formattedDate3 = new Date(selectedDateadsm).toISOString();
        const empData = {
          id: selectedEmployeeId,
          dateadsm: formattedDate3,
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
        };

        try{
          setNotification({
            status: "loading",
            title: "Обновление данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
          });

          await axiosInstance.put(`/efes/lmk/edit_lmk`, empData);

          setShowModalADSM(false);
          refetch_adsm_pass();
          refetch_adsm_soon();

          setNotification({
            status: "success",
            title: "Успешно",
            subtitle: "Запись отредактирована",
            subtitle2: <div></div>,
            isVisible: true,
          });

        } catch (error) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при обновлении",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
      }

      if (type === "ses"){
        const formattedDate4 = new Date(selectedDateses).toISOString();
        const empData = {
          id: selectedEmployeeId,
          dateses: formattedDate4,
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
        };

        try{
          setNotification({
            status: "loading",
            title: "Обновление данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
          });

          await axiosInstance.put(`/efes/lmk/edit_lmk`, empData);

          setShowModalSES(false);
          refetch_ses_pass();
          refetch_ses_soon();

          setNotification({
            status: "success",
            title: "Успешно",
            subtitle: "Запись отредактирована",
            subtitle2: <div></div>,
            isVisible: true,
          });

        } catch (error) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при обновлении",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
      }
    };
  
    const columnsFLG: GridColDef[] = [
      // { field: "id", headerName: "ID", width: 40 },
      {
        field: "fio",
        headerName: "ФИО",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          if (row.employee) {
            return row.employee.fio;
          }
        },
      },
      {
        field: "dateonjob",
        headerName: "Дата прохождения",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.dateflg) {
            return null;
          }
          const [day, month, year] = row.dateflg.split(".");
          return new Date(`${year}-${month}-${day}`);
        },
      },
      {
        field: "dateonjob2",
        headerName: "Дата срока окончания",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.dateflg) {
            return null;
          }
          const [day, month, year] = row.dateflg.split(".");
          const startDate = new Date(`${year}-${month}-${day}`);
          const endDate = new Date(startDate);
          endDate.setFullYear(endDate.getFullYear() + 1);
          return endDate;
        },
      },
      {
        field: "button",
        headerName: "",
        width: 40,
        renderCell: (params) => (
          <div>
            <IconButton
              onClick={() => {setShowModalFLG(true); setSelectedEmployeeId(params.row.id); setSelectedEmployeeFio(params.row.employee.fio); setSelectedDateflg(formatDate(params.row.dateflg, "flg")) }}
            >
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];
  
    const columnsADSM: GridColDef[] = [
      // { field: "id", headerName: "ID", width: 40 },
      {
        field: "fio",
        headerName: "ФИО",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          if (row.employee) {
            return row.employee.fio;
          }
        },
      },
      {
        field: "dateonjob",
        headerName: "Дата прохождения",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.dateadsm) {
            return null;
          }
          const [day, month, year] = row.dateadsm.split(".");
          return new Date(`${year}-${month}-${day}`);
        },
      },
      {
        field: "dateonjob2",
        headerName: "Дата срока окончания",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.dateadsm) {
            return null;
          }
          const [day, month, year] = row.dateadsm.split(".");
          const startDate = new Date(`${year}-${month}-${day}`);
          const endDate = new Date(startDate);
          endDate.setFullYear(endDate.getFullYear() + 10);
          return endDate;
        },
      },
      {
        field: "button",
        headerName: "",
        width: 40,
        renderCell: (params) => (
          <div>
            <IconButton
              onClick={() => {setShowModalADSM(true); setSelectedEmployeeId(params.row.id); setSelectedEmployeeFio(params.row.employee.fio); setSelectedDateadsm(formatDate(params.row.dateadsm, "adsm")) }}
            >
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];

    const columnsSES: GridColDef[] = [
      // { field: "id", headerName: "ID", width: 40 },
      {
        field: "fio",
        headerName: "ФИО",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          if (row.employee) {
            return row.employee.fio;
          }
        },
      },
      {
        field: "dateonjob",
        headerName: "Дата прохождения",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.dateses) {
            return null;
          }
          const [day, month, year] = row.dateses.split(".");
          return new Date(`${year}-${month}-${day}`);
        },
      },
      {
        field: "dateonjob2",
        headerName: "Дата срока окончания",
        width: 200,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.dateses) {
            return null;
          }
          const [day, month, year] = row.dateses.split(".");
          const startDate = new Date(`${year}-${month}-${day}`);
          const endDate = new Date(startDate);
          endDate.setFullYear(endDate.getFullYear() + 2);
          return endDate;
        },
      },
      {
        field: "button",
        headerName: "",
        width: 40,
        renderCell: (params) => (
          <div>
            <IconButton
              onClick={() => {setShowModalSES(true); setSelectedEmployeeId(params.row.id); setSelectedEmployeeFio(params.row.employee.fio); setSelectedDateses(formatDate(params.row.dateses, "ses")) }}
            >
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];
  

    if (loading_lmk_pass || loading_lmk_soon || loading_flg_pass || loading_flg_soon || loading_adsm_pass || loading_adsm_soon || isLoadingEmpl || loading_ses_pass || loading_ses_soon)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
            </div>
        </div>
    ;
        
    if (error_lmk_pass || error_lmk_soon || error_flg_pass || error_flg_soon || error_adsm_pass || error_adsm_soon || errorEmpl || error_ses_pass || error_ses_soon) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
            <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
        </div>
    ;


    return (
      <div className="briefings_container">
        <div className="btn_brief_change" style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            onClick={() => {
              setVisibleLmk(true);
              setVisibleFlg(false);
              setVisibleAdsm(false);
              setVisibleSes(false);
              setColorBtnTop("btn1");
            }}
            style={{
              backgroundColor: colorBtnTop === "btn1" ? "#D51B6C" : "",
            }}
          >
            ЛМК
          </Button>
          <Button
            variant="contained"
            onClick={() => {
                setVisibleLmk(false);
                setVisibleFlg(true);
                setVisibleAdsm(false);
                setVisibleSes(false);
                setColorBtnTop("btn2");
            }}
            style={{
              backgroundColor: colorBtnTop === "btn2" ? "#D51B6C" : "",
            }}
          >
            ФЛГ
          </Button>
          <Button
            variant="contained"
            onClick={() => {
                setVisibleLmk(false);
                setVisibleFlg(false);
                setVisibleAdsm(true);
                setVisibleSes(false);
                setColorBtnTop("btn3");
            }}
            style={{
              backgroundColor: colorBtnTop === "btn3" ? "#D51B6C" : "",
            }}
          >
            АДСМ
          </Button>
          <Button
            variant="contained"
            onClick={() => {
                setVisibleLmk(false);
                setVisibleFlg(false);
                setVisibleAdsm(false);
                setVisibleSes(true);
                setColorBtnTop("btn4");
            }}
            style={{
              backgroundColor: colorBtnTop === "btn4" ? "#D51B6C" : "",
            }}
          >
            СЭС
          </Button>
        </div>
        <div className="tables_brief">
          {visibleLmk && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="navbar_control">
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">В течении месяца</div>
                    <div className="num_cntrl">{data_lmk_soon?.count}</div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_soon_lmk_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ffaa00",
                    }}
                  >
                    <Typography>{value_soon_lmk_proc}%</Typography>
                  </CircularProgress>
                </div>
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">Просрочено</div>
                    <div className="num_cntrl" style={{ color: "#ad1100" }}>
                      {data_lmk_pass?.count}
                    </div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_pass_lmk_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ad1100",
                    }}
                  >
                    <Typography>{value_pass_lmk_proc}%</Typography>
                  </CircularProgress>
                </div>
              </div>
              <div style={{ display: "flex", maxHeight: "700px" }}>
                <DataGrid
                  rows={data_lmk_soon?.records ?? []}
                  columns={columnsLMK}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                  style={{ marginRight: "50px" }}
                />
                <DataGrid
                  rows={data_lmk_pass?.records ?? []}
                  columns={columnsLMK}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                />
              </div>
            </div>
          )}
          {visibleFlg && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="navbar_control">
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">В течении месяца</div>
                    <div className="num_cntrl">{data_flg_soon?.count}</div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_soon_flg_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ffaa00",
                    }}
                  >
                    <Typography>{value_soon_flg_proc}%</Typography>
                  </CircularProgress>
                </div>
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">Просрочен</div>
                    <div className="num_cntrl" style={{ color: "#ad1100" }}>
                      {data_flg_pass?.count}
                    </div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_pass_flg_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ad1100",
                    }}
                  >
                    <Typography>{value_pass_flg_proc}%</Typography>
                  </CircularProgress>
                </div>
              </div>
              <div style={{ display: "flex", maxHeight: "700px" }}>
                <DataGrid
                  rows={data_flg_soon?.records ?? []}
                  columns={columnsFLG}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                  style={{ marginRight: "50px" }}
                />
                <DataGrid
                  rows={data_flg_pass?.records ?? []}
                  columns={columnsFLG}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                />
              </div>
            </div>
          )}
          {visibleAdsm && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="navbar_control">
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">В течении месяца</div>
                    <div className="num_cntrl">{data_adsm_soon?.count}</div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_soon_adsm_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ffaa00",
                    }}
                  >
                    <Typography>{value_soon_adsm_proc}%</Typography>
                  </CircularProgress>
                </div>
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">Просрочен</div>
                    <div className="num_cntrl" style={{ color: "#ad1100" }}>
                      {data_adsm_pass?.count}
                    </div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_pass_adsm_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ad1100",
                    }}
                  >
                    <Typography>{value_pass_adsm_proc}%</Typography>
                  </CircularProgress>
                </div>
              </div>
              <div style={{ display: "flex", maxHeight: "700px" }}>
                <DataGrid
                  rows={data_adsm_soon?.records ?? []}
                  columns={columnsADSM}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                  style={{ marginRight: "50px" }}
                />
                <DataGrid
                  rows={data_adsm_pass?.records ?? []}
                  columns={columnsADSM}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                />
              </div>
            </div>
          )}

          {visibleSes && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="navbar_control">
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">В течении месяца</div>
                    <div className="num_cntrl">{data_ses_soon?.count}</div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_soon_ses_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ffaa00",
                    }}
                  >
                    <Typography>{value_soon_ses_proc}%</Typography>
                  </CircularProgress>
                </div>
                <div className="cntrl">
                  <div className="data_cntrl">
                    <div className="title_cntrl">Просрочено</div>
                    <div className="num_cntrl" style={{ color: "#ad1100" }}>
                      {data_ses_pass?.count}
                    </div>
                  </div>
                  <CircularProgress
                    determinate
                    value={value_pass_ses_proc as number}
                    sx={{
                      "--CircularProgress-size": "78px",
                      "--CircularProgress-progressColor": "#ad1100",
                    }}
                  >
                    <Typography>{value_pass_ses_proc}%</Typography>
                  </CircularProgress>
                </div>
              </div>
              <div style={{ display: "flex", maxHeight: "700px" }}>
                <DataGrid
                  rows={data_ses_soon?.records ?? []}
                  columns={columnsSES}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                  style={{ marginRight: "50px" }}
                />
                <DataGrid
                  rows={data_ses_pass?.records ?? []}
                  columns={columnsSES}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                />
              </div>
            </div>
          )}

        </div>

        {/* Модальное окно для редактирования ЛМК*/}
        {showModalLMK && (
          <div className="modal_brief_employee_efes">
              <div className="modal_cont_brief_employee_efes" style={{height: "170px"}}>
                  <h3 className='title_employee_efes'>Редактирование ЛМК</h3>

                  <label style={{display:"flex", flexDirection: "row"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

                  <div className="dat">
                      <label htmlFor="datelmk">Дата прохождения ЛМК:</label>
                      <input
                          type="date"
                          id="datelmk"
                          name="datelmk"
                          value={selectedDatelmk}
                          onChange={(e) => setSelectedDatelmk(e.target.value)}
                          style={{marginTop: "15px", marginBottom: "10px", width: "170px"}}
                      />
                  </div>

                  <div className="modal_act_fir">
                      <button onClick={() => handleEditLMK("lmk")}>Добавить</button>
                      <button onClick={() => setShowModalLMK(false)}>Отмена</button>
                  </div>
              </div>
          </div>
        )}

        {/* Модальное окно для редактирования ФЛГ*/}
        {showModalFLG && (
          <div className="modal_brief_employee_efes">
              <div className="modal_cont_brief_employee_efes" style={{height: "170px"}}>
                  <h3 className='title_employee_efes'>Редактирование ФЛГ</h3>

                  <label style={{display:"flex", flexDirection: "row"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

                  <div className="dat">
                      <label htmlFor="dateflg">Дата прохождения ФЛГ:</label>
                      <input
                          type="date"
                          id="dateflg"
                          name="dateflg"
                          value={selectedDateflg}
                          onChange={(e) => setSelectedDateflg(e.target.value)}
                          style={{marginTop: "15px", marginBottom: "10px", width: "170px"}}
                      />
                  </div>

                  <div className="modal_act_fir">
                      <button onClick={() => handleEditLMK("flg")}>Добавить</button>
                      <button onClick={() => setShowModalFLG(false)}>Отмена</button>
                  </div>
              </div>
          </div>
        )}

        {/* Модальное окно для редактирования АДСМ */}
        {showModalADSM && (
          <div className="modal_brief_employee_efes">
              <div className="modal_cont_brief_employee_efes" style={{height: "170px"}}>
                  <h3 className='title_employee_efes'>Редактирование АДСМ</h3>

                  <label style={{display:"flex", flexDirection: "row"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

                  <div className="dat">
                      <label htmlFor="dateadsm">Дата прохождения АДСМ:</label>
                      <input
                          type="date"
                          id="dateadsm"
                          name="dateadsm"
                          value={selectedDateadsm}
                          onChange={(e) => setSelectedDateadsm(e.target.value)}
                          style={{marginTop: "15px", marginBottom: "10px", width: "150px"}}
                      />
                  </div>

                  <div className="modal_act_fir">
                      <button onClick={() => handleEditLMK("adsm")}>Добавить</button>
                      <button onClick={() => setShowModalADSM(false)}>Отмена</button>
                  </div>
              </div>
          </div>
        )}

        {/* Модальное окно для редактирования СЭС */}
        {showModalSES && (
          <div className="modal_brief_employee_efes">
              <div className="modal_cont_brief_employee_efes" style={{height: "170px"}}>
                  <h3 className='title_employee_efes'>Редактирование СЭС</h3>

                  <label style={{display:"flex", flexDirection: "row"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

                  <div className="dat">
                      <label htmlFor="dateses">Дата прохождения СЭС:</label>
                      <input
                          type="date"
                          id="dateses"
                          name="dateses"
                          value={selectedDateses}
                          onChange={(e) => setSelectedDateses(e.target.value)}
                          style={{marginTop: "15px", marginBottom: "10px", width: "150px"}}
                      />
                  </div>

                  <div className="modal_act_fir">
                      <button onClick={() => handleEditLMK("ses")}>Добавить</button>
                      <button onClick={() => setShowModalSES(false)}>Отмена</button>
                  </div>
              </div>
          </div>
        )}

        {notification.isVisible && (
          <Notification
            status={notification.status}
            title={notification.title}
            subtitle={notification.subtitle}
            subtitle2={notification.subtitle2}
            onClose={hideNotification}
            isVisible={notification.isVisible}
          />
        )}
      </div>
    );
  }
   
  export default ControlLMK;