import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';

export const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export interface DriverEfes {
    employee: {
        id: number;
        post: {
            id: number;
            namepost: string;
        };
        fio: string;
        dateemployment: string | null;
    };
    dateinput: string | null;
    datepsih: string | null;
    number: string;
}

export interface ExpiryResult<T> {
  count: number;
  records: T[];
}

export const driverEfesSlice = createApi({
  reducerPath: 'driverEfes',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['DriverEfes'],
  endpoints: (builder) => ({
    getDriverEfes: builder.query<DriverEfes[], void>({
      query: () => ({ url: '/efes/all_drivers', method: 'GET' }),
      transformResponse: (response: DriverEfes[]) =>
        response.map((driver) => ({
          ...driver,
          dateinput: formatDate(driver.dateinput),
          datepsih: formatDate(driver.datepsih),
          employee: {
            ...driver.employee,
            dateemployment: formatDate(driver.employee.dateemployment),
          },
        })),
      providesTags: ['DriverEfes'],
    }),
    getExpiredInput: builder.query<ExpiryResult<DriverEfes>, void>({
      query: () => ({ url: '/efes/input/expired', method: 'GET' }),
      transformResponse: (response: ExpiryResult<DriverEfes>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          dateinput: formatDate(record.dateinput),
        })),
      }),
      providesTags: ['DriverEfes'],
    }),
    getExpiringSoonInput: builder.query<ExpiryResult<DriverEfes>, void>({
      query: () => ({ url: '/efes/input/expiring-soon', method: 'GET' }),
      transformResponse: (response: ExpiryResult<DriverEfes>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          dateinput: formatDate(record.dateinput),
        })),
      }),
      providesTags: ['DriverEfes'],
    }),
    getExpiredPsih: builder.query<ExpiryResult<DriverEfes>, void>({
      query: () => ({ url: '/efes/psih/expired', method: 'GET' }),
      transformResponse: (response: ExpiryResult<DriverEfes>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          datepsih: formatDate(record.datepsih),
        })),
      }),
      providesTags: ['DriverEfes'],
    }),
    getExpiringSoonPsih: builder.query<ExpiryResult<DriverEfes>, void>({
      query: () => ({ url: '/efes/psih/expiring-soon', method: 'GET' }),
      transformResponse: (response: ExpiryResult<DriverEfes>) => ({
        count: response.count,
        records: response.records.map((record) => ({
          ...record,
          datepsih: formatDate(record.datepsih),
        })),
      }),
      providesTags: ['DriverEfes'],
    }),
    getDriverAllExpired: builder.query<Number, void>({
      query: () => ({ url: '/efes/driver/all_count_expired', method: 'GET' }),
      providesTags: ['DriverEfes'],
    }),
    getDriverAllExpiringSoon: builder.query<Number, void>({
      query: () => ({ url: '/efes/driver/all_count_expiring_soon', method: 'GET' }),
      providesTags: ['DriverEfes'],
    }),
  }),
});

export const { 
  useGetDriverEfesQuery,
  useGetExpiredInputQuery,
  useGetExpiringSoonInputQuery,
  useGetExpiredPsihQuery,
  useGetExpiringSoonPsihQuery,
  useGetDriverAllExpiredQuery,
  useGetDriverAllExpiringSoonQuery
 } = driverEfesSlice;