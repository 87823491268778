import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Employee } from "../models";
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from "./marsBaseQuery.ts";

export const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

interface EmployeeState {
  employee: Employee | null;
}

const initialState: EmployeeState = {
  employee: null,
};

export interface Employee_active {
  id: number;
  fio: string;
  gender: string;
  datebirthday: string | null;
  phone: string;
  shift: Shift | null;
  mainPost: Post | null;
  dateemployment: string | null;
  datelmk: string | null;
  dateflg: string | null;
  dateadsm: string | null;
  datesiz: string | null;
  dateinst: string | null;
  status: boolean | null;
  datechange: string | null;
  typechz: string;
  commchz: string;
}

interface Shift {
  id: number;
  nameshift: string;
}

interface Post {
  id: number;
  namepost: string;
}

interface Area {
  id: number;
  namearea: string;
}

interface Employee_projectCHZ{
  id: number;
  fio: string;
  dateemployment: string | null;
  typechz: string;
  commchz: string;
  friendaction: boolean;
}

export interface Employee_employed{
  id: number;
  gender: string;
  age: number;
  shift: Shift | null;
  mainpost: Post | null;
  mainarea: Area | null;
  dateemployment: string | null;
  datefired: string | null;
  datefiredlast: string | null;
  typeemployment: string;
}

interface Employee_age_gender{
  id: number;
  gender: string;
  age: number;
  fio: string;
  shift: string;
}

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    setEmployee(state, action: PayloadAction<Employee>) {
      state.employee = action.payload;
    },
    clearEmployee(state) {
      state.employee = null;
    },
  },
});

export const employeeActiveMarsSlice = createApi({
  reducerPath: 'employeeActiveMars',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ActiveEmployeesMars'],
  endpoints: (builder) => ({
    getEmployeesMars: builder.query<Employee_active[], void>({
      query: () => ({ url: '/employees', method: 'GET' }),
      transformResponse: (response: Employee_active[]) =>
        response.map((employee) => ({
          ...employee,
          dateemployment: formatDate(employee.dateemployment),
          datebirthday: formatDate(employee.datebirthday),
          datelmk: formatDate(employee.datelmk),
          dateflg: formatDate(employee.dateflg),
          dateadsm: formatDate(employee.dateadsm),
          datesiz: formatDate(employee.datesiz),
          dateinst: formatDate(employee.dateinst),
          datechange: formatDate(employee.datechange),
        })),
      providesTags: ['ActiveEmployeesMars'],
    }),
    getEmplProjectCHZMars: builder.query<Employee_projectCHZ[], void>({
      query: () => ({ url: '/employees/all_employee_projectchz', method: 'GET' }),
      transformResponse: (response: Employee_projectCHZ[]) =>
        response.map((employee) => ({
          ...employee,
          dateemployment: formatDate(employee.dateemployment),
        })),
      providesTags: ['ActiveEmployeesMars'],
    }),
    getEmplFriendMars: builder.query<Employee_projectCHZ[], void>({
      query: () => ({ url: '/employees/all_employee_friend', method: 'GET' }),
      transformResponse: (response: Employee_projectCHZ[]) =>
        response.map((employee) => ({
          ...employee,
          dateemployment: formatDate(employee.dateemployment),
        })),
      providesTags: ['ActiveEmployeesMars'],
    }),
    getEmploymentEmplMars: builder.query<Employee_employed[], void>({
      query: () => ({ url: '/employees/all_employment', method: 'GET' }),
      transformResponse: (response: Employee_employed[]) =>
        response.map((employee) => ({
          ...employee,
          dateemployment: formatDate(employee.dateemployment),
        })),
      providesTags: ['ActiveEmployeesMars'],
    }),
    getFiredEmplMars: builder.query<Employee_employed[], void>({
      query: () => ({ url: '/employees/all_fired', method: 'GET' }),
      transformResponse: (response: Employee_employed[]) =>
        response.map((employee) => ({
          ...employee,
          dateemployment: formatDate(employee.dateemployment),
          datefired: formatDate(employee.datefired),
          datefiredlast: formatDate(employee.datefiredlast),
        })),
      providesTags: ['ActiveEmployeesMars'],
    }),
    getGenderAgeEmplMars: builder.query<Employee_age_gender[], void>({
      query: () => ({ url: '/employees/all_employee_gender_age', method: 'GET' }),
      transformResponse: (response: Employee_age_gender[]) =>
        response.map((employee) => ({
          ...employee,
        })),
      providesTags: ['ActiveEmployeesMars'],
    }),
  }),
});

export const { 
  useGetEmployeesMarsQuery,
  useGetEmplProjectCHZMarsQuery,
  useGetEmplFriendMarsQuery,
  useGetEmploymentEmplMarsQuery,
  useGetFiredEmplMarsQuery,
  useGetGenderAgeEmplMarsQuery,
} = employeeActiveMarsSlice;

export const { setEmployee, clearEmployee } = employeeSlice.actions;
export const employeeReducer = employeeSlice.reducer;