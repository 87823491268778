/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../Styles/navbar.scss";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import SchoolIcon from "@mui/icons-material/School";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import LogoutIcon from "@mui/icons-material/Logout";
import HistoryIcon from "@mui/icons-material/History";
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const Navbar = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token"));

  const handleLogout = async () => {
    const confirmLogout = window.confirm("Точно хотите выйти?");
    if (!confirmLogout) return; 

    try {
      localStorage.removeItem("token");
      navigate("/marspets/login");
    } catch (error) {
      console.log("Не удалось выйти");
    }
  };

  const INACTIVITY_LIMIT = 9 * 60 * 60 * 1000;

  const timer = setTimeout(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      clearTimeout(timer);
      console.log("сеанс окончен");
      navigate("/marspets/login");
  }, INACTIVITY_LIMIT);
  
  

  return (
    <div className="navbar_container">
      <div style={{ width: "78px" }}></div>
      <div className="sidebar close">
        <ul className="nav-links">
          <li>
            <a href="/marspets/">
              <i>
                <HomeIcon sx={{ fontSize: 25, color: "black" }} />
              </i>
            </a>
          </li>
          <li>
            <div className="iocn-link">
              <a href="#">
                <i>
                  <BackupTableIcon sx={{ fontSize: 25, color: "black" }} />
                </i>
              </a>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Таблицы
                </a>
              </li>
              <li>
                <a href="/marspets/employee">Сотрудники</a>
              </li>
              <li>
                <a href="/marspets/siz">СИЗ</a>
              </li>
              <li>
                <a href="/marspets/lmk">ЛМК</a>
              </li>
              <li>
                <a href="/marspets/area_table">Участки</a>
              </li>
              <li>
                <a href="/marspets/brief">Инструктажи</a>
              </li>
              <li>
                <a href="/marspets/fired">Уволенные</a>
              </li>
            </ul>
          </li>
          <li>
            <div className="iocn-link">
              <a href="/marspets/area">
                <i>
                  <SchoolIcon sx={{ fontSize: 25, color: "black" }} />
                </i>
              </a>
            </div>
          </li>
          <li>
            <a href="#">
              <i>
                <CalendarMonthIcon sx={{ fontSize: 25, color: "black" }} />
              </i>
            </a>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Контроль сроков
                </a>
              </li>
              <li style={{ display: "flex", alignItems: "center" }}>
                <a href="/marspets/control">ЛМК</a>
                <div className="notif_red"></div>
              </li>
              <li>
                <a href="/marspets/control_flg">ФЛГ</a>
              </li>
              <li>
                <a href="/marspets/control_adsm">АДСМ</a>
              </li>
              <li>
                <a href="/marspets/control_siz">СИЗ</a>
              </li>
              <li>
                <a href="/marspets/control_briefings">Инструктажи</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#">
              <i>
                <BeachAccessIcon sx={{ fontSize: 25, color: "black" }} />
              </i>
            </a>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Отпуска
                </a>
              </li>
              <li>
                <a href="/marspets/vacations">Отпуска по месяцам</a>
              </li>
              <li>
                <a href="/marspets/vacations_year">Отпуска за год</a>
              </li>
            </ul>
          </li>
          {/* <li>
            <div className="iocn-link">
              <a href="/marspets/print">
                <i>
                  <LocalPrintshopIcon sx={{ fontSize: 25, color: "black" }} />
                </i>
              </a>
            </div>
          </li> */}
          <li>
            <a href="#">
              <i>
                <QueryStatsIcon sx={{ fontSize: 25, color: "black" }} />
              </i>
            </a>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Статистика
                </a>
              </li>
              <li>
                <a href="/marspets/stats/project_chz">Проект ЧЗ</a>
              </li>
              <li>
                <a href="/marspets/stats/friend">Акция "приведи друга"</a>
              </li>
              <li>
                <a href="/marspets/stats/occupancy">Наполняемость смен</a>
              </li>
              <li>
                <a href="/marspets/employed">Трудоустроенные</a>
              </li>
              <li>
                <a href="/marspets/stats/gender_age">Пол по возрасту</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#">
              <i>
                <HistoryIcon sx={{ fontSize: 25, color: "black" }} />
              </i>
            </a>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  История
                </a>
              </li>
              <li>
                <a href="/marspets/history_ports">Должности</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" onClick={handleLogout}>
              <i>
                <LogoutIcon sx={{ fontSize: 25, color: "black" }} />
              </i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
