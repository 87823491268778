
import React, { useState } from "react";
import Notification from "../../Components/notification.tsx";
import CircularProgress from '@mui/material/CircularProgress';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, Cell } from "recharts";
import { 
    useGetOccupancyMarsQuery,
    useGetOccupancyForemenMarsQuery,
    useGetOccupancyOpMarsQuery,
    useGetOccupancyGenderMarsQuery,
    useGetOccupancyAgeMarsQuery,
    useGetOccupancyExperienceMarsQuery,
    useGetOccupancyAreaMarsQuery,
 } from '../../Global/occupancySliceMars.ts';


const TableOccupancy = () => {
    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });

    const hideNotification = () => {
        setNotification({
          ...notification,
          isVisible: false,
        });
    };

    const { data: all_occupancy, error, isLoading } = useGetOccupancyMarsQuery();
    const { data: occupancy_op, error: error_occupancy_op, isLoading: loading_occupancy_op } = useGetOccupancyOpMarsQuery();
    const { data: occupancy_gender, error: error_occupancy_gender, isLoading: loading_occupancy_gender } = useGetOccupancyGenderMarsQuery();
    const { data: occupancy_age, error: error_occupancy_age, isLoading: loading_occupancy_age } = useGetOccupancyAgeMarsQuery();
    const { data: occupancy_experience, error: error_occupancy_experience, isLoading: loading_occupancy_experience } = useGetOccupancyExperienceMarsQuery();
    const { data: occupancy_area, error: error_occupancy_area, isLoading: loading_occupancy_area } = useGetOccupancyAreaMarsQuery();

    const colors = [
        "#d60000",
        "#ff8c00",
        "#f9d400",
        "#00840d",
        "#320e54",
        "#000000",
        "#e2e8f1",
        "#e2e8f1",
        "#e2e8f1",
        "#e2e8f1",
        "#e2e8f1",
    ];

    const colorsMale = "#8884d8";
    const colorsFemale = "#f387ff";

    const colors18_29 = "#b5ea8c";
    const colors30_39 = "#94bf73";
    const colors40_49 = "#739559";
    const colors50_59 = "#526a40";
    const colors60Plus = "#314026";

    const colorsExp1 = "#6c58f1";
    const colorsExp2 = "#5948c6";
    const colorsExp3 = "#46399c";
    const colorsExp4 = "#332971";
    const colorsExp5 = "#201a47";
    const colorsExp6 = "#0d0a1c";

    const colorsArea1 = "#ff0000";
    const colorsArea2 = "#ff8000";
    const colorsArea3 = "#ffff00";
    const colorsArea4 = "#80ff00";
    const colorsArea5 = "#00ff00";
    const colorsArea6 = "#00ff80";
    const colorsArea7 = "#00ffff";
    const colorsArea8 = "#0080ff";
    const colorsArea9 = "#0000ff";
    const colorsArea10 = "#8000ff";
    const colorsArea11 = "#ff00ff";
    const colorsArea12 = "#ff0080";

    if (isLoading || loading_occupancy_op || loading_occupancy_gender || loading_occupancy_age || loading_occupancy_experience || loading_occupancy_area)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
            </div>
        </div>
    ;
        
    if (error || error_occupancy_op || error_occupancy_gender || error_occupancy_age || error_occupancy_experience || error_occupancy_area) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
            <img src={require("../../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
        </div>
    ;

    return (
        <div style={{height: "fit-content", backgroundColor: "#eff2f7", paddingBottom: "150px"}}>
            <div className="homepage_dashboard_container" style={{width: "100%", justifyContent: "space-evenly", height: "fit-content"}}>
                <div className="block_employee_barchat_shift" style={{width: "fit-content"}}>
                    <div>Наполняемость смен</div>
                    <BarChart
                        width={800}
                        height={300}
                        data={all_occupancy}
                        margin={{ top: 20, right: 30, bottom: 5, left: 20 }}
                    >
                        <XAxis dataKey="shift" />
                        <YAxis />
                        <Tooltip />
                        {all_occupancy && (
                        <Bar
                            dataKey="employees"
                            fill="#8884d8"
                            label={{ position: "top" }}
                        >
                            {all_occupancy.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                            ))}
                        </Bar>
                        )}
                    </BarChart>
                </div>

                <div className="block_employee_barchat_shift" style={{width: "fit-content"}}>
                    <div>Наполняемость смен (оператор производственного процесса)</div>
                    <BarChart
                        width={800}
                        height={300}
                        data={occupancy_op}
                        margin={{ top: 20, right: 30, bottom: 5, left: 20 }}
                    >
                        <XAxis dataKey="shift" />
                        <YAxis />
                        <Tooltip />
                        {occupancy_op && (
                        <Bar
                            dataKey="employees"
                            fill="#8884d8"
                            label={{ position: "top" }}
                        >
                            {occupancy_op.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                            ))}
                        </Bar>
                        )}
                    </BarChart>
                </div>

                {/* <div className="block_employee_barchat_shift" style={{width: "fit-content"}}>
                    <div>Наполняемость смен (бригадиры)</div>
                    <BarChart
                        width={500}
                        height={300}
                        data={occupancy_foremen}
                        margin={{ top: 20, right: 30, bottom: 5, left: 20 }}
                    >
                        <XAxis dataKey="shift" />
                        <YAxis />
                        <Tooltip />
                        {occupancy_foremen && (
                        <Bar
                            dataKey="employees"
                            fill="#8884d8"
                            label={{ position: "top" }}
                        >
                            {occupancy_foremen.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                            ))}
                        </Bar>
                        )}
                    </BarChart>
                </div> */}

                <div className="block_employee_barchat_shift" style={{width: "fit-content"}}>
                    <div>Наполняемость смен по полу</div>
                    <BarChart
                        width={800}
                        height={300}
                        data={occupancy_gender}
                        margin={{ top: 20, right: 30, bottom: 5, left: 20 }}
                    >
                        <XAxis dataKey="shift" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="male" fill={colorsMale} name="Мужчины" label={{ position: "top" }}>
                            {occupancy_gender?.map((entry, index) => (
                                <Cell key={`male-cell-${index}`} fill={colorsMale} />
                            ))}
                        </Bar>
                        <Bar dataKey="female" fill={colorsFemale} name="Женщины" label={{ position: "top" }}>
                            {occupancy_gender?.map((entry, index) => (
                                <Cell key={`female-cell-${index}`} fill={colorsFemale} />
                            ))}
                        </Bar>
                    </BarChart>
                </div>

                <div className="block_employee_barchat_shift" style={{width: "fit-content"}}>
                    <div>Наполняемость смен по возрастным категориям</div>
                    <BarChart
                        width={800}
                        height={300}
                        data={occupancy_age}
                        margin={{ top: 20, right: 30, bottom: 5, left: 20 }}
                    >
                        <XAxis dataKey="shift" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="18-29" fill={colors18_29} name="18-29 лет" label={{ position: "top" }}>
                            {occupancy_age?.map((entry, index) => (
                                <Cell key={`ageGroup18_29-${index}`} fill={colors18_29} />
                            ))}
                        </Bar>
                        <Bar dataKey="30-39" fill={colors30_39} name="30-39 лет" label={{ position: "top" }}>
                            {occupancy_age?.map((entry, index) => (
                                <Cell key={`ageGroup30-39-${index}`} fill={colors30_39} />
                            ))}
                        </Bar>
                        <Bar dataKey="40-49" fill={colors40_49} name="40-49 лет" label={{ position: "top" }}>
                            {occupancy_age?.map((entry, index) => (
                                <Cell key={`ageGroup40-49-${index}`} fill={colors40_49} />
                            ))}
                        </Bar>
                        <Bar dataKey="50-59" fill={colors50_59} name="50-59 лет" label={{ position: "top" }}>
                            {occupancy_age?.map((entry, index) => (
                                <Cell key={`ageGroup50-59-${index}`} fill={colors50_59} />
                            ))}
                        </Bar>
                        <Bar dataKey="60+" fill={colors60Plus} name="60+ лет" label={{ position: "top" }}>
                            {occupancy_age?.map((entry, index) => (
                                <Cell key={`ageGroup60Plus-${index}`} fill={colors60Plus} />
                            ))}
                        </Bar>
                    </BarChart>
                </div>

                <div className="block_employee_barchat_shift" style={{width: "fit-content", height: "fit-content"}}>
                    <div>Наполняемость смен по стажу</div>
                    <BarChart
                        width={1300}
                        height={400}
                        data={occupancy_experience}
                        margin={{ top: 20, right: 30, bottom: 5, left: 20 }}
                    >
                        <XAxis dataKey="shift" />
                        <YAxis />
                        <Tooltip />
                        <Legend 
                            layout="vertical" 
                            align="right" 
                            verticalAlign="middle" 
                        />
                        <Bar dataKey="Меньше месяца" fill={colorsExp1} name="Меньше месяца" label={{ position: "top" }}>
                            {occupancy_experience?.map((entry, index) => (
                                <Cell key={`ageGroup18_25-${index}`} fill={colorsExp1} />
                            ))}
                        </Bar>
                        <Bar dataKey="От 1 до 6 месяцев" fill={colorsExp2} name="От 1 до 12 месяцев" label={{ position: "top" }}>
                            {occupancy_experience?.map((entry, index) => (
                                <Cell key={`ageGroup26_45-${index}`} fill={colorsExp2} />
                            ))}
                        </Bar>
                        <Bar dataKey="От 6 до 12 месяцев" fill={colorsExp2} name="От 1 до 12 месяцев" label={{ position: "top" }}>
                            {occupancy_experience?.map((entry, index) => (
                                <Cell key={`ageGroup26_45-${index}`} fill={colorsExp3} />
                            ))}
                        </Bar>
                        <Bar dataKey="От года до 2х лет" fill={colorsExp3} name="От года до 2х лет" label={{ position: "top" }}>
                            {occupancy_experience?.map((entry, index) => (
                                <Cell key={`ageGroup45Plus-${index}`} fill={colorsExp4} />
                            ))}
                        </Bar>
                        <Bar dataKey="От 2х до 3х лет" fill={colorsExp3} name="От 2х до 3х лет" label={{ position: "top" }}>
                            {occupancy_experience?.map((entry, index) => (
                                <Cell key={`ageGroup45Plus-${index}`} fill={colorsExp5} />
                            ))}
                        </Bar>
                        <Bar dataKey="Больше 3х лет" fill={colorsExp3} name="Больше 3х лет" label={{ position: "top" }}>
                            {occupancy_experience?.map((entry, index) => (
                                <Cell key={`ageGroup45Plus-${index}`} fill={colorsExp6} />
                            ))}
                        </Bar>
                    </BarChart>
                </div>

                <div className="block_employee_barchat_shift" style={{width: "fit-content", height: "fit-content"}}>
                    <div>Наполняемость смен по участкам</div>
                    <BarChart
                        width={1300}
                        height={400}
                        data={occupancy_area} // Данные для графика
                        margin={{ top: 20, right: 30, bottom: 5, left: 20 }}
                    >
                        <XAxis dataKey="shift" />
                        <YAxis />
                        <Tooltip />
                        <Legend 
                            layout="vertical" 
                            align="right" 
                            verticalAlign="middle" 
                        />
                        {/* Вторичная упаковка */}
                        <Bar
                            dataKey="Вторичная упаковка"
                            fill={colorsArea1}
                            name="Вторичная упаковка"
                            label={{ position: "top" }}
                        >
                            {occupancy_area?.map((entry, index) => (
                                <Cell key={`cell-${index}-packaging`} fill={colorsArea1} />
                            ))}
                        </Bar>

                        {/* Мониторинг */}
                        <Bar
                            dataKey="Мониторинг"
                            fill={colorsArea2}
                            name="Мониторинг"
                            label={{ position: "top" }}
                        >
                            {occupancy_area?.map((entry, index) => (
                                <Cell key={`cell-${index}-monitoring`} fill={colorsArea2} />
                            ))}
                        </Bar>

                        {/* Паучмейкер */}
                        <Bar
                            dataKey="Паучмейкер"
                            fill={colorsArea4}
                            name="Паучмейкер"
                            label={{ position: "top" }}
                        >
                            {occupancy_area?.map((entry, index) => (
                                <Cell key={`cell-${index}-pouchmaker`} fill={colorsArea4} />
                            ))}
                        </Bar>

                        {/* Переборка паллет с гп и упаковкой */}
                        <Bar
                            dataKey="Переборка паллет с гп и упаковкой"
                            fill={colorsArea5}
                            name="Переборка паллет"
                            label={{ position: "top" }}
                        >
                            {occupancy_area?.map((entry, index) => (
                                <Cell key={`cell-${index}-pallet-sorting`} fill={colorsArea5} />
                            ))}
                        </Bar>

                        {/* СГП */}
                        <Bar
                            dataKey="СГП"
                            fill={colorsArea6}
                            name="СГП"
                            label={{ position: "top" }}
                        >
                            {occupancy_area?.map((entry, index) => (
                                <Cell key={`cell-${index}-sgp`} fill={colorsArea6} />
                            ))}
                        </Bar>

                        {/* Стерилизация */}
                        <Bar
                            dataKey="Стерилизация"
                            fill={colorsArea7}
                            name="Стерилизация"
                            label={{ position: "top" }}
                        >
                            {occupancy_area?.map((entry, index) => (
                                <Cell key={`cell-${index}-sterilization`} fill={colorsArea7} />
                            ))}
                        </Bar>

                        {/* ТТ9 */}
                        <Bar
                            dataKey="ТТ9"
                            fill={colorsArea8}
                            name="ТТ9"
                            label={{ position: "top" }}
                        >
                            {occupancy_area?.map((entry, index) => (
                                <Cell key={`cell-${index}-tt9`} fill={colorsArea8} />
                            ))}
                        </Bar>

                        {/* Холодный склад. Бункерная зона */}
                        <Bar
                            dataKey="Холодный склад. Бункерная зона"
                            fill={colorsArea11}
                            name="Холодный склад"
                            label={{ position: "top" }}
                        >
                            {occupancy_area?.map((entry, index) => (
                                <Cell key={`cell-${index}-cold-storage`} fill={colorsArea11} />
                            ))}
                        </Bar>

                        {/* Честный знак */}
                        <Bar
                            dataKey="Честный знак"
                            fill={colorsArea12}
                            name="Честный знак"
                            label={{ position: "top" }}
                        >
                            {occupancy_area?.map((entry, index) => (
                                <Cell key={`cell-${index}-honest-sign`} fill={colorsArea12} />
                            ))}
                        </Bar>
                    </BarChart>
                </div>


            </div>
            
            {notification.isVisible && (
                <Notification
                status={notification.status}
                title={notification.title}
                subtitle={notification.subtitle}
                onClose={hideNotification}
                isVisible={notification.isVisible}
                subtitle2={notification.subtitle2}
                />
            )}
        </div>
    );
}

export default TableOccupancy;