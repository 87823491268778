import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from "./marsBaseQuery.ts";

export const formatDate = (dateStr: string | null): string => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};
  
export interface FiredEmployeeMars {
    id: number;
    fio: string;
    shift: Shift | null;
    mainPost: Post | null;
    datefired: string;
    dateemployment: string | null;
    reason: string;
    datebirthday: string | null;
};

interface Shift {
    id: number;
    nameshift: string;
}
  
interface Post {
    id: number;
    namepost: string;
}
  
export const firedEmployeeSliceMars = createApi({
    reducerPath: 'firedEmployeeMars',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['FiredEmployeeMars'],
    endpoints: (builder) => ({
      getFiredEmployeeMars: builder.query<FiredEmployeeMars[], void>({
        query: () => ({ url: '/fired', method: 'GET' }),
        transformResponse: (response: FiredEmployeeMars[]) =>
          response.map((history) => ({
            ...history,
            datefired: formatDate(history.datefired),
            dateemployment: formatDate(history.dateemployment),
            datebirthday: formatDate(history.datebirthday),
          })),
        providesTags: ['FiredEmployeeMars'],
      }),
    }),
});
  
export const { useGetFiredEmployeeMarsQuery } = firedEmployeeSliceMars;