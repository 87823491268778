import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import { useGetTrainingsEfesQuery } from "../Global/TrainingsEfes.ts";
import Select, { SingleValue } from "react-select";
import Notification from "../Components/Notification.tsx";
import { Employee_active, useGetEmployeesQuery } from "../Global/EmployeeActiveSlice.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store.ts";
import axiosInstance from "../axiosConfigEfes.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetAreaQuery } from "../Global/AreaEfesSlice.ts";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { setSelectedEfesEmployee } from "../Global/EmployeeActiveSlice.ts";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { useGetPostQuery } from "../Global/PostEfesSlice.ts";

interface EmployeeOption {
    value: number;
    label: string;
}
  
interface AreaOption {
    value: number;
    label: string;
}

interface PostOption {
    value: number;
    label: string;
}

interface Area {
    id: number;
    namearea: string;
}

interface Post {
    id: number;
    namepost: string;
}

const Trainings = () => {
    const { data: trainings, error, isLoading, refetch } = useGetTrainingsEfesQuery();
    const { data: areas } = useGetAreaQuery();
    const { data: posts } = useGetPostQuery();
    const { data: employees_active } = useGetEmployeesQuery();
    const [showModalAddTraining, setShowModalAddTraining] = useState(false);
    const employeeNext = useSelector((state: RootState) => state.employeeEfesState.selectedEfesEmployee);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selectedTrainingId, setSelectedTrainingId] = useState(null);
    const [isModalVisibleUpd, setIsModalVisibleUpd] = useState(false);


    const [nextEmployeeModalBtn, setNextEmployeeModalBtn] = useState(false);

    const [numSmen, setSelectedSmen] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedDateEnd, setSelectedDateEnd] = useState("");
    const [main, setSelectedMain] = useState(false);

    const [dataEmployee, setDataEmployee] = useState<Employee_active[]>(employees_active || []);
    const [dataMentor, setDataMentor] = useState<Employee_active[]>(employees_active || []);
    const [mentors, setMentors] = useState<Employee_active[]>(employees_active || []);
    const [dataForemen, setDataForemen] = useState<Employee_active[]>(employees_active || []);
    const [dataArea, setArea] = useState<Area[]>([]);
    const [dataPost, setPost] = useState<Post[]>([]);

    const [selectedEmployee, setSelectedEmployee] =
    useState<SingleValue<EmployeeOption>>(null);
    const [selectedMentor, setSelectedMentor] =
    useState<SingleValue<EmployeeOption>>(null);
    const [selectedArea, setSelectedArea] =
    useState<SingleValue<AreaOption>>(null);
    const [selectedPost, setSelectedPost] =
    useState<SingleValue<PostOption>>(null);
    const [selectedForemen, setSelectedForemen] =
    useState<SingleValue<EmployeeOption>>(null);

    const employeeOptions: EmployeeOption[] = dataEmployee.map((employee) => ({
        value: employee.id,
        label: employee.fio,
    }));

    const mentorOptions: EmployeeOption[] = mentors.map((employee) => ({
        value: employee.id,
        label: employee.fio,
    }));

    const areaOptions: AreaOption[] = dataArea.map((area) => ({
        value: area.id,
        label: area.namearea,
    }));

    const postOptions: PostOption[] = dataPost.map((post) => ({
        value: post.id,
        label: post.namepost,
    }));

    const foremenOptions: EmployeeOption[] = dataForemen.map((employee) => ({
        value: employee.id,
        label: employee.fio,
    }));

    const handleEmployeeChange = (
        selectedOption: SingleValue<EmployeeOption> | null
      ) => {
        setSelectedEmployee(selectedOption);
        if (selectedOption && employees_active) {
          const filteredMentors = employees_active.filter(
            (mentor) => mentor.id !== selectedOption.value
          );
          setMentors(filteredMentors);
        } else {
          setMentors(employees_active || []);
        }
    };
      
    useEffect(() => {
        if (employees_active && areas && posts) {
          setDataEmployee(employees_active);
          setDataMentor(employees_active);
          setMentors(employees_active);
          const filteredForemen = employees_active.filter(
            (foremen) => foremen.post.namepost === "бригадир"
          );
          setDataForemen(filteredForemen);
          setArea(areas);
          setPost(posts);
        } else {
          setDataEmployee([]);
          setDataMentor([]);
          setMentors([]);
          setDataForemen([]);
          setArea([]);
          setPost([]);
        }
    }, [employees_active, areas, posts]);
      
    useEffect(() => {
        if (selectedEmployee && employees_active) {
          const filteredMentors = employees_active.filter(
            (mentor) => mentor.id !== selectedEmployee.value
          );
          setMentors(filteredMentors);
        } else {
          setMentors(employees_active || []);
        }
    }, [employees_active, selectedEmployee]);

    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });

    const hideNotification = () => {
        setNotification({
          ...notification,
          isVisible: false,
        });
    };

    const columns: GridColDef[] = [
        {
            field: "fio",
            headerName: "ФИО",
            width: 250,
            valueGetter: (value, row) => {
                if (row.employee !== ""){
                    return row.employee.fio;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "area",
            headerName: "Участок",
            width: 150,
            valueGetter: (value, row) => {
                if (row.areaEfes !== null){
                    return row.areaEfes.namearea;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "post",
            headerName: "Должность",
            width: 150,
            valueGetter: (value, row) => {
                if (row.postEfes !== null){
                    return row.postEfes.namepost;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "mentor",
            headerName: "Наставник",
            width: 250,
            valueGetter: (value, row) => {
                if (row.mentor !== ""){
                    return row.mentor.fio;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "foremen",
            headerName: "Бригадир",
            width: 250,
            valueGetter: (value, row) => {
                if (row.foremen !== ""){
                    return row.foremen.fio;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "num",
            headerName: "Кол-во смен",
            width: 80,
            valueGetter: (value, row) => {
                if (row.numsmen !== ""){
                    return row.numsmen;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "datestart",
            headerName: "Дата начала",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.date) {
                    return null;
                }
                const [day, month, year] = row.date.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "dateend",
            headerName: "Дата окончания",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.dateend) {
                    return null;
                }
                const [day, month, year] = row.dateend.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "main",
            headerName: "Основной",
            width: 80,
            valueGetter: (value, row) => {
                if (row.main === true){
                    return "✅";
                } else {
                    return "❌";
                }
            },
        },
        {
            field: "button",
            headerName: "Действия",
            width: 140,
            renderCell: (params) => (
              <div>
                <IconButton
                  onClick={() => handleEditStatus(params.row.id)}
                >
                  <AutorenewIcon />
                </IconButton> 
                <IconButton
                  onClick={() => handleEdit(params.row.id)}
                >
                  <EditIcon />
                </IconButton> 
                <IconButton
                  onClick={() => handlePrintRaspStaz(params.row.id)}
                >
                  <LocalPrintshopIcon />
                </IconButton> 
                {localStorage.getItem("role_efes") !== "Бригадир" && (
                  <IconButton onClick={() => confirmDeleteTrain(params.row.id)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ),
        },
    ];

    const confirmDeleteTrain = async (id) => {
        const confirmLogout = window.confirm("Точно хотите удалить обчуение?");
        if (!confirmLogout) return;

        const data1 = {
            fio_akk: localStorage.getItem("fio_efes"),
            role_akk: localStorage.getItem("role_efes"),
        };
    
        try {
    
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
            await axiosInstance.delete(`/efes/delete_training/${id}`, { data: data1 });

            await refetch();
    
            setNotification({
                status: "success",
                title: "Успешно",
                subtitle: "Программа удалена",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
        } catch (error: any) {
            const errorMessage =
              error.response?.data || "Ошибка при удалении обучения"; 
        
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: errorMessage,
              subtitle2: <div></div>,
              isVisible: true,
            });
        }
    };

    const handleEditStatus = async ( trainid ) => {
        const confirmLogout = window.confirm("Точно хотите изменить статус обучения?");
        if (!confirmLogout) return;
        
        const data = {
            id: trainid,
            fio_akk: localStorage.getItem("fio_efes"),
            role_akk: localStorage.getItem("role_efes"),
        };
        try {
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });

            await axiosInstance.put(`/efes/toggle_status_training`, data);
            
            await refetch();
      
            setNotification({
              status: "success",
              title: "Успешно",
              subtitle: "Статус обучения изменен",
              subtitle2: <div></div>,
              isVisible: true,
            });
      
        } catch (error) {
            setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при изменении обучения",
            subtitle2: <div></div>,
            isVisible: true,
            });
        }
    }

    const handlePrintRaspStaz = async (id) => {
        try {
            if (!nextEmployeeModalBtn) {
                setNotification({
                    status: "loading",
                    title: "Создание документа",
                    subtitle: "Пожалуйста, подождите...",
                    subtitle2: <div></div>,
                    isVisible: true,
                });
            }
            
            const response = await axiosInstance.get(`/efes/documents/raspstaz/${id}`, {
                responseType: "blob",
            });
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("target", "_blank");
            link.click();
            window.URL.revokeObjectURL(url);

            if (!nextEmployeeModalBtn) {
                hideNotification();
            }

        } catch (error) {
            setNotification({
                status: "error",
                title: "Произошла ошибка",
                subtitle: "Повторите еще раз или обратитесь к разработчику",
                subtitle2: <div></div>,
                isVisible: true,
            });
        }
    };

    const handlePrintElectro = async (employeeid) => {
        try {
            if (!nextEmployeeModalBtn) {
                setNotification({
                    status: "loading",
                    title: "Создание документа",
                    subtitle: "Пожалуйста, подождите...",
                    subtitle2: <div></div>,
                    isVisible: true,
                });
            }
            
            const response = await axiosInstance.get(`/efes/documents/electrodoc/${employeeid}`, {
                responseType: "blob",
            });
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("target", "_blank");
            link.click();
            window.URL.revokeObjectURL(url);

            if (!nextEmployeeModalBtn) {
                hideNotification();
            }

        } catch (error) {
            setNotification({
                status: "error",
                title: "Произошла ошибка",
                subtitle: "Повторите еще раз или обратитесь к разработчику",
                subtitle2: <div></div>,
                isVisible: true,
            });
        }
    };

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const handleEdit = async (id) => {
        setNotification({
            status: "loading",
            title: "Получение данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
        });

        await axiosInstance
          .get(`/efes/get_training/${id}`)
          .then((response) => {
            const training = response.data;
            setSelectedDate(formatDate(training.date));
            setSelectedDateEnd(formatDate(training.dateend));
            setSelectedEmployee({
                value: training.employee.id,
                label: training.employee.fio,
            });
        
            setSelectedArea({
                value: training.areaEfes.id,
                label: training.areaEfes.namearea,
            });
        
            setSelectedPost(training.postEfes 
                ? {value: training.postEfes.id, label: training.postEfes.namepost }
                : null);
        
            setSelectedMentor(training.mentor
                ? { value: training.mentor.id, label: training.mentor.fio }
                : null);
        
            setSelectedForemen(training.foremen
                ? { value: training.foremen.id, label: training.foremen.fio }
                : null);

            setSelectedSmen(training.numsmen);
            setSelectedMain(training.main);
          })
          .catch((error) => {
            console.error(
              "Ошибка при получении данных для редактирования:",
              error
            );
        })

        hideNotification();

        setSelectedTrainingId(id);
        setIsModalVisibleUpd(true);
    };

    const confirmAddTrain = async () => {
        const formattedDate1 = new Date(selectedDate).toISOString();
        const formattedDate2 = new Date(selectedDateEnd).toISOString();

        if (formattedDate2 <= formattedDate1) {
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: "Дата окончания не может быть раньше даты начала",
              isVisible: true,
              subtitle2: <div></div>,
            });
            return;
        }

        if (Number(numSmen) <= 0) {
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: "Количество смен не может быть 0 или меньше.",
              isVisible: true,
              subtitle2: <div></div>,
            });
            return;
        }

        const data = {
            employeeid: selectedEmployee?.value,
            areaid: selectedArea?.value,
            postid: selectedPost?.value,
            mentorid: selectedMentor?.value,
            foremenid: selectedForemen?.value,
            datestart: formattedDate1,
            dateend: formattedDate2,
            numsmen: numSmen,
            main: main,
            fio_akk: localStorage.getItem("fio_efes"),
            role_akk: localStorage.getItem("role_efes"),
        };

        try {
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });

            const resp = await axiosInstance.post(`/efes/add_new_training`, data);

            if (resp.status >= 200 && resp.status < 300) {
                const traindata = resp.data;
                if (nextEmployeeModalBtn) {
                    setNotification({
                    status: "success",
                    title: "Успешно (внутри)",
                    subtitle: "",
                    subtitle2: (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div>Проверьте, все ли документы напечатаны:</div>
                            {/* <div>-карточка СИЗ</div> */}
                            <div>-распоряжение о стажировке</div>
                            {/* <div>-протокол проверки знаний в электроустановках</div> */}
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "15px",
                                }}
                            >
                                <p>Нажмите, чтобы напечатать: </p>
                                <Button
                                variant="contained"
                                onClick={() => handlePrintRaspStaz(traindata.training.id)}
                                style={{ marginTop: "10px" }}
                                >
                                    распоряжение о стажировке
                                </Button>
                                {/* <Button
                                variant="contained"
                                onClick={() =>
                                    handlePrintElectro(employeeNext?.id)
                                }
                                style={{ marginTop: "10px" }}
                                >
                                    протокол проверки знаний в электроустановках
                                </Button> */}
                            </div>
                        </div>
                    ),
                    isVisible: true,
                    });
                } else {
                    setNotification({
                        status: "success",
                        title: "Успешно",
                        subtitle: "",
                        subtitle2: (
                            <div>
                            </div>
                        ),
                        isVisible: true,
                    });
                }
            }

            dispatch(setSelectedEfesEmployee(null));
            setShowModalAddTraining(false);
            navigate(location.pathname, { replace: true });
            await refetch();

            setSelectedEmployee(null);
            setSelectedMentor(null);
            setSelectedArea(null);
            setSelectedPost(null);
            setSelectedForemen(null);

            setSelectedMain(false);
            setSelectedSmen("");
            setSelectedDate("");
            setSelectedDateEnd("");
      
        } catch (error: any) {
            const errorMessage = error.response?.data || "Ошибка при добавлении "; 
        
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: errorMessage,
              subtitle2: <div></div>,
              isVisible: true,
            });
        }
    }

    const confirmEditTrain = async () => {
        const formattedDate1 = new Date(selectedDate).toISOString();
        const formattedDate2 = new Date(selectedDateEnd).toISOString();

        if (formattedDate2 <= formattedDate1) {
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: "Дата окончания не может быть раньше даты начала",
              isVisible: true,
              subtitle2: <div></div>,
            });
            return;
        }

        if (Number(numSmen) <= 0) {
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: "Количество смен не может быть 0 или меньше.",
              isVisible: true,
              subtitle2: <div></div>,
            });
            return;
        }

        const data = {
            employeeid: selectedEmployee?.value,
            areaid: selectedArea?.value,
            postid: selectedPost?.value,
            mentorid: selectedMentor?.value,
            foremenid: selectedForemen?.value,
            datestart: formattedDate1,
            dateend: formattedDate2,
            numsmen: numSmen,
            main: main,
            fio_akk: localStorage.getItem("fio_efes"),
            role_akk: localStorage.getItem("role_efes"),
        };

        try {
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });

            const resp = await axiosInstance.put(`/efes/edit_training/${selectedTrainingId}`, data);

            if (resp.status >= 200 && resp.status < 300) {
                setNotification({
                    status: "success",
                    title: "Успешно",
                    subtitle: "",
                    subtitle2: (
                        <div>
                        </div>
                    ),
                    isVisible: true,
                });
            }

            setShowModalAddTraining(false);
            await refetch();

            setSelectedEmployee(null);
            setSelectedMentor(null);
            setSelectedArea(null);
            setSelectedPost(null);
            setSelectedForemen(null);

            setSelectedMain(false);
            setSelectedSmen("");
            setSelectedDate("");
            setSelectedDateEnd("");
      
        } catch (error: any) {
            const errorMessage = error.response?.data || "Ошибка при редактировании "; 
        
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: errorMessage,
              subtitle2: <div></div>,
              isVisible: true,
            });
        }
    }

    useEffect(() => {
        if (location.state?.showModal && !showModalAddTraining) {
            setNextEmployeeModalBtn(true);
            setShowModalAddTraining(true);
    
            if (employeeNext) {
                const nextEmpl: EmployeeOption = {
                    value: employeeNext.id,
                    label: employeeNext.fio,
                };
                setSelectedEmployee(nextEmpl);
                handleEmployeeChange(nextEmpl);
            }
        }
    }, [location.state, employeeNext, showModalAddTraining]);
    

    if (isLoading)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
          </div>
        </div>
    ;
        
    if (error) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
          <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
          <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
      </div>
    ;

    const isSaveDisabled = !selectedEmployee || !selectedArea || !selectedMentor || !numSmen || !selectedDate || !selectedForemen || !selectedDate || !selectedDateEnd;


    return (
        <div className="active_employee_efes">
            <div className="container_briefings_efes" style={{width: "1420px"}}>
                <Button
                    variant="contained"
                    onClick={() => setShowModalAddTraining(true)}
                    style={{ marginTop: "20px" }}
                    >
                    Добавить обучение
                </Button>
                <DataGrid
                    sx={{
                        marginTop: 2,
                    }}
                    rows={trainings ?? []}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 12,
                        },
                    },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    pageSizeOptions={[12]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{ toolbar: { showQuickFilter: true } }}
                />
            </div>

            {/* Модальное окно для добавления */}
            {showModalAddTraining && (
                <div className="modalTableTraining">
                <div className="modal-contentTableTraining">
                    <h3>Добавление информации об обучении</h3>
                    <div className="tr_cont_select">
                    <Select
                        id="employee-dropdown"
                        value={selectedEmployee}
                        options={employeeOptions}
                        onChange={handleEmployeeChange}
                        placeholder="Выберите сотрудника"
                        styles={{}}
                        isDisabled={!!employeeNext}
                    />
                    </div>

                    <div className="tr_cont_select">
                        <Select
                            id="area-dropdown"
                            value={selectedArea}
                            options={areaOptions}
                            onChange={(areaOptions) => setSelectedArea(areaOptions)}
                            placeholder="Выберите участок"
                        />
                    </div>

                    <div className="tr_cont_select">
                        <Select
                            id="post-dropdown"
                            value={selectedPost}
                            options={postOptions}
                            onChange={(postOptions) => setSelectedPost(postOptions)}
                            placeholder="Выберите должность"
                        />
                    </div>

                    <div className="tr_cont_select">
                    <Select
                        id="mentor-dropdown"
                        value={selectedMentor}
                        options={mentorOptions}
                        onChange={(mentorOptions) => setSelectedMentor(mentorOptions)}
                        placeholder="Выберите наставника"
                    />
                    </div>

                    <div className="fg">
                    <label htmlFor="numsmen">Кол-во смен:</label>
                    <input
                        type="number"
                        id="numsmen"
                        name="numsmen"
                        value={numSmen}
                        onChange={(e) => setSelectedSmen(e.target.value)}
                    />
                    </div>

                    <label htmlFor="date">Дата начала обучения:</label>
                    <input
                    type="date"
                    id="date"
                    name="date"
                    style={{
                        width: "96%",
                        height: "28px",
                        padding: "5px",
                        border: "1px solid #b7b7b7",
                        borderRadius: "5px",
                    }}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    />

                    <label htmlFor="dateend">Дата окончания обучения:</label>
                    <input
                    type="date"
                    id="dateend"
                    name="dateend"
                    style={{
                        width: "96%",
                        height: "28px",
                        padding: "5px",
                        border: "1px solid #b7b7b7",
                        borderRadius: "5px",
                    }}
                    onChange={(e) => setSelectedDateEnd(e.target.value)}
                    />

                    <div className="tr_cont_select">
                    <Select
                        id="foremen-dropdown"
                        value={selectedForemen}
                        options={foremenOptions}
                        onChange={(foremenOptions) =>
                        setSelectedForemen(foremenOptions)
                        }
                        placeholder="Выберите бригадира"
                    />
                    </div>

                    <div className="fg2" style={{ marginTop: "0px" }}>
                    <label htmlFor="main">Основной участок:</label>
                    <input
                        type="checkbox"
                        id="main"
                        checked={main}
                        onChange={(e) => setSelectedMain(e.target.checked)}
                    />
                    </div>

                    <div className="modal-actionsTableEmpl">
                    {!nextEmployeeModalBtn && (
                        <div className="modal-actionsTableEmpl">
                        <button onClick={confirmAddTrain} disabled={isSaveDisabled}>
                            Добавить
                        </button>
                        <button onClick={() => setShowModalAddTraining(false)}>Отмена</button>
                        </div>
                    )}

                    {nextEmployeeModalBtn && (
                        <div className="modal-actionsTableEmpl">
                        <button onClick={confirmAddTrain} disabled={isSaveDisabled}>
                            Добавить
                        </button>
                        </div>
                    )}
                    </div>
                </div>
                </div>
            )}

            {/* Модальное окно для редактирования */}
            {isModalVisibleUpd && (
                <div className="modalTableTraining">
                    <div className="modal-contentTableTraining">
                        <h3>Редактирование информации об обучении</h3>
                        <div className="tr_cont_select">
                        <Select
                            id="employee-dropdown"
                            value={selectedEmployee}
                            options={employeeOptions}
                            onChange={handleEmployeeChange}
                            placeholder="Выберите сотрудника"
                            styles={{}}
                            isDisabled={!!employeeNext}
                        />
                        </div>

                        <div className="tr_cont_select">
                            <Select
                                id="area-dropdown"
                                value={selectedArea}
                                options={areaOptions}
                                onChange={(areaOptions) => setSelectedArea(areaOptions)}
                                placeholder="Выберите участок"
                            />
                        </div>

                        <div className="tr_cont_select">
                            <Select
                                id="post-dropdown"
                                value={selectedPost}
                                options={postOptions}
                                onChange={(postOptions) => setSelectedPost(postOptions)}
                                placeholder="Выберите должность"
                            />
                        </div>

                        <div className="tr_cont_select">
                        <Select
                            id="mentor-dropdown"
                            value={selectedMentor}
                            options={mentorOptions}
                            onChange={(mentorOptions) => setSelectedMentor(mentorOptions)}
                            placeholder="Выберите наставника"
                        />
                        </div>

                        <div className="fg">
                        <label htmlFor="numsmen">Кол-во смен:</label>
                        <input
                            type="number"
                            id="numsmen"
                            name="numsmen"
                            value={numSmen}
                            onChange={(e) => setSelectedSmen(e.target.value)}
                        />
                        </div>

                        <label htmlFor="date">Дата начала обучения:</label>
                        <input
                        type="date"
                        id="date"
                        name="date"
                        style={{
                            width: "96%",
                            height: "28px",
                            padding: "5px",
                            border: "1px solid #b7b7b7",
                            borderRadius: "5px",
                        }}
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        />

                        <label htmlFor="dateend">Дата окончания обучения:</label>
                        <input
                        type="date"
                        id="dateend"
                        name="dateend"
                        style={{
                            width: "96%",
                            height: "28px",
                            padding: "5px",
                            border: "1px solid #b7b7b7",
                            borderRadius: "5px",
                        }}
                        value={selectedDateEnd}
                        onChange={(e) => setSelectedDateEnd(e.target.value)}
                        />

                        <div className="tr_cont_select">
                        <Select
                            id="foremen-dropdown"
                            value={selectedForemen}
                            options={foremenOptions}
                            onChange={(foremenOptions) =>
                            setSelectedForemen(foremenOptions)
                            }
                            placeholder="Выберите бригадира"
                        />
                        </div>

                        <div className="fg2" style={{ marginTop: "0px" }}>
                        <label htmlFor="main">Основной участок:</label>
                        <input
                            type="checkbox"
                            id="main"
                            checked={main}
                            onChange={(e) => setSelectedMain(e.target.checked)}
                        />
                        </div>

                        <div className="modal-actionsTableEmpl">
                            <div className="modal-actionsTableEmpl">
                                <button onClick={confirmEditTrain} disabled={isSaveDisabled}>
                                    Редактировать
                                </button>
                                <button onClick={() => setIsModalVisibleUpd(false)}>Отмена</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {notification.isVisible && (
                <Notification
                status={notification.status}
                title={notification.title}
                subtitle={notification.subtitle}
                subtitle2={notification.subtitle2}
                onClose={hideNotification}
                isVisible={notification.isVisible}
                />
            )}
        </div>
    );
}

export default Trainings;