import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './marsBaseQuery.ts';

export const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export interface BriefingsMars {
    id: number,
    fio: string;
    dateonjob: string | null;
    datefire: string | null;
    dateelectro: string | null;
    dateelectrolast: string | null;
    numelectro: string;
}

export const briefingsMarsSlice = createApi({
  reducerPath: 'briefingsMars',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['BriefingsMars'],
  endpoints: (builder) => ({
    getBriefingsMars: builder.query<BriefingsMars[], void>({
      query: () => ({ url: '/employees/all_brief', method: 'GET' }),
      transformResponse: (response: BriefingsMars[]) =>
        response.map((history) => ({
          ...history,
          dateonjob: formatDate(history.dateonjob),
          datefire: formatDate(history.datefire),
          dateelectro: formatDate(history.dateelectro),
          dateelectrolast: formatDate(history.dateelectrolast),
        })),
      providesTags: ['BriefingsMars'],
    }),
  }),
});

export const { 
  useGetBriefingsMarsQuery
 } = briefingsMarsSlice;