import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import { useGetDriverEfesQuery } from '../Global/DriverEfesSlice.ts';
import CircularProgress from '@mui/material/CircularProgress';import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Notification from "../Components/Notification.tsx";
import axiosInstance from '../axiosConfigEfes.js';
import "../Styles/Briefings.scss";
import Select, { SingleValue } from "react-select";
import { Employee_active, useGetEmployeesQuery } from "../Global/EmployeeActiveSlice.ts";
import Button from "@mui/material/Button";

interface EmployeeOption {
    value: number;
    label: string;
}

const Driver = () => {
    const { data: driver, error, isLoading, refetch } = useGetDriverEfesQuery();
    const { data: employees_active, error: errorEmpl, isLoading: isLoadingEmpl } = useGetEmployeesQuery();

    const [showModalDriver, setShowModalDriver] = useState(false);

    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
    const [selectedEmployeeFio, setSelectedEmployeeFio] = useState("");
    const [showModalUpd, setShowModalUpd] = useState(false);

    const [dateinput, setDateinput] = useState("");
    const [datepsih, setDatepsih] = useState("");
    const [number, setNumber] = useState("");

    const [dataEmployee, setDataEmployee] = useState<Employee_active[]>(employees_active || []);

    const [selectedEmployee, setSelectedEmployee] =
        useState<SingleValue<EmployeeOption>>(null);

    const employeeOptions: EmployeeOption[] = dataEmployee.map((employee) => ({
        value: employee.id,
        label: employee.fio,
    }));

    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });

    const hideNotification = () => {
    setNotification({
        ...notification,
        isVisible: false,
    });
    };

    useEffect(() => {
        if (employees_active) {
            setDataEmployee(employees_active);
        } else {
            setDataEmployee([]);
        }
    }, [employees_active]);

    const columns: GridColDef[] = [
        {
            field: "fio",
            headerName: "ФИО",
            width: 250,
            valueGetter: (value, row) => {
                if (row.employee.fio !== ""){
                    return row.employee.fio;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "post",
            headerName: "Должность",
            width: 200,
            valueGetter: (value, row) => {
                if (row.employee.post !== ""){
                    return row.employee.post.namepost;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "dateemployment",
            headerName: "Дата трудоустр.",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.employee.dateemployment) {
                    return null;
                }
                const [day, month, year] = row.employee.dateemployment.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "number",
            headerName: "Номер удостовер.",
            width: 200,
            valueGetter: (value, row) => {
                if (row.number !== ""){
                    return row.number;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "dateinput",
            headerName: "Дата выдачи",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.dateinput) {
                    return null;
                }
                const [day, month, year] = row.dateinput.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "datepsih",
            headerName: "Дата психоосвед.",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.datepsih) {
                    return null;
                }
                const [day, month, year] = row.datepsih.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "button",
            headerName: "Действия",
            width: 60,
            renderCell: (params) => (
              <div>
                <IconButton onClick={() => handleEdit(params.row.id, params.row.employee.fio)}>
                  <EditIcon />
                </IconButton>
              </div>
            ),
        },
    ];

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const handleEdit = async (id, fio) => {
        setNotification({
            status: "loading",
            title: "Получение данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
        });

        await axiosInstance
          .get(`/efes/get_driver/${id}`)
          .then((response) => {
            const employee = response.data;
            setDateinput(formatDate(employee.dateinput));
            setDatepsih(formatDate(employee.datepsih));
            setNumber(employee.number);
          })
          .catch((error) => {
            console.error(
              "Ошибка при получении данных",
              error
            );
        })

        hideNotification();

        setSelectedEmployeeId(id);
        setSelectedEmployeeFio(fio);
        setShowModalUpd(true);
    };

    const confirmUpd = async () => {
        const formattedDatepsih = new Date(datepsih).toISOString();
        const formattedDateinput = new Date(dateinput).toISOString();
    
        const empData = {
          id: selectedEmployeeId,
          dateinput: formattedDateinput,
          datepsih: formattedDatepsih,
          number: number,
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
        };
    
        try {
    
          setNotification({
              status: "loading",
              title: "Обновление данных",
              subtitle: "Пожалуйста, подождите...",
              subtitle2: <div></div>,
              isVisible: true,
          });
    
          await axiosInstance.put(`/efes/edit_driver`, empData);
          
          setShowModalUpd(false);
          refetch();
    
          setNotification({
            status: "success",
            title: "Успешно",
            subtitle: "Водительское удостоверение отредактировано",
            subtitle2: <div></div>,
            isVisible: true,
          });
    
        } catch (error) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при обновлении",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
    };

    const confirmDriver = async () => {
        if (!dateinput || !number || !selectedEmployee) {
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: "Необходимо указать номер и дату выдачи удостоверения",
              subtitle2: <div></div>,
              isVisible: true,
            });
            return;
        }

        let formattedDatepsih = "";

        if (datepsih){
            formattedDatepsih = new Date(datepsih).toISOString();
        }
        const formattedDateinput = new Date(dateinput).toISOString();
    
        const empData = {
            employeeid: selectedEmployee?.value,
            dateinput: formattedDateinput,
            datepsih: formattedDatepsih,
            number: number,
            fio_akk: localStorage.getItem("fio_efes"),
            role_akk: localStorage.getItem("role_efes"),
        };
    
        try {
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
            await axiosInstance.post(`/efes/add_new_driver`, empData);

            setShowModalDriver(false);
            setSelectedEmployee(null);
            setNumber("");
            setDateinput("");
            setDatepsih("");
            refetch();
          
            setNotification({
                status: "success",
                title: "Успешно",
                subtitle: "Водительское удостоверение добавлено",
                subtitle2: (
                    <div>
                    </div>
                ),
                isVisible: true,
            });
        } catch (error) {
            setShowModalDriver(false);
            setSelectedEmployee(null);
            setNumber("");
            setDateinput("");
            setDatepsih("");
            const errorMessage = error.response?.data || "Ошибка при добавлении";
            setNotification({
                status: "error",
                title: "Ошибка",
                subtitle: errorMessage,
                subtitle2: <div></div>,
                isVisible: true,
            });
        }
    };

    if (isLoading || isLoadingEmpl)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
          </div>
        </div>
    ;
        
    if (error || errorEmpl) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
          <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
          <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
      </div>
    ;

    return (
        <div className="active_employee_efes">
            <div className="container_briefings_efes" style={{width: "1050px"}}>
                <Button
                    variant="contained"
                    onClick={() => {setShowModalDriver(true); setDateinput(""); setDatepsih(""); setNumber(""); setSelectedEmployee(null)}}
                    style={{ marginTop: "20px" }}
                    >
                    Добавить водительское удостоверение
                </Button>
                <DataGrid
                    style={{
                    marginTop: 20,
                    }}
                    rows={driver ?? []}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 12,
                        },
                    },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    pageSizeOptions={[12]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{
                    toolbar: GridToolbar,
                    }}
                    slotProps={{ toolbar: { showQuickFilter: true } }}
                />
            </div>

            {/* Модальное окно для редактирования */}
            {showModalUpd && (
                <div className="modal_brief_employee_efes">
                    <div className="modal_cont_brief_employee_efes" style={{height: "260px"}}>
                        <h3 className='title_employee_efes' style={{height: "50px"}}>Редактирование удостоверения</h3>
                        <label style={{display:"flex", flexDirection: "row"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

                        <div className="dat">
                            <label htmlFor="fio">Номер удостоверения:</label>
                            <input
                                type="text"
                                name="number"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                style={{width: "182px", marginTop: "15px",}}
                            ></input>
                        </div>

                        <div className="dat">
                            <label htmlFor="dateinput">Дата выдачи удостоверения:</label>
                            <input
                                type="date"
                                id="dateinput"
                                name="dateinput"
                                value={dateinput}
                                onChange={(e) => setDateinput(e.target.value)}
                                style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="datepsih">Дата психоосведетельст.:</label>
                            <input
                                type="date"
                                id="datepsih"
                                name="datepsih"
                                value={datepsih}
                                onChange={(e) => setDatepsih(e.target.value)}
                                style={{ marginBottom: "10px", marginLeft: "50px"}}
                            />
                        </div>

                        <div className="modal_act_fir">
                            <button onClick={confirmUpd}>Обновить</button>
                            <button onClick={() => setShowModalUpd(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Модальное окно для добавления вод. уд.*/}
            {showModalDriver && (
                <div className="modal_brief_employee_efes">
                    <div className="modal_cont_brief_employee_efes" style={{height: "250px"}}>
                        <h3 className='title_employee_efes' style={{}}>Добавление удостоверения</h3>
                        
                        <div className="tr_cont_select">
                            <Select
                                id="employee-dropdown"
                                value={selectedEmployee}
                                options={employeeOptions}
                                onChange={(selectedOption: SingleValue<EmployeeOption> | null) => setSelectedEmployee(selectedOption)}
                                placeholder="Выберите сотрудника"
                                styles={{}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="fio">Номер удостоверения:</label>
                            <input
                                type="text"
                                name="number"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                style={{width: "182px"}}
                            ></input>
                        </div>

                        <div className="dat">
                            <label htmlFor="dateinput">Дата выдачи удостоверения:</label>
                            <input
                                type="date"
                                id="dateinput"
                                name="dateinput"
                                value={dateinput}
                                onChange={(e) => setDateinput(e.target.value)}
                                style={{marginTop: "10px", marginBottom: "10px", marginLeft: "20px"}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="datepsih">Дата психоосведетельст.:</label>
                            <input
                                type="date"
                                id="datepsih"
                                name="datepsih"
                                value={datepsih}
                                onChange={(e) => setDatepsih(e.target.value)}
                                style={{ marginBottom: "10px", marginLeft: "50px"}}
                            />
                        </div>

                        <div className="modal_act_fir">
                            <button onClick={confirmDriver}>Добавить</button>
                            <button onClick={() => setShowModalDriver(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {notification.isVisible && (
                <Notification
                    status={notification.status}
                    title={notification.title}
                    subtitle={notification.subtitle}
                    onClose={hideNotification}
                    isVisible={notification.isVisible}
                    subtitle2={notification.subtitle2}
                />
            )}
        </div>
    );
}

export default Driver;