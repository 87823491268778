import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';

export const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export interface TrainingsEfes {
    employee: {
        id: number;
        fio: string;
    };
    mentor: {
        id: number;
        fio: string
    };
    foremen: {
        id: number;
        fio: string
    };
    area: {
        id: number;
        namearea: string
    };
    post: {
      id: number;
      namepost: string
    };
    date: string | null;
    dateend: string | null;
    main: boolean;
    numsmen: number;
}

export const trainingsEfesSlice = createApi({
  reducerPath: 'trainingsEfes',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['TrainingsEfes'],
  endpoints: (builder) => ({
    getTrainingsEfes: builder.query<TrainingsEfes[], void>({
      query: () => ({ url: '/efes/all_trainings', method: 'GET' }),
      transformResponse: (response: TrainingsEfes[]) =>
        response.map((train) => ({
          ...train,
          date: formatDate(train.date),
          dateend: formatDate(train.dateend),
        })),
      providesTags: ['TrainingsEfes'],
    }),
  }),
});

export const { useGetTrainingsEfesQuery } = trainingsEfesSlice;